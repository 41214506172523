import React, { useEffect, useState } from 'react';

// Modules
import Reveal from 'reveal.js';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { DndContext, useDroppable, useSensors, useSensor, PointerSensor } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { NestedMenuItem } from 'mui-nested-menu';
import { debounce } from 'lodash';

// App
import { serverUrl } from '../../config';
import { getOnlineNode } from '../../core/getNode';
import { postNode, patchNode } from '../../core/postNode';
import { get_csrf_token } from '../../core/auth';
import { default_reveal } from '../../core/defaultReveal';
import SlideEdit from './partials/slide/edit/slide'
import Toolbar from './partials/toolbar';
import Sidebar from './partials/sidebar';
import CategorySelector from '../../partials/categorySelector';
import RevealControls from './partials/revealControls';
import BespokeMedia from '../../partials/presentations/bespokeMedia';
import ColorPickerNew from '../../partials/presentations/colorPickerNew';
import { jsonParser, decodeHtmlEntities, runAnimation, addWidescreen, getThemeBackgrounds } from '../../utils/helpers';
import AlertModal from '../../partials/alertModal';
import AddAudio from '../../partials/presentations/addAudio';
import AudioPlayer from '../../partials/audioPlayer';

// UI components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  Alert,
  IconButton,
  Button,
  Box,
  Dialog,
  DialogContent,
  Snackbar,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Typography,
  Stack,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Autocomplete
} from '@mui/material';
import {
  ArrowBack,
  Close,
  KeyboardArrowRight,
  ChevronLeft
} from '@mui/icons-material';

let reveal;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

export default function EditPresentation(props) {
  /** generic */
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [samplePresentationLoading, setSamplePresentationLoading] = useState(true);
  const [authorised, setAuthorised] = useState(true);

  /** errors */
  const [addSlideError, setAddSlideError] = useState(false);
  const [addLibrarySlidesError, setAddLibrarySlidesError] = useState(false);
  const [addCloneSlidesError, setAddCloneSlidesError] = useState(false);
  const [removeSlidesError, setRemoveSlidesError] = useState(false);
  const [slidePatchError, setSlidePatchError] = useState(false);

  /** modals */
  const [showPresentationModal, setShowPresentationModal] = useState(false);
  const [showSlideAudioSettings, setShowSlideAudioSettings] = useState(false);
  const [showSlideLayoutSettings, setShowSlideLayoutSettings] = useState(false);
  const [showLogoSettings, setShowLogoSettings] = useState(false);
  const [showBackgroundSettings, setShowBackgroundSettings] = useState(false);
  const [showTransitionSettings, setShowTransitionSettings] = useState(false);
  const [showSlideSettings, setShowSlideSettings] = useState(false);
  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const [showSlideNotes, setShowSlideNotes] = useState(false);

  const [open, setOpen] = useState(false);
  const [showControls, setShowControls] = useState(true);

  const [isInitial, setIsInitial] = useState(true);

  /** audio */
  const [playSlideAudio, setPlaySlideAudio] = useState(false);
  const [slideAudioPlaying, setSlideAudioPlaying] = useState(false);
  const [showSlideAudioControls, setShowSlideAudioControls] = useState(false);
  const [hasSlideAudio, setHasSlideAudio] = useState(false);
  const [delayHideSlideAudioControls, setDelayHideSlideAudioControls] = useState(false);
  const [playPresentationAudio, setPlayPresentationAudio] = useState(false);
  const [showPresentationAudioControls, setShowPresentationAudioControls] = useState(false);
  const [presentationAudioPlaying, setPresentationAudioPlaying] = useState(false);

  let [elementDimensions, setElementDimensions] = useState({ width: 305, height: 30 });
  let [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  /** hooks */
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  /** redux */
  const user = useSelector((state) => state).authReducer.user;
  const permissions = useSelector((state) => state).permissionsReducer.permissions;

  const [csrf_token, setCsrfToken] = useState(user.csrf_token);

  /** reveal */
  // const [reveal, setReveal] = useState(null);
  const [indexh, setIndexH] = useState(1);
  // const [indexv, setIndexV] = useState(1);

  // controls delta
  let [x, setX] = useState(window.innerWidth - (elementDimensions.width + 10));
  let [y, setY] = useState(170);

  /** data */
  const [presentation, setPresentation] = useState(null);
  const [samplePresentation, setSamplePresentation] = useState(null);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [coreLibrary, setCoreLibrary] = useState(false);
  const [presentationAudio, setPresentationAudio] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('#000000');
  const [homeSlide, setHomeSlide] = useState('');
  const [showHomeSlideInput, setShowHomeSlideInput] = useState(false);
  const [thumbnail, setThumbnail] = useState({});
  const [categories, setCategories] = useState([{ value: 0, label: 'All Categories' }]);
  const [languages, setLanguages] = useState([{ value: 0, label: 'All Languages' }]);
  const [themes, setThemes] = useState([{ value: 0, label: 'Select a theme' }]);
  const [sitas, setSitas] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const [selectedTheme, setSelectedTheme] = useState(0);
  const [selectedThemeItem, setSelectedThemeItem] = useState(null);
  const [selectedSita, setSelectedSita] = useState(null);
  const [slidesToRemove, setSlidesToRemove] = useState([]);

  // droppable
  const { setNodeRef } = useDroppable({
    id: 'droppable',
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  // get sita
  useEffect(() => {
    if(sitas){
      setSita();
    }
  }, [sitas, presentation]);

  // On mount
  useEffect(() => {
    dispatch({ type: 'TOGGLE_NAVIGATION', payload: false });
    getCategories();
    initReveal();

    get_csrf_token()
      .then(response => {
        setCsrfToken(response.data);
      });

    return () => {
      if (reveal) {
        reveal.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get content after reveal is initialized
  useEffect(() => {
    if (reveal && reveal !== null) {
      getContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reveal]);

  useEffect(() => {
    setDimensions(getWindowDimensions()) // Necessary to make sure dimensions are set upon initial load

    function handleResize() {
      setDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  useEffect(() => {
    const handleMouseMove = debounce((event) => {
      setShowControls(true)
      hideRevealControls()
    });
    const hideRevealControls = debounce(() => {
      setShowControls(false)
    }, 5000);

    window.addEventListener('mousemove', handleMouseMove);


    return () => {
      window.removeEventListener(
        'mousemove',
        handleMouseMove
      );
    };
  }, []);

  const initReveal = () => {
    reveal = new Reveal({
      width: 1366,
      height: 768,
      margin: 0,
      slideNumber: false,
      help: false,
      transition: 'slide',
      progress: false,
      hashOneBasedIndex: true,
      respondToHashChanges: true,
      hash: true,
      maxScale: 1,
      minScale: 0.5,
      controls: false,
      controlsTutorial: false,
      overview: false,
      fragmentInURL: true,
    });

    reveal.initialize()
      .then(() => {

        const indices = reveal.getIndices(reveal.getCurrentSlide());
        const currentIndex = indices.h ? indices.h : 0;

        setIndexH(currentIndex);
        setLoading(false);

        const currentSlide = reveal.getCurrentSlide();
        checkAdvancedSlide(currentSlide);

        reveal.on('slidechanged', event => {
          setIndexH(event.indexh);
          // setIndexV(event.indexv);
          checkAdvancedSlide(event.currentSlide);
        });

        reveal.on('slidetransitionend', event => {
          const allIframes = document.querySelectorAll('.slides iframe');
          allIframes.forEach(iframe => {
            addWidescreen(iframe);
          });
        });
      })
      .catch(console.error);
  };

  const updateReveal = (index) => {
    if (reveal) {
      reveal.sync();
      reveal.slide(index);

      const currentSlide = reveal.getCurrentSlide();
      checkAdvancedSlide(currentSlide);
    }
  };

  const navigateTo = (indexh, indexv) => {
    if (reveal) {
      reveal.slide(indexh);
    }
  };

  const checkAdvancedSlide = (currentSlide) => {
    const allIframes = document.querySelectorAll('.slides iframe');
    allIframes.forEach(iframe => {
      addWidescreen(iframe);
    });

    const currentIframe = currentSlide.querySelector('iframe.advanced-slide');
    runAnimation(currentIframe);
  };

  const getCategories = () => {
    getOnlineNode('api/categories?vid=presentation_categories,language,themes,sita&creator=true')
      .then(response => {
        let categories = [{ value: 0, label: 'All Categories' }];
        let languages = [{ value: 0, label: 'All Languages' }];
        let themes = [{ value: 0, label: 'Select a theme' }];
        let sitas = [{ value: '0', title: 'NONE', tid: '0', label: 'NONE' }];
        response.data.forEach(item => {
          if (item.has_view_permission === true) {
            if (item.vid === 'presentation_categories') {
              categories.push({
                ...item,
                value: item.tid,
                label: item.title,
              });
            }

            if (item.vid === 'language') {
              languages.push({
                ...item,
                value: item.tid,
                label: item.title,
              });
            }

            if (item.vid === 'themes') {
              themes.push(item);
            }

            if (item.vid === 'sita') {
              sitas.push({
                ...item,
                value: item.tid,
                label: item.title,
              });
            }
          }
        });

        setThemes(themes);
        setLanguages(languages);
        setCategories(categories);
        setSitas(sitas);
      })
      .catch(console.error);
  };

  const setSita = () => {
    let selected;

    if (presentation?.sita?.tid) {
      selected = sitas.find(item => item.tid == presentation.sita?.tid);
    } else {
      selected = sitas.find(item => item.tid == 0);
    }

    setSelectedSita(selected);
  };

  const getSamplePresentation = (tid) => {
    if (tid && tid !== selectedTheme) {
      setSamplePresentationLoading(true);
      getOnlineNode(`api/sample_presentation/${tid}`)
        .then(response => {
          setSamplePresentation(response.data);
          setSamplePresentationLoading(false);
        })
        .catch((error) => {
          setSamplePresentationLoading(false);
        });
    }
  };

  /**
   * @function getContent - Retrieves presentation data from server
   *
   * @param {Boolean} isUpdateOnly
   * @param {Boolean} revealIndex
   */
  const getContent = (revealIndex) => {
    if (params?.id) {
      getOnlineNode(`api/presentation/${params.id}?status=false`)
        .then((response) => {
          if (response.data) {
            if (response.data?.has_edit_permission === true) {
              if (response?.data?.title) {
                setTitle(response?.data?.title);
              }

              if (response?.data?.body) {
                setBody(response?.data?.body);
              }

              if (response?.data?.core_library) {
                setCoreLibrary(response?.data?.core_library === '1' ? true : false);
              }

              const json = jsonParser(response?.data?.json);

              if (json?.presentationAudio) {
                setPresentationAudio(json.presentationAudio);
              }

              if (json?.thumbnail) {
                setThumbnail(json.thumbnail);
              }

              if (json?.backgroundColor) {
                setBackgroundColor(json.backgroundColor);
              }

              if (json?.homeSlide) {
                setHomeSlide(json.homeSlide);
                setShowHomeSlideInput(true);
              }

              setSelectedCategory(response.data.category.tid ? parseInt(response.data.category.tid, 10) : 0);
              setSelectedLanguage(response.data.language.tid ? parseInt(response.data.language.tid, 10) : 0);
              setSelectedSita(response.data.sita.tid ? parseInt(response.data.sita.tid, 10) : 0);

              setSelectedTheme(response.data.theme.tid ? parseInt(response.data.theme.tid, 10) : 0);
              setSelectedThemeItem(
                response.data.theme.tid ?
                  {
                    label: response.data.theme.label,
                    value: response.data.theme.tid,
                  } :
                  {
                    label: 'Select a theme',
                    value: 0,
                  }
              );

              getSamplePresentation(response.data.theme.tid)

              setPresentation(response.data);
              setLoading(false);
              updateReveal(revealIndex);
            } else {
              setLoading(false);
              setAuthorised(false);
            }
          } else {
            setLoading(false);
            setError(true);
          }
        })
        .catch(error => {
          setLoading(false);
          setError(true);
        });
    } else {
      setLoading(false);
      setError(true);
    }
  };

  /**
   * @function updatePresentation - Send patch to server to update presentation entity
   * 
   * @param {Array} new_slides - Array of slide ID's
   */
  const updatePresentation = (new_slides) => {
    let data = {
      id: presentation.id,
      title: title,
      body: body,
      category: selectedCategory,
      language: selectedLanguage,
      sita: selectedSita?.tid ? selectedSita.tid : 0,
      core_library: coreLibrary ? 1 : 0,
      theme: selectedTheme,
      json: {
        presentationAudio: presentationAudio,
        thumbnail: thumbnail,
        backgroundColor: backgroundColor,
        homeSlide: homeSlide ? homeSlide : ''
      }
    };

    if (new_slides) {
      data.slides = new_slides;
    }

    return patchNode(`api/presentation/${params.id}`, data, csrf_token);
  };

  /**
   * @function changeSlideOrder
   * 
   * @param {Array} new_slides 
   */
  const changeSlideOrder = (new_slides) => {
    if (new_slides.length > 0) {
      setLoading(true);

      updatePresentation(new_slides)
        .then(response => {
          setPresentation(null);
          setPresentation(response.data);
          setLoading(false);
          updateReveal(0);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  };

  /**
   * @function addSlide - Adds a new slide to the presentation
   * 
   * @param {Object} slide - Slide entity from sample presentation
   * @param {Object} layout - Layout json
   * @param {Boolean} index 
   */
  const addSlide = (slide, layout, index) => {
    setLoading(true);

    let data;

    if (slide && slide?.id) {
      data = {
        presentation_id: presentation.id,
        slides: [slide.id],
      };

      postNode('api/slide_clone', data, csrf_token)
        .then(response => {
          if (response.data.length > 0) {
            let new_slides = [];

            presentation.slides.forEach(_slide => {
              new_slides.push(_slide.id);
            });

            new_slides.splice(index, 0, ...response.data);

            updatePresentation(new_slides)
              .then(response => {
                setPresentation(null);
                setIndexH(indexh);
                getContent(index);
              })
              .catch((error) => {
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAddSlideError(true);
        });
    } else {
      if (layout && layout.id) {
        addSlideFromLayout(layout, index);
      } else {
        setLoading(true);
        setAddSlideError(true);
      }
    }
  };

  const addSlideFromLayout = (layout, index) => {
    let data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/slide/slide',
        },
      },
      title: {
        value: 'New slide for ' + presentation.id,
      },
      subtitle: {
        value: '',
      },
      initial_presentation: [
        {
          target_id: presentation.id,
        },
      ],
    };

    if (presentation.theme.tid) {
      data.theme = [
        {
          target_id: presentation.theme.tid,
        }
      ];
    }

    postNode('entity/slide', data, csrf_token)
      .then(response => {
        const new_slide_id = response.data?.id[0]?.value;

        if (new_slide_id) {
          let reveal = default_reveal;

          reveal.slideLayout = {
            id: layout.id,
            name: layout.name,
            files: layout?.files
          }

          const slideData = {
            id: new_slide_id,
            presentation_id: presentation.id,
            json: {
              blocks: [],
              reveal: default_reveal
            }
          }

          patchNode(
            'api/slide/' + new_slide_id, slideData, csrf_token)
            .then(_response => {
              let new_slides = [];

              presentation.slides.forEach(_slide => {
                new_slides.push(_slide.id);
              });

              new_slides.splice(index, 0, new_slide_id);

              updatePresentation(new_slides)
                .then(response => {
                  setPresentation(null);
                  setIndexH(indexh);
                  getContent(index);
                })
                .catch((error) => {
                  setLoading(false);
                });
            })
            .catch(error => {
              setLoading(false);
              setAddSlideError(true);
            });
        } else {
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        setAddSlideError(true);
      });
  };

  /**
   * @function addLibrarySlides - Adds an array of new slides to presentation entity
   * 
   * @param {Array} new_slides - Array of slide ID's
   * @param {Number} index - The pre index of the slides to add
   */
  const addLibrarySlides = (new_slides, index) => {
    if (new_slides.length > 0) {
      setLoading(true);

      let existing_slides = [];
      presentation.slides.forEach(slide => {
        existing_slides.push(slide.id);
      });

      existing_slides.splice(index, 0, ...new_slides);

      updatePresentation(existing_slides)
        .then(response => {
          setPresentation(null);
          setIndexH(indexh);
          getContent(indexh + 1);
        })
        .catch(() => {
          setLoading(false);
          setAddLibrarySlidesError(true);
        });
    }
  };

  /**
   * @function addCloneSlides - Clones an array of slides to presentation entity
   * 
   * @param {Array} new_slides - Array of slide ID's
   */
  const addCloneSlides = (new_slides, index) => {
    let data;

    if (new_slides.length > 0) {
      setLoading(true);

      data = {
        presentation_id: presentation.id,
        slides: new_slides,
      };

      postNode('api/slide_clone', data, csrf_token)
        .then(response => {
          if (response.data.length > 0) {
            let existing_slides = [];

            presentation.slides.forEach(slide => {
              existing_slides.push(slide.id);
            });

            existing_slides.splice(index, 0, ...response.data);

            updatePresentation(existing_slides)
              .then(response => {
                setPresentation(null);
                setIndexH(indexh);
                getContent(indexh + 1);
              })
              .catch(() => {
                setLoading(false);
                setAddCloneSlidesError(true);
              });
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          setAddCloneSlidesError(true);
        });
    }
  };

  const toggleSlideStatus = (slides) => {
    setLoading(true);

    let slides_to_update = [];

    if (slides.length > 0) {
      presentation.slides.forEach(slide => {
        const found = slides.some(item => item === slide?.id);

        if (found) {
          slides_to_update.push({
            id: slide.id,
            status: slide.published === true ? 0 : 1,
          });
        }
      });

      postNode('/slides_draft', slides_to_update, csrf_token)
        .then(_response => {
          getContent();
        })
        .catch(_error => {
          setLoading(false);
          setSlidePatchError(true);
        });
    } else {
      setLoading(false);
    }
  };

  /**
   * @function removeSlides - Removed an array of slides from the presentation entity
   * 
   * @param {Array} slides - Array of slide ID's
   */
  const removeSlides = (slides) => {
    if (slides.length > 0) {
      let new_slides = [];
      presentation.slides.forEach(slide => {
        const found = slides.some(item => item === slide?.id);

        if (!found) {
          new_slides.push(slide.id);
        }
      });

      updatePresentation(new_slides)
        .then(response => {
          setPresentation(null);
          setIndexH(indexh);
          getContent(indexh - 1);
        })
        .catch(() => {
          setLoading(false);
          setRemoveSlidesError(true);
        });
    } else {
      setRemoveSlidesError(true);
      setLoading(false);
    }
  };

  const handleThemeSelection = (tid) => {
    let themeItem = themes.filter(item => {
      return item.tid === tid
    });

    setSelectedThemeItem(
      {
        label: themeItem[0].label,
        value: themeItem[0].tid
      }
    );

    setSelectedTheme(parseInt(tid, 10));
  };

  const handleCategorySelection = (tid) => {
    setSelectedCategory(parseInt(tid, 10));
    setOpen(false);
  };

  function handleDragEnd(ev) {
    setX(x += ev.delta.x);
    setY(y += ev.delta.y);
  };

  const hasChildren = (id) => {
    if (categories) {
      const nested = categories.filter(item => item.parent_target_id === id);

      if (nested.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getChildItems = (arr, id) => {
    return arr.filter(item => item.parent_target_id === id);
  };

  const menuProps = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  };

  return (
    <main className='edit-presentation screen'>
      <DndContext onDragEnd={handleDragEnd} sensors={sensors} modifiers={[restrictToParentElement]}>
        <Container ref={setNodeRef} fluid className='ps-container h-100'>
          {isLoading && (
            <Box className='circular-container fs'>
              <CircularProgress size={20} />
            </Box>
          )}

          {authorised ? (
            <Row className='ps-row h-100 justify-content-center align-self-center'>
              <Col xs={2} className='ps-col ps-sidebar'>
                {presentation ? (
                  <Sidebar
                    user={user}
                    csrf_token={csrf_token}
                    presentation={presentation}
                    samplePresentation={samplePresentation}
                    // @TODO sample presentation loading
                    samplePresentationLoading={samplePresentationLoading}
                    permissions={permissions}
                    categories={categories}
                    themes={themes}
                    languages={languages}
                    indexh={indexh}
                    navigateTo={navigateTo}
                    updateSlidesOrder={(new_slides => changeSlideOrder(new_slides))}
                    onAddSlide={(slide, layout, index) => {
                      addSlide(slide, layout, index);
                    }}
                    onAddLibrarySlides={(slides, index) => {
                      addLibrarySlides(slides, index);
                    }}
                    onAddCloneSlides={(slides, index) => {
                      addCloneSlides(slides, index);
                    }}
                    onRemoveSlides={(slides) => {
                      setSlidesToRemove(slides);
                    }}
                    toggleStatus={(slides) => {
                      toggleSlideStatus(slides);
                    }}
                  />
                ) : (
                  <Box className='d-flex h-100 justify-content-center align-items-center align-content-center' sx={{ flexDirection: 'column' }}>
                    {isError ? (
                      <Alert variant='filled' severity='error'>An error occurred.</Alert>
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </Box>
                )}
              </Col>
              <Col className='ps-col ps-viewer h-100'>
                {isError ? (
                  <></>
                ) : (
                  <Toolbar
                    onPresentationModal={() => {
                      setShowPresentationModal(true);
                    }}
                    onLogoSettings={() => {
                      setShowLogoSettings(true);
                    }}
                    onBackgroundSettings={() => {
                      setShowBackgroundSettings(true);
                    }}
                    onTransitionSettings={() => {
                      setShowTransitionSettings(true);
                    }}
                    presentation={presentation}
                    reveal={reveal}
                    indexh={indexh}
                    onRemoveSlides={(slides) => {
                      setSlidesToRemove(slides);
                    }}
                    onSlideSettings={() => {
                      setShowSlideSettings(true);
                    }}
                    onSlideNotes={() => {
                      setShowSlideNotes(true)
                    }}
                    // onHelp={()=>{
                    //   setShowHelpDialog(true);
                    // }}
                    goBack={() => {
                      // if (reveal) {
                      //   reveal.destroy();
                      // }
                      navigate('/presentations-creator');
                    }}
                    toggleStatus={(slides) => {
                      toggleSlideStatus(slides);
                    }}
                  />
                )}

                <div style={backgroundColor ? { backgroundColor: backgroundColor } : {}} className={`edit-container ${presentation ? '' : 'h-100'}`}>
                  <div className='reveal'>
                    <div className='slides'>
                      {presentation?.slides.length > 0 ? (
                        <>
                          {presentation?.slides?.map((slide, index) => {
                            return (
                              <SlideEdit
                                key={`slide-${index}`}
                                user={user}
                                csrf_token={csrf_token}
                                permissions={permissions}
                                slide={slide}
                                presentation={presentation}
                                themes={presentation?.themes ? presentation?.themes : []}
                                selectedTheme={selectedTheme}
                                onRevealUpdate={() => {
                                  reveal.sync();
                                }}
                                onSlideUpdate={() => {
                                  getContent();
                                }}
                                onSettingsModalHide={() => {
                                  setShowSlideAudioSettings(false);
                                  setShowSlideLayoutSettings(false);
                                  setShowLogoSettings(false);
                                  setShowBackgroundSettings(false);
                                  setShowTransitionSettings(false);
                                  setShowSlideSettings(false);
                                  setShowHelpDialog(false);
                                  setShowSlideNotes(false);
                                }}
                                setSlideAudioSettings={(condition) => {
                                  setShowSlideAudioSettings(condition);
                                }}
                                activeTrigger={index === indexh ? true : false}
                                showSlideAudioSettings={index === indexh ? showSlideAudioSettings : false}
                                showLayoutSettings={index === indexh ? showSlideLayoutSettings : false}
                                showLogoSettings={index === indexh ? showLogoSettings : false}
                                showBackgroundSettings={index === indexh ? showBackgroundSettings : false}
                                showTransitionSettings={index === indexh ? showTransitionSettings : false}
                                showSlideSettings={index === indexh ? showSlideSettings : false}
                                showHelpDialog={index === indexh ? showHelpDialog : false}
                                showSlideNotes={index === indexh ? showSlideNotes : false}

                                playSlideAudio={index === indexh ? playSlideAudio : false}
                                showSlideAudioControls={index === indexh ? showSlideAudioControls : false}
                                hideSlideAudioControls={() => {
                                  if (index === indexh) {
                                    setShowSlideAudioControls(false)
                                  }
                                }}
                                onSlideAudioPlaying={(value) => {
                                  if (!value) {
                                    setPlaySlideAudio(false);
                                  }
                                  setSlideAudioPlaying(value);
                                }}
                                slideHasAudio={(value) => {
                                  index === indexh && value ? setHasSlideAudio(value) : setHasSlideAudio(false)
                                }}
                              />
                            )
                          })}
                        </>
                      ) : (
                        <section className='slide-wrap h-100'>
                          <div className='d-flex h-100 justify-content-center align-items-center'>
                            {presentation ? (
                              <>
                                {presentation?.slides.length < 1 && (
                                  <Typography variant='body'>No slides added. Add a new slide from the sidebar.</Typography>
                                )}
                              </>
                            ) : (
                              <>
                                {isError ? (
                                  <Alert variant='filled' severity='error'>An error occurred.</Alert>
                                ) : (
                                  <CircularProgress size={20} />
                                )}
                              </>
                            )}
                          </div>
                        </section>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <Row className='ps-row h-100 justify-content-center align-self-center'>
              <Col>
                <Box className='d-flex h-100 justify-content-center align-items-center align-content-center' sx={{ flexDirection: 'column' }}>
                  <Alert variant='filled' severity='error'>You don't have permission to edit this presentation.</Alert>
                  <Button
                    className='mt-3'
                    variant='outlined'
                    startIcon={<ArrowBack />}
                    onClick={() => {
                      // if (reveal) {
                      //   reveal.destroy();
                      // }
                      navigate('/presentations-creator');
                    }}
                  >
                    Back
                  </Button>
                </Box>
              </Col>
            </Row>
          )}

          {authorised && presentation && (
            <RevealControls
              reveal={reveal}
              indexh={indexh}
              presentation={presentation}
              showControls={showControls}
              editMode={true}
              hasSlideAudio={hasSlideAudio}
              showSlideAudioControls={(value) => {
                switch (value) {
                  case 0:
                    setShowSlideAudioControls(false);
                    break;
                  case 1:
                    setShowSlideAudioControls(!showSlideAudioControls);
                    break;
                  case 2:
                    setShowSlideAudioControls(true);
                    setDelayHideSlideAudioControls(true);
                    break;
                  case 3:
                    setShowSlideAudioControls(false);
                    setDelayHideSlideAudioControls(false);
                    break;
                }
              }}
              playSlideAudio={() => {
                setPlaySlideAudio(!playSlideAudio)
              }}
              slideAudioActive={slideAudioPlaying}
              hasPresentationAudio={presentationAudio}
              showPresentationAudioControls={(value) => {
                setShowPresentationAudioControls(value)
              }}
              playPresentationAudio={() => {
                setPlayPresentationAudio(!playPresentationAudio)
              }}
              presentationAudioActive={presentationAudioPlaying}
              onHelp={() => {
                setShowHelpDialog(true);
              }}
              styles={{
                // left: `min(${dimensions.width - elementDimensions.width}px, ${x}px)`,
                left: `min(${dimensions.width - 147}px, ${x}px)`,
                top: `min(${dimensions.height - elementDimensions.height}px, ${y}px)`
              }}
              setDimensions={(size) => {
                setElementDimensions(size);
                if (isInitial) {
                  setX(window.innerWidth - (size.width + 30));
                  setIsInitial(false);
                }
              }}
              presentationAudioObject={{
                audio: presentationAudio?.field_media_audio,
                autoPlay: presentationAudio?.autoPlay,
                playOverride: playPresentationAudio,
                showControls: showPresentationAudioControls,
                editMode: true,
              }}
              presentationAudioPlaying={(value) => { setPresentationAudioPlaying(value) }}
              hideSlideAudioControls={() => setShowPresentationAudioControls(false)}
            />
          )}
        </Container>
      </DndContext>

      <Dialog
        className='ps-modal presentation-settings-modal'
        open={showPresentationModal}
        maxWidth={'lg'}
        fullWidth={true}
        onClose={() => {
          setShowPresentationModal(false);
        }}>

        <Box className='ps-modal-title'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col xs={12} sm={6}>
                <h3>Presentation settings</h3>
              </Col>
              <Col xs={12} sm={6}>
                <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
                  <Button
                    variant='outlined'
                    className='cancel'
                    onClick={() => {
                      setShowPresentationModal(false);
                    }}>
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    color='secondary'
                    className='action'
                    onClick={() => {
                      setLoading(true);
                      setShowPresentationModal(false);
                      updatePresentation()
                        .then(() => {
                          setLoading(true);
                          getContent(indexh);
                        })
                        .catch(() => {
                          setLoading(false);
                        });
                    }}>
                    Save
                  </Button>
                </Stack>
              </Col>
            </Row>
          </Container>
        </Box>
        <DialogContent>
          <Box className='ps-modal-content'>
            <Container fluid>
              <Row>
                <Col xs={12} sm={6}>
                  <Box className='spacer ls'>
                    <div className='form-group'>
                      <label htmlFor='presentation-title'>Title</label>
                      <TextField
                        id='presentation-title'
                        variant='outlined'
                        fullWidth
                        size='small'
                        value={title}
                        sx={{
                          "& .MuiInputBase-input": {
                            height: 28,
                            // paddingTop: '11px',
                            // paddingBottom: '11px'
                          }
                        }}
                        onInput={(event) => {
                          setTitle(event.target.value);
                        }}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='description'>Description</label>
                      <TextField
                        id='description'
                        variant='outlined'
                        fullWidth
                        size='small'
                        value={body}
                        sx={{
                          "& .MuiInputBase-root": {
                            minHeight: 45,
                            paddingTop: '3px',
                            paddingBottom: '3px'
                          }
                        }}
                        onInput={(event) => {
                          setBody(event.target.value);
                        }}
                        multiline
                        maxRows={3}
                      />
                    </div>
                    {permissions.access_promote_to_core_library && (
                      <div className='form-group'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id='core-lib-checkbox'
                              checked={coreLibrary}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  setCoreLibrary(true);
                                } else {
                                  setCoreLibrary(false);
                                }
                              }}
                            />
                          }
                          label='Promote to Corporate library'
                        />
                      </div>
                    )}
                    <div className='form-group multi-group'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='home-slide-checkbox'
                            checked={homeSlide ? true : false}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setHomeSlide(homeSlide ? homeSlide : 1);
                                setShowHomeSlideInput(true);
                              } else {
                                setHomeSlide('');
                                setShowHomeSlideInput(false);
                              }
                            }}
                          />
                        }
                        label='Show home slide button / Slide #'
                      />
                      {showHomeSlideInput && (
                        <div className='inner-form-group'>
                          <TextField
                            id='slideNumber'
                            size='small'
                            value={homeSlide}
                            type='number'
                            InputProps={{ inputProps: { min: 1, max: presentation?.slides?.length } }}
                            onChange={(event) => {
                              setHomeSlide(event.target.value);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className='form-group pres-bg'>
                      <label>Presentation background</label>
                      <ColorPickerNew
                        hideTitle={true}
                        slideBackgroundColor={backgroundColor ? backgroundColor : ''}
                        colors={getThemeBackgrounds(presentation?.theme?.label)}
                        hideDefault={true}
                        onColorSelected={(background_color => {
                          setBackgroundColor(background_color);
                        })}
                      />
                    </div>
                  </Box>
                </Col>
                <Col xs={12} sm={6}>
                  <Box className='spacer rs'>
                    <Box className='form-group'>
                      <label htmlFor='presentationThemes'>Theme</label>
                      <CategorySelector
                        name='presentationThemes'
                        label='Theme'
                        categories={themes}
                        selectedCategory={selectedThemeItem?.value === 0 ? { label: 'Select a theme', value: 0 } : selectedThemeItem}
                        outlined={true}
                        variant="outlined"
                        size="small"
                        handleCategorySelection={(tid) => {
                          handleThemeSelection(tid);
                        }}
                      />
                    </Box>
                    <Box className='form-group'>
                      <label htmlFor='presentationCategories'>Category</label>
                      <FormControl
                        variant={'outlined'}
                        size={'small'}
                        sx={{
                          width: 1,
                          "& label[data-shrink=true]": {
                            visibility: "hidden"
                          },
                          "& legend": {
                            width: 0
                          }
                        }}>
                        <InputLabel id={`category`}>Category</InputLabel>
                        <Select
                          labelId={`category-id`}
                          value={selectedCategory}
                          label={'Category'}
                          open={open}
                          onClose={() => setOpen(false)}
                          onOpen={() => setOpen(true)}
                        >
                          {categories?.map((category, index) => {
                            const has_child = hasChildren(category.value);
                            const is_top_level = category.parent_target_id ? false : true;

                            if (!category.has_create_permission && category.value !== 0) {
                              return null;
                            }

                            if (is_top_level) {
                              if (has_child) {
                                const region_child_items = getChildItems(categories, category.value);

                                return (
                                  <NestedMenuItem
                                    key={`category-${category.value}`}
                                    leftIcon={<ChevronLeft />}
                                    rightIcon={null}
                                    parentMenuOpen={open}
                                    label={category.label}
                                    MenuProps={menuProps}
                                    className='settings-nested'
                                  >
                                    {
                                      region_child_items.map((region) => {
                                        const countries = getChildItems(categories, region.value);

                                        return (
                                          <NestedMenuItem
                                            key={`region-${region.value}`}
                                            leftIcon={<ChevronLeft />}
                                            rightIcon={null}
                                            parentMenuOpen={open}
                                            label={region.label}
                                            MenuProps={menuProps}
                                            className='settings-nested'
                                          >
                                            {
                                              countries.map((country) => {
                                                const cities = getChildItems(categories, country.value);

                                                return (
                                                  <NestedMenuItem
                                                    key={`country-${country.value}`}
                                                    leftIcon={<ChevronLeft />}
                                                    rightIcon={null}
                                                    parentMenuOpen={open}
                                                    label={country.label}
                                                    MenuProps={menuProps}
                                                    className='settings-nested'
                                                  >
                                                    {
                                                      cities.map((city, cityIndex) => {
                                                        return (
                                                          <MenuItem
                                                            key={`city-${city.value}`}
                                                            value={city.value}
                                                            sx={{
                                                              fontSize: "16px",
                                                              fontWeight: "400",
                                                              "&.Mui-selected": {
                                                                backgroundColor: "primary.light"
                                                              }
                                                            }}
                                                            onClick={() => {
                                                              handleCategorySelection(city.value);
                                                            }}
                                                          >
                                                            {decodeHtmlEntities(city.label)}
                                                          </MenuItem>
                                                        )
                                                      })
                                                    }
                                                  </NestedMenuItem>
                                                )
                                              })
                                            }
                                          </NestedMenuItem>
                                        )
                                      })
                                    }
                                  </NestedMenuItem>
                                )
                              } else {
                                return (
                                  <MenuItem
                                    key={`category-${category.value}`}
                                    value={category.value}
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      "&.Mui-selected": {
                                        backgroundColor: "primary.light"
                                      }
                                    }}
                                    onClick={() => {
                                      handleCategorySelection(category.value);
                                    }}
                                  >
                                    {decodeHtmlEntities(category.label)}
                                  </MenuItem>
                                );
                              }
                            } else {
                              return (
                                <MenuItem
                                  key={`category-${category.value}`}
                                  value={category.value}
                                  sx={{ display: 'none' }}
                                  onClick={() => {
                                    handleCategorySelection(category.value);
                                  }}
                                >
                                  {decodeHtmlEntities(category.label)}
                                </MenuItem>
                              );
                            }
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box className='form-group'>
                      <label htmlFor='languages'>Language</label>
                      <FormControl
                        variant={'outlined'}
                        size={'small'}
                        sx={{
                          width: 1,
                          "& label[data-shrink=true]": {
                            visibility: "hidden"
                          },
                          "& legend": {
                            width: 0
                          }
                        }}>
                        <InputLabel id={`Language`}>Language</InputLabel>
                        <Select
                          labelId={`language-id`}
                          value={selectedLanguage}
                          label={'Language'}
                          onChange={(event) => {
                            setSelectedLanguage(event.target.value);
                          }}
                        >
                          {languages?.map((language, index) => {
                            return (
                              <MenuItem
                                key={`language-${language.value}`}
                                value={language.value}
                                sx={{ fontSize: 16 }}
                              >
                                {decodeHtmlEntities(language.label)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box className='form-group'>
                      <label htmlFor='sita'>SITA</label>
                      <FormControl fullWidth size='small' sx={{
                        width: 1,
                        "& label[data-shrink=true]": {
                          visibility: "hidden"
                        },
                        "& legend": {
                          width: 0
                        }
                      }}>
                        <Autocomplete
                          options={sitas}
                          getOptionLabel={(option) => option.title}
                          value={selectedSita ? selectedSita : null}
                          disableClearable={true}
                          onChange={(event, value) => {
                            setSelectedSita(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="SITA"
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                sx: {
                                  paddingBottom: 0,
                                  '& .MuiOutlinedInput-input': {
                                    paddingBottom: '0 !important',
                                    paddingTop: '0 !important',
                                    borderColor: '#c2ccd6',
                                  },
                                  '&:hover .MuiOutlinedInput-input': {
                                    borderWidth: '2px',
                                    borderColor: 'primary.main'
                                  },
                                  '& .MuiAutocomplete-endAdornment': {
                                    top: 'calc(50% - 16px)'
                                  }
                                }
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                </Col>
              </Row>
              {permissions.access_advanced_presentation_settings &&
                <Row>
                  <Accordion
                    elevation={0}
                    sx={{
                      '&:before': {
                        display: 'none',
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<KeyboardArrowRight />}
                      sx={{
                        padding: 0,
                        flexDirection: 'row-reverse',
                        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                          transform: 'rotate(90deg)',
                        },

                      }}
                    >
                      <Col xs={12} className='d-flex align-items-center'>
                        Advanced
                        <Divider className='mt-1 ms-2 flex-grow-1' />
                      </Col>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        paddingLeft: 0,
                        paddingRight: 0
                      }}
                    >
                      <Row className='slide-settings-adv'>
                        <Col xs={12} sm={6}>
                          <Row><h6 className='ss-subtitle-color'>Add presentation audio</h6></Row>
                          <AddAudio
                            user={user}
                            audioData={presentationAudio}
                            onAudioChange={(audio) => {
                              setPresentationAudio(audio)
                            }}
                          />
                        </Col>
                        <Col xs={12} sm={6}>
                          <BespokeMedia
                            user={user}
                            files={{
                              minWidth: 400,
                              minHeight: 225,
                              maxWidth: 400,
                              maxHeight: 225,
                              aspectRatio: 1.777777777777778
                            }}
                            media={thumbnail}
                            onMediaInsert={(media) => {
                              setThumbnail(media);
                            }}
                            title={`Bespoke presentation thumbnail`}
                            hint={`A thumbnail for the presentation will be created automatically. However, if you would like to create your own, please upload an image file via the Media library.`}
                          />
                        </Col>
                      </Row>
                    </AccordionDetails>
                  </Accordion>
                </Row>
              }
            </Container>
          </Box>
        </DialogContent>
      </Dialog>

      {/* <Dialog open={slidesToRemove.length > 0 ? true : false} onClose={() => setSlidesToRemove([])}>
        <DialogTitle>Remove slides</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you would like to remove the selected slides from this presentation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSlidesToRemove([])}>Cancel</Button>
          <Button color='error' onClick={() => {
            setLoading(true);
            removeSlides(slidesToRemove);
            setSlidesToRemove([]);
          }}>Remove</Button>
        </DialogActions>
      </Dialog> */}
      <AlertModal
        showAlert={slidesToRemove.length > 0 ? true : false}
        showAlertCallback={() => setSlidesToRemove([])}
        showCaLogo={true}
        alertMessageTitle={"Remove slides?"}
        alertMessageBody={"Are you sure you want to remove the selected slide(s) from this presentation?"}
        cancelButton={true}
        confirmButton={true}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Remove"}
        confirmButtonColor={"error"}
        onConfirm={() => {
          setLoading(true);
          removeSlides(slidesToRemove);
          setSlidesToRemove([]);
        }}
      />

      <Snackbar
        open={addSlideError ? true : false}
        autoHideDuration={3000}
        onClose={() => {
          setAddSlideError(false);
        }}
        message='Failed to create slide. Please try again.'
        action={(
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={() => {
              setAddSlideError(false);
            }}>
            <Close fontSize='small' />
          </IconButton>
        )}
      />

      <Snackbar
        open={slidePatchError ? true : false}
        autoHideDuration={3000}
        onClose={() => {
          setSlidePatchError(false);
        }}
        message='Failed to update slide. Please try again.'
        action={(
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={() => {
              setSlidePatchError(false);
            }}>
            <Close fontSize='small' />
          </IconButton>
        )}
      />

      <Snackbar
        open={addCloneSlidesError ? true : false}
        autoHideDuration={3000}
        onClose={() => {
          setAddCloneSlidesError(false);
        }}
        message='Failed to clone slides. Please try again.'
        action={(
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={() => {
              setAddCloneSlidesError(false);
            }}>
            <Close fontSize='small' />
          </IconButton>
        )}
      />

      <Snackbar
        open={addLibrarySlidesError ? true : false}
        autoHideDuration={3000}
        onClose={() => {
          setAddLibrarySlidesError(false);
        }}
        message='Failed to add library slides. Please try again.'
        action={(
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={() => {
              setAddLibrarySlidesError(false);
            }}>
            <Close fontSize='small' />
          </IconButton>
        )}
      />

      <Snackbar
        open={removeSlidesError ? true : false}
        autoHideDuration={3000}
        onClose={() => {
          setRemoveSlidesError(false);
        }}
        message='Failed to remove slides. Please try again.'
        action={(
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={() => {
              setRemoveSlidesError(false);
            }}>
            <Close fontSize='small' />
          </IconButton>
        )}
      />
      {
        presentationAudio &&
        <AudioPlayer
          isSlideAudio={false}
          audio={typeof presentationAudio == 'string' ? presentationAudio : presentationAudio.src}
          editMode={true}
          audioPlaying={(value) => { setPresentationAudioPlaying(value) }}
          showControls={showPresentationAudioControls}
          hideSlideAudioControls={() => setShowPresentationAudioControls(false)}
        />
      }
    </main >
  );
};

// eslint-disable-next-line no-lone-blocks
{/* {audio && (
        <AudioPlayer
          audio={audio.field_media_audio}
          editMode={true}
          autoPlay={audio.autoPlay}
          onRemoveAudio={() => {
            setAudio(null);
            updatePresentation()
              .then(() => {
                setLoading(false);
                if (reveal) {
                  reveal.sync();
                }
              })
              .catch(() => {
                setLoading(false);
              });
          }}
          onReplaceAudio={() => {
            setShowMediaLibrary(true);
          }}
          toggleAutoPlay={() => {
            setAudio((prevState) => {
              return ({
                ...prevState,
                autoPlay: !prevState.autoPlay,
              });
            });
            updatePresentation()
              .then(() => {
                setLoading(false);
                if (reveal) {
                  reveal.sync();
                }
              })
              .catch(() => {
                setLoading(false);
              });
          }}
        />
      )} */}

// eslint-disable-next-line no-lone-blocks
{/* {showMediaLibrary && (
        <MediaLibrary
          user={user}
          presentation={presentation}
          mediaType={mediaType}
          setShowMediaLibrary={(condition => {
            setShowMediaLibrary(condition);
          })}
          showMediaLibrary={showMediaLibrary}
          onMediaInsert={(media) => {
            if (media.bundle === 'audio') {
              setAudio({ ...media, autoPlay: false })
            }
          }}
        />
      )} */}