import React, { useState, useContext, useEffect, useRef } from "react";

// Modules
import { useNavigate } from "react-router-dom";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";

// App
import AddSlide from "./slide/addSlide";
import AddLibrarySlide from "./slide/addLibrarySlide";
import Thumbnail from "./thumbnail";

// UI components
import { Row, Col } from "react-bootstrap";
import {
  Typography,
  Stack,
  Button,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  TextField,
  InputLabel,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Fab,
  Tooltip,
  Alert,
} from "@mui/material";
import { ReactComponent as NounLink } from "../../../assets/noun-link.svg";

import {
  DragIndicator,
  Add,
  TextSnippet,
  ContentCopy,
  InsertLink,
} from "@mui/icons-material";

export default function Sidebar(props) {
  const { navigator } = useContext(NavigationContext);
  const navigate = useNavigate();
  const unblockRef = useRef();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const selectedAction = "";

  const [fromLayoutOpen, setFromLayoutOpen] = useState(false);
  const [fromLibraryOpen, setFromLibraryOpen] = useState(false);
  const [fromCloneOpen, setFromCloneOpen] = useState(false);
  const [showMoveSlides, setShowMoveSlides] = useState(false);
  const [slidesPosition, setSlidesPosition] = useState("");

  const [hasChanges, setHasChange] = useState(false);
  const [slideAddIndex, setSlideAddIndex] = useState(0);
  const [newSlideDialog, setNewSlideDialogOpen] = useState(false);
  const [slides, setSlides] = useState(props?.presentation?.slides);
  const [selectedSlides, setSelectedSlides] = useState([]);

  const [scrollToItemId, setScrollToItemId] = useState(null);
  const scrollToItemRef = useRef(null);

  // Listen to changes for slides
  useEffect(() => {
    let original_order = [];
    let new_order = [];

    for (let i = 0; i < props?.presentation?.slides.length; i++) {
      original_order.push(props?.presentation?.slides[i].id);
    }

    for (let i = 0; i < slides.length; i++) {
      new_order.push(slides[i].id);
    }

    const x = original_order.toString();
    const y = new_order.toString();

    if (x !== y) {
      setHasChange(true);
    } else {
      setHasChange(false);
    }
  }, [slides, props?.presentation?.slides]);

  // Re-render on slide change
  useEffect(() => {
    setSlides(props?.presentation?.slides);
  }, [props?.presentation?.slides]);

  // Router listener
  useEffect(() => {
    if (!hasChanges) return;

    unblockRef.current = navigator.block((tx) => {
      if (hasChanges) {
        // setCurrentPath(tx.location.pathname);
        return false;
      }
    });
    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [hasChanges, navigate, navigator]);

  const handleNewSlideDialogClickOpen = () => {
    setNewSlideDialogOpen(true);
  };

  const handleNewSlideDialogClose = () => {
    setNewSlideDialogOpen(false);
  };

  const handleShowMoveSlideClose = () => {
    setShowMoveSlides(false);
  };

  const handleActionChange = (event) => {
    if (selectedSlides.length > 0) {
      if (event.target.value === "delete") {
        props.onRemoveSlides(selectedSlides);
        setSelectedSlides([]);
      }

      if (event.target.value === "draft") {
        props.toggleStatus(selectedSlides);
        setSelectedSlides([]);
      }

      if (event.target.value === "move") {
        setShowMoveSlides(true);
      }
    }
  };

  const handleMoveSlides = () => {
    const position = slidesPosition === 1 ? 0 : slidesPosition;
    let selected_slides_excluded = [];

    slides.forEach((slide) => {
      if (selectedSlides.indexOf(slide.id) === -1) {
        selected_slides_excluded.push(slide.id);
      }
    });

    let new_slides = selected_slides_excluded;
    new_slides.splice(position, 0, ...selectedSlides);
    props?.updateSlidesOrder(new_slides);

    setSelectedSlides([]);
    handleShowMoveSlideClose();
  };

  // DnD callback
  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setSlides((slides) => {
        const oldIndex = slides.findIndex((f) => f.id === active.id);
        const newIndex = slides.findIndex((f) => f.id === over.id);
        return arrayMove(slides, oldIndex, newIndex);
      });
    }
  }

  // Scroll to specific item
  useEffect(() => {
    if (scrollToItemId && scrollToItemRef.current) {
      setTimeout(() => {
        scrollToItemRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 250);
    }
  }, [scrollToItemId]);

  // Function to scroll to a specific SortableItem
  const scrollToItem = (itemId) => {
    setScrollToItemId(itemId);
  };

  useEffect(() => {
    const slide = slides[props.indexh];

    if (slide && slide.id) {
      scrollToItem(slide.id);
    }
  }, [props.indexh, slides]);

  return (
    <div className={`sidebar-container ${hasChanges ? "overflow" : ""}`}>
      <Box className="actions">
        <FormControl fullWidth size="small">
          <InputLabel
            sx={{ fontSize: 14 }}
            size="small"
            id="slide-actions-label"
          >
            Actions
          </InputLabel>
          <Select
            labelId="slide-label"
            id="slide-actions-select"
            value={selectedAction}
            label="Actions"
            disabled={selectedSlides.length > 0 ? false : true}
            variant={"outlined"}
            onChange={(event) => {
              handleActionChange(event);
            }}
            input={<OutlinedInput sx={{ fontSize: 14 }} label="Actions" />}
          >
            <MenuItem value={"delete"} sx={{ fontSize: 14 }}>
              Delete
            </MenuItem>
            <MenuItem value={"draft"} sx={{ fontSize: 14 }}>
              Toggle Hide
            </MenuItem>
            <MenuItem value={"move"} sx={{ fontSize: 14 }}>
              Move
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      {fromLayoutOpen && (
        <AddSlide
          title={"Slide layout"}
          fromLayoutOpen={fromLayoutOpen}
          onLayoutClose={() => {
            setFromLayoutOpen(false);
          }}
          presentation={props?.presentation}
          samplePresentation={props?.samplePresentation}
          samplePresentationLoading={props?.samplePresentationLoading}
          permissions={props.permissions}
          user={props?.user}
          onAddSlide={(slide, layout) => {
            setNewSlideDialogOpen(false);
            props?.onAddSlide(slide, layout, slideAddIndex + 1);
            setFromLayoutOpen(false);
          }}
        />
      )}

      {fromLibraryOpen && (
        <AddLibrarySlide
          title={"Presentation Library"}
          type="copy"
          fromLibraryOpen={fromLibraryOpen}
          onLayoutClose={() => {
            setFromLibraryOpen(false);
          }}
          user={props?.user}
          presentation={props?.presentation}
          categories={props.categories}
          languages={props.languages}
          themes={props.themes}
          onAddSlides={(slides) => {
            setNewSlideDialogOpen(false);
            props?.onAddLibrarySlides(slides, slideAddIndex + 1);
            setFromLibraryOpen(false);
          }}
        />
      )}

      {fromCloneOpen && (
        <AddLibrarySlide
          title={"Presentation Library"}
          fromLibraryOpen={fromCloneOpen}
          onLayoutClose={() => {
            setFromCloneOpen(false);
          }}
          user={props?.user}
          presentation={props?.presentation}
          categories={props.categories}
          languages={props.languages}
          themes={props.themes}
          type={"clone"}
          onAddSlides={(slides) => {
            setNewSlideDialogOpen(false);
            props?.onAddCloneSlides(slides, slideAddIndex + 1);
            setFromCloneOpen(false);
          }}
        />
      )}

      {slides.length > 0 ? (
        <div className={`thumbnail-wrapper slides`}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            // onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
          >
            <SortableContext
              handle
              items={slides.map((slide) => slide.id)}
              strategy={verticalListSortingStrategy}
            >
              {slides.map((slide, index) => {
                const isSelected =
                  selectedSlides.indexOf(slide.id) > -1 ? true : false;
                const isLibrarySlide =
                  slide.initial_presentation === props.presentation.id
                    ? false
                    : true;

                return (
                  <SortableItem
                    key={`sortable-${slide.id}`}
                    refProp={index === props.indexh ? scrollToItemRef : null}
                    index={index}
                    id={slide.id}
                    slide={slide}
                    indexh={props?.indexh}
                    navigateTo={props?.navigateTo}
                    originalSlides={props?.presentation?.slides}
                    handleNewSlideDialogClickOpen={(slideIndex) => {
                      handleNewSlideDialogClickOpen();
                      setSlideAddIndex(slideIndex);
                    }}
                    handleNewSlideDialogClose={handleNewSlideDialogClose}
                    hasChanges={hasChanges}
                    onSlideToggled={() => {
                      if (selectedSlides.includes(slide.id)) {
                        const newArray = selectedSlides.filter(
                          (e) => e !== slide.id
                        );
                        setSelectedSlides(newArray);
                      } else {
                        const newArray = [...selectedSlides, slide.id];
                        setSelectedSlides(newArray);
                      }
                    }}
                    isSelected={isSelected}
                    isDragDisabled={selectedSlides.length > 0 ? true : false}
                    isLibrarySlide={isLibrarySlide}
                    presentation={{ id: props.presentation.id }}
                    isFirst={index === 0 ? true : false}
                    isLast={index === slides.length - 1 ? true : false}
                  />
                );
              })}
            </SortableContext>
          </DndContext>
        </div>
      ) : (
        <div className={`thumbnail-wrapper slides`}>
          <Row className="mt-2 text-center">
            <Col>
              <Button
                startIcon={<Add />}
                onClick={handleNewSlideDialogClickOpen}
              >
                Add slide
              </Button>
            </Col>
          </Row>
        </div>
      )}

      {hasChanges && (
        <div className="sidebar-save">
          <Button
            variant="contained"
            onClick={() => {
              let new_order = [];

              for (let i = 0; i < slides.length; i++) {
                new_order.push(slides[i].id);
              }

              props?.updateSlidesOrder(new_order);
            }}
          >
            Save
          </Button>
        </div>
      )}

      <Dialog
        fullScreen={fullScreen}
        open={newSlideDialog}
        onClose={handleNewSlideDialogClose}
        aria-labelledby="add-slide-dialog"
        maxWidth={"sm"}
        fullWidth={true}
        className="add-library-modal"
      >
        <DialogTitle
          id="add-slide-dialog-title"
          sx={{
            backgroundColor: "primary.light",
          }}
        >
          <Row>
            <Col>{"Add slide"}</Col>
            <Col className="d-flex justify-content-end">
              <Button
                sx={{
                  backgroundColor: "primary.contrastText",
                  width: "130px",
                  height: "35px",
                  textTransform: "none",
                  fontWeight: "400",
                }}
                variant="outlined"
                onClick={handleNewSlideDialogClose}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </DialogTitle>
        <List sx={{ pt: 0 }}>
          <ListItem
            button
            onClick={() => {
              setFromLayoutOpen(true);
              handleNewSlideDialogClose();
            }}
          >
            <ListItemIcon
              sx={{
                color: "primary.main",
              }}
            >
              <TextSnippet />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: "primary.main",
              }}
              primary="Create NEW from layout"
              secondary="Creates a new, original slide"
            />
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setFromLibraryOpen(true);
              handleNewSlideDialogClose();
            }}
          >
            <ListItemIcon
              sx={{
                color: "primary.main",
              }}
            >
              <InsertLink />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: "primary.main",
              }}
              primary={"Create COPY from library"}
              secondary={
                "A non-editable copy of a slide, which is linked to the original"
              }
            />
          </ListItem>

          <ListItem
            button
            onClick={() => {
              setFromCloneOpen(true);
              handleNewSlideDialogClose();
            }}
          >
            <ListItemIcon
              sx={{
                color: "primary.main",
              }}
            >
              <ContentCopy />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: "primary.main",
              }}
              primary={"Create DUPLICATE from library"}
              secondary={
                "An editable copy of a slide, which in NOT linked to the original"
              }
            />
          </ListItem>
        </List>
        <Box className="alert-container">
          <Alert severity="warning">
            PLEASE NOTE: DUPLICATE functionality is not available on all slides
          </Alert>
        </Box>
        <DialogActions></DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={showMoveSlides}
        onClose={handleShowMoveSlideClose}
        aria-labelledby="add-slide-dialog"
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogTitle>Move Slide(s)</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a slide number, or use one of the buttons below.
          </DialogContentText>
          <br />
          <Button onClick={() => setSlidesPosition(1)}>To start</Button>
          <Button onClick={() => setSlidesPosition(slides.length)}>
            To end
          </Button>
          <TextField
            autoFocus
            margin="dense"
            id="slides-position"
            label="To slide position"
            type="number"
            fullWidth
            variant="standard"
            value={slidesPosition}
            InputProps={{
              inputProps: {
                max: slides.length,
                min: 1,
              },
            }}
            onChange={(event) => {
              setSlidesPosition(event.target.value);
            }}
            onBlur={() => {
              if (slidesPosition > slides.length) {
                setSlidesPosition(slides.length);
              }

              if (slidesPosition < 1) {
                setSlidesPosition(1);
              }
            }}
          />
        </DialogContent>
        <DialogActions className="pb-3 px-3">
          <Button
            className="mui-cancel"
            variant="outlined"
            onClick={handleShowMoveSlideClose}
          >
            Cancel
          </Button>
          <Button
            className="mui-confirm ms-3"
            variant="contained"
            color="secondary"
            onClick={handleMoveSlides}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const originalIndex = props.originalSlides.findIndex(
    (x) => x.id === props.id
  );

  /**
   * Identifier to check which item is active
   *
   * If item order is not changed, use reveal index
   * Else use original slide index
   */
  let indexCheck = props.index;
  let navigateIndex = props.index;

  if (props.hasChanges) {
    indexCheck = originalIndex;
    navigateIndex = originalIndex;
  }

  // console.log('index: ', indexCheck, ' - active index: ', props.indexh);
  const isFirst = props.isFirst;
  const isLast = props.isLast;

  const InitialAdd = () => {
    if (isFirst) {
      return (
        <div className="slide-add top">
          <Fab
            id="select-layout"
            onClick={() => {
              props.handleNewSlideDialogClickOpen(-1);
            }}
            size="small"
            color="primary"
            aria-label="Select layout"
          >
            <Add />
          </Fab>
        </div>
      );
    } else {
      return null;
    }
  };

  if (props.slide.published === true) {
    return (
      <>
        <div
          ref={setNodeRef}
          style={style}
          className={`drag-wrap ${indexCheck === props.indexh ? "active" : ""}`}
          data-id={props.id}
          data-first={isFirst}
          data-last={isLast}
        >
          <InitialAdd />
          <div
            ref={props.refProp}
            className={`slide-thumb-container d-flex`}>
            <div className="slide-thumb-index">
              <Stack
                direction="column"
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  disabled={true}
                  variant="text"
                  className="index-btn"
                  {...listeners}
                  {...attributes}
                >
                  {props.index + 1}
                </Button>
                <Button
                  disabled={props.isDragDisabled}
                  variant="text"
                  className="move-btn"
                  {...listeners}
                  {...attributes}
                >
                  <DragIndicator color="#777" fontSize={"14px"} />
                </Button>
                <div className="selector">
                  <Checkbox
                    checked={props.isSelected}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}
                    onChange={() => {
                      props.onSlideToggled();
                    }}
                  />
                </div>
              </Stack>
            </div>
            <Tooltip
              title={`S=${props.slide.id} (${props.slide.title})`}
              placement="right"
            >
              <div
                className="slide-thumb-wrapper"
                onClick={() => {
                  props.navigateTo(navigateIndex);
                }}
              >
                <div className="slide-preview">
                  {props.isLibrarySlide && (
                    <Tooltip
                      id="thumb-library-id"
                      title={`P=${props.slide.initial_presentation}`}
                      placement="right"
                    >
                      <div className="library-slide-indicator">
                        <span className="icon-container">
                          <span className="icon">
                            <NounLink
                              style={{
                                transform: "rotate(45deg)",
                                pointerEvents: "none",
                              }}
                            />
                          </span>
                          <span className="tri" />
                        </span>
                      </div>
                    </Tooltip>
                  )}

                  <Thumbnail
                    isSlide={true}
                    isActive={indexCheck === props.indexh}
                    slide={props.slide}
                    presentation={props?.presentation}
                  />
                </div>
              </div>
            </Tooltip>
          </div>

          <div className="slide-add">
            <Fab
              id="select-layout"
              onClick={() => {
                props.handleNewSlideDialogClickOpen(navigateIndex);
              }}
              size="small"
              color="primary"
              aria-label="Select layout"
            >
              <Add />
            </Fab>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          ref={setNodeRef}
          style={style}
          className={`drag-wrap draft ${
            indexCheck === props.indexh ? "active" : ""
          }`}
          data-id={props.id}
          data-first={isFirst}
          data-last={isLast}
        >
          <InitialAdd />
          <div ref={props.refProp} className="slide-draft d-flex">
            <div className="slide-thumb-index">
              <Stack
                direction="column"
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <div className="selector">
                  <Checkbox
                    checked={props.isSelected}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}
                    onChange={() => {
                      props.onSlideToggled();
                    }}
                  />
                </div>
              </Stack>
            </div>

            <div
              className="slide-thumb-draft d-flex align-items-center"
              onClick={() => {
                props.navigateTo(navigateIndex);
              }}
            >
              <div className="info">
                <span>{props.index + 1}</span>
                <span>{` (Hidden)`}</span>
              </div>
            </div>
          </div>

          <div className="slide-add">
            <Fab
              id="select-layout"
              onClick={() => {
                props.handleNewSlideDialogClickOpen(navigateIndex);
              }}
              size="small"
              color="primary"
              aria-label="Select layout"
            >
              <Add />
            </Fab>
          </div>
        </div>
      </>
    );
  }
}
