import React, { useState, useEffect } from 'react';

// Modules


// App
import { filesystem_root, subdomain } from '../../../../../config';
import PostTitle from '../../../../../partials/postTitle';
import { jsonParser } from '../../../../../utils/helpers';

// UI components
import { Col } from 'react-bootstrap';
import { Box, Card, CardMedia, CardActionArea, CardContent, Skeleton, Typography, Checkbox } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import BlockIcon from '@mui/icons-material/Block';

export default function SlideThumbnail(props) {
  const [hasImage, setHasImage] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [isDisabled, setDisabled] = useState(props.isDisabled);

  useEffect(() => {
    setDisabled(props.isDisabled);
  }, [props.isDisabled]);

  const getThumbnail = () => {
    let thumb;
    const slide_json = jsonParser(props.slide.slide_json);

    if (slide_json?.reveal?.slideThumbnail?.field_media_image) {
      thumb = slide_json?.reveal?.slideThumbnail?.field_media_image;
    } else {
      const isLibrarySlide = props.slide.initial_presentation === props.presentation.id ? false : true;

      if (isLibrarySlide) {
        thumb = `${filesystem_root}/${subdomain}/presentations/${props.slide.initial_presentation}/${props.slide.initial_presentation}/thumbnails/slide_${props.slide.id}_thumbnail.jpg`;
      } else {
        thumb = `${filesystem_root}/${subdomain}/presentations/${props.presentation.id}/${props.presentation.id}/thumbnails/slide_${props.slide.id}_thumbnail.jpg`;
      }
    }

    return thumb;
  }

  return (
    <Col
      key={`slide-${props.slide.id ? props.slide.id : Date.now()}`}
      className={`slide-view-item d-flex  ${props.selector ? 'col-xl-1-2 col-xxl-1-2 px-1 pb-4 mb-1 position-relative' : 'mb-5'}`}
      xs={props.selector ? 4 : 12}
      sm={props.selector ? 4 : 6}
      md={props.selector ? 3 : 3}
      lg={props.selector ? 2 : ''}
      xxl={props.selector ? '' : 3}
    >
      <Card
        className='mui-card'
        sx={{
          width: '100%',
          border: props.isSelected ? 'solid 2px' : 'solid 1px #e6e6e6',
          borderColor: props.isSelected ? 'primary.main' : '#e6e6e6',
          opacity: props.isDisabled ? 0.25 : 1,
        }}
      >
        <CardActionArea
          className='ratio ratio-16x9 media'
          sx={{
            borderBottom: '1px solid #e6e6e6'
          }}
          onClick={() => {
            if (props.selector) {
              // props.onSelected();
              console.log("preview function goes here")
            }
          }}
        // onMouseEnter={()=>setHovered(true)}
        // onMouseLeave={()=>setHovered(false)}
        >
          {props.selector ? (
            <Box
              className='hoverArea'
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: hovered ? 99 : 0,
                opacity: hovered ? 1 : 0,
                transition: 'all 0.5s'
              }}
            >
              <PlayArrow
                sx={{
                  color: '#fff'
                }}
              />

            </Box>
            // <Box className={`select-container ${props.isSelected ? 'selected' : ''}`}>
            //   <span className='selected-icon'>
            //     <span className='icon'>
            //       <Check fontSize='16px' />
            //     </span>
            //     <span className='tri' />
            //   </span>
            // </Box>
          ) : (
            <Box className='play-container'>
              <PlayArrow
                fontSize='large'
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  margin: 'auto',
                  zIndex: 99,
                }}
              />
            </Box>
          )}
          {hasImage ? (
            <CardMedia
              className='thumb'
              component='img'
              image={getThumbnail()}
              alt={''}
              onError={(error) => setHasImage(false)}
            />
          ) : (
            <Box className='skeleton d-flex' data-thumb={getThumbnail()}>
              <Box className='w-50'>
                {props.selector ?
                  <Box sx={{
                    margin: '0 5%',
                    padding: '10px'
                  }}
                  >
                    <Skeleton animation={false} width='30%' height={15} />
                    <Skeleton animation={false} width='100%' height={15} />
                    <Skeleton animation={false} width='100%' height={15} />
                  </Box>
                  :
                  <Box sx={{ margin: '0 5%' }}>
                    <Skeleton animation={false} width='30%' height={20} />
                    <Skeleton animation={false} width='100%' height={20} />
                    <Skeleton animation={false} width='100%' height={20} />
                    <Skeleton animation={false} width='100%' height={20} />
                    <Skeleton animation={false} width='100%' height={20} />
                  </Box>
                }
              </Box>
              <Box className='w-50'>
                <Box className='w-100 h-100'>
                  <Skeleton className='right w-100 h-100' animation={false} variant='rectangular' />
                </Box>
              </Box>
            </Box>
          )}
          {/* <Box className='category'>
            <span className="category__text">{category}</span>
          </Box> */}
        </CardActionArea>
        <CardContent className='content'
          sx={props.selector && {
            padding: '0 !important',
            minHeight: 45,
            cursor: 'pointer',
            position: 'relative'
          }}
          onClick={() => {
            if (props.preventDuplicate !== "1") {
              props.onSelected();
            }
          }}
        >
          {props.selector ? (
            <Box
              sx={{
                display: 'flex',
              }}
            >
              {/* <PostTitle
                title={props.slide.title ? props.slide.title : 'sample title'}
                headingLevel={'h6'}
                className={`mb-0`}
              /> */}
              <Typography
                className='thumb-title'
                variant='subtitle'
                component='p'
                sx={{
                  fontWeight: '400',
                  fontSize: '12px',
                  padding: '3px 10px 0',
                  maxHeight: 35,
                  lineHeight: 1.5,
                }}
              >
                {props.slide.title ? props.slide.title : ''}
              </Typography>
            </Box>
          ) : (

            <PostTitle
              title={props.slide.title}
              headingLevel={'h6'}
              className={`mb-0`}
            />
          )}
        </CardContent>
      </Card>
      {props.preventDuplicate === '1' || isDisabled ?
        <BlockIcon
          color='error'
          sx={{
            position: 'absolute',
            right: 0,
            left: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            bottom: "-1px",
            transform: 'scale(0.5)'
          }} /> :
        <Checkbox
          size='small'
          disableRipple
          checked={props.isSelected}
          onClick={() => {
            props.onSelected();
          }}
          disabled={(props.preventDuplicate === '1' || isDisabled) ? true : false}
          sx={{
            width: 10,
            height: 10,
            position: 'absolute',
            right: 0,
            left: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            bottom: 5,
            transform: 'scale(0.6)'
          }}
        />
      }
    </Col>
  );
};
