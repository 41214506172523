import React, { useState } from 'react';

// Modules

// App

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RangeSlider from 'react-bootstrap-range-slider';

export default function BackgroundImageOpacity(props) {
  const [backgroundOpacity, setBackgroundOpacity] = useState(props.slideBackgroundOpacity ? props.slideBackgroundOpacity : 1);

  const onBackgroundImageOpacity = opacity => {
    setBackgroundOpacity(opacity);
    props.onBackgroundImageOpacity(opacity);
  }

  return (
    <div className='settings-wrap background-opacity'>
      <h6>{props.title}</h6>
      <Row>
        <Col>
          <RangeSlider
            min={0}
            max={1}
            step={0.01}
            value={backgroundOpacity}
            onChange={changeEvent => onBackgroundImageOpacity(changeEvent.target.value)}
            variant={'light'}
          />
        </Col>
      </Row>
    </div>
  );
};
