import React, { useState } from "react";

// Modules

// App
import MediaLibrary from '../mediaLibrary';

// UI components
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from '@mui/material';


export default function BespokeThumbnail(props) {
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  const [media, setMedia] = useState(props.media ? props.media : null);
  const title = props.title ? props.title : 'Add bespoke media'
  const hint = props.hint ? props.hint : 'To attach a media, please select or upload an image file via the media library';

  return (
    <div className='bespoke-slide-thumbnail'>
      <Row><h6 className="ss-subtitle-color">{title}</h6></Row>
      <Container className={`adv-slide-settings-sub-container ${media?.mid ? 'img' : ''}`}>
        {media?.mid ?
          <>
            <Col>
              <Row>
                <img alt='' className='thumbnail-container' src={media?.src} />
              </Row>
              <Row>
                <Row className="mb-2">
                  <Col xs={4} className="pe-0">
                    <label>File Name:</label>
                  </Col>
                  <Col>
                    <span className="value">{media?.name}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={4} className="pe-0">
                    <label>MID:</label>
                  </Col>
                  <Col>
                    <span className="value">{media?.mid}</span>
                  </Col>
                </Row>
              </Row>
            </Col>
            <Row className='btn-grp mt-3'>
              <Col xs={6} className='d-flex justify-content-center'>
                <Button
                  className="change-btn"
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    setShowMediaLibrary(true);
                  }}
                >
                  Change image
                </Button>
              </Col>
              <Col xs={6} className='d-flex justify-content-center'>
                <Button
                  className="remove-btn"
                  variant='contained'
                  color='error'
                  onClick={() => {
                    setMedia({});
                    props.onMediaInsert({});
                  }}
                >
                  Remove image
                </Button>
              </Col>
            </Row>
          </> :
          <>
            <Row className="hint">
              <span>{hint}</span>
            </Row>
            <Row className="d-flex justify-content-center media-lib-btn-container">
              <Col xs={'auto'}>
                <Button
                  className="media-lib-btn"
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    setShowMediaLibrary(true);
                  }}
                >
                  Media library
                </Button>
              </Col>
            </Row>
          </>
        }
      </Container>

      <MediaLibrary
        user={props.user}
        mediaType='image'
        setShowMediaLibrary={(condition => {
          setShowMediaLibrary(condition);
        })}
        showMediaLibrary={showMediaLibrary}
        onMediaInsert={(media) => {
          setMedia(media);
          props.onMediaInsert(media);
        }}
        selectedMid={media?.mid}
        files={props.files}
      />
    </div>
  );
};
