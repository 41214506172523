import React from 'react';

// Modules
import { connect } from 'react-redux';
import { Redirect } from '../../hooks/redirect';

// App
import withRouter from '../../hooks/withRouter';
import { serverUrl } from '../../config';
import { getOnlineNode } from '../../core/getNode';
import { postNode } from '../../core/postNode';
// import {postBase64} from '../../core/postFile';
import { alertMessages } from '../../partials/alertMessages';
import PageHeader from '../../partials/pageHeader';
import CategorySelector from '../../partials/categorySelector';
import AlertModal from '../../partials/alertModal';

// UI components
import { Container, Row, Col } from 'react-bootstrap';
import { Box, TextField, CircularProgress, Button, Stack, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { CheckCircle, Close } from '@mui/icons-material';

class AddPresentation extends React.Component {
  constructor() {
    super();
    this.state = {
      isComponentLoading: true,
      isLoading: false,
      title: '',
      body: '',

      themes: [{ value: 0, title: 'Select a theme' }],
      selectedTheme: 0,
      selectedThemeItem: {
        label: 'Select a theme',
        value: 0
      },

      modalVisible: false,
      postModalVisible: false,
      unauthorised: false,
    };
  }

  componentDidMount() {
    if (this.props?.permissions?.access_presentation_creator_mode !== true) {
      this.setState({
        unauthorised: true,
      })
    } else {
      this.getTaxonomies();
    }
  }

  getTaxonomies = () => {
    getOnlineNode('api/categories?vid=themes')
      .then(response => {
        let themes = [{ tid: 0, label: 'Select a theme' }];

        response.data.forEach(item => {
          if (item.has_view_permission === true) {
            if (item.vid === 'themes') {
              themes.push({
                ...item,
                value: item.tid,
                label: item.label ? item.label : item.title
              });
            }
          }
        });

        this.setState({
          isComponentLoading: false,
          themes: themes,
        });
      })
      .catch(_error => {
        console.log('err: ', _error);
        this.setState({
          isComponentLoading: false,
        });
      });
  };

  setTitle = _title => {
    this.setState({
      title: _title,
    });
  };

  setBody = _body => {
    this.setState({
      body: _body,
    });
  };

  saveNode = () => {
    const { title, body, selectedTheme } = this.state;

    this.setState({
      isLoading: true,
    });

    let theme;
    if (selectedTheme && selectedTheme !== 0) {
      theme = selectedTheme;
    }

    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/presentation/presentation',
        },
      },
      title: {
        value: title,
      },
      body: {
        value: body,
      },
      theme: [
        {
          target_id: theme,
        },
      ]
    };

    postNode(
      'entity/presentation',
      data,
      this.props.user.csrf_token,
    )
      .then(_response => {
        if (_response.status === 201) {
          this.setState({
            isLoading: false,
            postModalVisible: true,
            alertTitle: alertMessages.presentationSubmission.title,
            alertBody: alertMessages.presentationSubmission.message,
          });
        } else {
          this.setState({
            isLoading: false,
            modalVisible: true,
            alertType: 'error',
            alertTitle: alertMessages.postNodeFailed.title,
            alertBody: alertMessages.postNodeFailed.message,
            alertConfirmButton: true,
            redirectBack: false,
          });
        }
      })
      .catch(_error => {
        this.setState({
          isLoading: false,
          modalVisible: true,
          alertType: 'error',
          alertTitle: alertMessages.postNodeFailed.title,
          alertBody: alertMessages.postNodeFailed.message,
          alertConfirmButton: true,
          redirectBack: false,
        });
      });
  };

  handleThemeSelection = (tid) => {
    let themeItem = this.state.themes.filter(item => {
      return item.tid === tid
    });

    this.setState({
      selectedThemeItem: {
        label: themeItem[0].label,
        value: themeItem[0].tid
      },
      selectedTheme: parseInt(tid, 10)
    });
  };

  setModalVisible = _visible => {
    this.setState({
      modalVisible: _visible,
    });
  };

  getThemeTitle = () => {
    return this.state.themes.find(
      x => x.tid === this.state.selectedTheme,
    ).title;
  }

  errorAlerts = () => {
    return (
      <AlertModal
        showAlert={this.state.modalVisible}
        showAlertCallback={this.setModalVisible}
        alertType={this.state.alertType}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        cancelButton={this.state.alertCancelButton}
        confirmButton={this.state.alertConfirmButton}
        cancelButtonLabel={this.state.cancelButtonLabel}
        confirmButtonLabel={this.state.confirmButtonLabel}
      />
    )
  }

  render() {
    if (this.state.unauthorised) {
      return <Redirect url='/presentations' />;
    }

    if (this.state.isComponentLoading) {
      return (
        <div className='d-flex h-100 justify-content-center align-items-center'>
          <CircularProgress size={20} />
        </div>
      );
    } else {
      return (
        <main className='create-presentation presentations screen'>
          <PageHeader
            pageName='Create a Presentation'
            filters={false}
          />

          {this.errorAlerts()}

          <div className='ps-modal presentation-settings-modal'>
            <div className='ps-modal-content'>
              <Container>
                <Row>
                  <Col xs={12}>
                    <Box className='ls'>
                      <div className='form-group'>
                        <label htmlFor='presentation-title'>Title</label>
                        <TextField
                          id='presentation-title'
                          variant='outlined'
                          fullWidth
                          size='small'
                          value={this.state.title}
                          sx={{
                            "& .MuiInputBase-input": {
                              height: 28,
                              // paddingTop: '11px',
                              // paddingBottom: '11px'
                            }
                          }}
                          onInput={(event) => {
                            this.setTitle(event.target.value);
                          }}
                        />
                      </div>
                      <div className='form-group'>
                        <label htmlFor='description'>Description</label>
                        <TextField
                          id='description'
                          variant='outlined'
                          fullWidth
                          size='small'
                          value={this.state.body}
                          sx={{
                            "& .MuiInputBase-root": {
                              minHeight: 45,
                              paddingTop: '3px',
                              paddingBottom: '3px'
                            }
                          }}
                          onInput={(event) => {
                            this.setBody(event.target.value);
                          }}
                          multiline
                          maxRows={3}
                        />
                      </div>
                    </Box>
                  </Col>
                  <Col xs={12}>
                    <Box className='rs'>
                      <Box className='form-group'>
                        <label htmlFor='presentationThemes'>Theme</label>
                        <CategorySelector
                          name='presentationThemes'
                          label='Theme'
                          categories={this.state.themes}
                          selectedCategory={this.state.selectedThemeItem?.value === 0 ? { label: this.state.selectedThemeItem?.label, value: 0 } : this.state.selectedThemeItem}
                          outlined={true}
                          variant='outlined'
                          size='small'
                          handleCategorySelection={(tid) => {
                            this.handleThemeSelection(tid);
                          }}
                        />
                      </Box>
                    </Box>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
                      <Button
                        variant='outlined'
                        sx={{
                          backgroundColor: 'primary.contrastText',
                          textTransform: 'none',
                          marginRight: '10px'
                        }}
                        className='cancel'
                        onClick={() => {
                          this.props.navigate('/presentations-creator');
                        }}>
                        Cancel
                      </Button>
                      <Button
                        variant='contained'
                        color='secondary'
                        className='action'
                        sx={{
                          textTransform: 'none'
                        }}
                        onClick={() => {
                          this.saveNode();
                        }}>
                        Create Presentation
                      </Button>
                    </Stack>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>

          {this.state.postModalVisible && (
            <AlertModal
              showAlert={this.state.postModalVisible}
              onClose={() => {
                this.props.navigate('/presentations-creator');
              }}
              showCaLogo={true}
              alertMessageTitle={"Success"}
              alertMessageBody={alertMessages.presentationSubmission.message}
              cancelButton={false}
              confirmButton={true}
              confirmButtonLabel="Confirm"
              onConfirm={() => {
                this.props.navigate('/presentations-creator');
              }}
            />
          )}
        </main>
      );
    }

  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  permissions: state.permissionsReducer.permissions,
});

export default withRouter(connect(mapStateToProps)(AddPresentation));
