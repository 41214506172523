import React, { useState, useRef, useEffect } from 'react';

// Modules

// App

// UI components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, FormControlLabel, Checkbox, Tooltip, IconButton, Box } from '@mui/material';
import { PlayArrow, Settings, KeyboardBackspace } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


export default function Toolbar(props) {
  const backTooltipTarget = useRef(null);
  const viewTooltipTarget = useRef(null);
  const settingsTooltipTarget = useRef(null);
  const logoTooltipTarget = useRef(null);
  const backgroundTooltipTarget = useRef(null);
  const transitionTooltipTarget = useRef(null);
  const deleteTooltipTarget = useRef(null);
  const notesTooltipTarget = useRef(null);

  const [index, setIndex] = useState(0);
  const [slide, setSlide] = useState(null);
  const [isLibrarySlide, setLibrarySlide] = useState(false);

  useEffect(() => {
    setIndex(props.indexh);
  }, [props.indexh]);

  useEffect(() => {
    if (props.presentation && props.presentation?.slides?.length > 0) {
      props.presentation.slides.forEach((slide, slideIndex) => {
        if (index === slideIndex) {
          setSlide(slide);

          if (slide.initial_presentation !== props.presentation.id) {
            setLibrarySlide(true);
          } else {
            setLibrarySlide(false);
          }
        }
      });
    }
  }, [index, props.presentation]);

  return (
    <div className='ps-toolbar'>
      <Container fluid>
        <Row className='align-items-center toolbar-row first-row'>
          <Col sm={12} md={6} className='ls'>
            <Row className='align-items-center'>
              <Col xs='auto'>
                <Tooltip id='back-tooltip' title='Back to presentations' placement='bottom'>
                  <IconButton
                    ref={backTooltipTarget}
                    className='back-btn d-flex align-items-center'
                    onClick={() => {
                      props.goBack();
                    }}
                    color='white'
                  >
                    <KeyboardBackspace />
                  </IconButton>
                </Tooltip>
              </Col>
              <Col xs='auto'>
                <Tooltip id='settings' title='Presentation settings' placement='bottom'>
                  <IconButton
                    ref={settingsTooltipTarget}
                    className='settings-btn d-flex align-items-center'
                    onClick={() => {
                      props.onPresentationModal();
                    }}
                    color='white'
                  >
                    <Settings />
                  </IconButton>
                </Tooltip>
              </Col>
              <Col xs='auto'>
                {props?.presentation?.slides?.length > 0 && (
                  <div className='d-flex align-items-center'>
                    <Button
                      ref={viewTooltipTarget}
                      className='preview-btn d-flex align-items-center'
                      onClick={() => {
                        window.open(`/presentations/preview/${props?.presentation?.id}`, '_blank');
                      }}
                      color='white'
                      startIcon={<PlayArrow fontSize={'small'} />}
                    >
                      Preview
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={6} className='rs'>
            <Row className='align-items-center justify-content-end'>
              {!isLibrarySlide && (
                <Col xs='auto'>
                  <Box className='bx checkbox'>
                    <FormControlLabel
                      labelPlacement='end'
                      control={
                        <Checkbox
                          size='small'
                          checked={slide?.published === true ? false : true}
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '0.75rem',
                              fontWeight: 500,
                              color: '#777',
                            }
                          }}
                          onChange={(event) => {
                            props.toggleStatus([slide.id]);
                          }}
                        />
                      }
                      label='Hide'
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: '0.75rem',
                          fontWeight: 500,
                          color: '#777',
                          marginRight: 0,
                        }
                      }}
                    />
                  </Box>
                </Col>
              )}
              <Col xs='auto'>
                <Box className='bx'>
                  <Tooltip id='delete-tooltip' title='Delete slide' placement='bottom'>
                    <Button
                      ref={deleteTooltipTarget}
                      className='delete-button'
                      sx={{ textTransform: 'none' }}
                      onClick={() => {
                        props.onRemoveSlides([slide.id]);
                      }}
                      color={'solid'}
                      startIcon={<DeleteOutlineIcon fontSize={'small'} />}
                    >
                      Delete
                    </Button>
                  </Tooltip>
                </Box>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className='align-items-center toolbar-row second-row'>
          <Col sm={12} md={6} className='ls'>
            <h2 className='ps-title'>{props?.presentation?.title} (#{props?.presentation?.id})</h2>
          </Col>
          <Col sm={12} md={6} className='rs'>
            {!isLibrarySlide && (
              <Row className='align-items-center justify-content-end'>
                <Col xs='auto'>
                  <Box className='bx'>
                    <Tooltip id='slide-settings-tooltip' title='Modify slide settings' placement='bottom'>
                      <Button
                        className='slide-button'
                        sx={{ textTransform: 'none' }}
                        onClick={() => {
                          props.onSlideSettings();
                        }}
                        color={'solid'}
                      >
                        Settings
                      </Button>
                    </Tooltip>
                  </Box>
                </Col>

                <Col xs='auto'>
                  <Box className='bx'>
                    <Tooltip id='background-tooltip' title='Change slide background' placement='bottom'>
                      <Button
                        ref={backgroundTooltipTarget}
                        className='background-button'
                        sx={{ textTransform: 'none' }}
                        onClick={() => {
                          props.onBackgroundSettings();
                        }}
                        color={'solid'}
                      >
                        Background
                      </Button>
                    </Tooltip>
                  </Box>
                </Col>

                <Col xs='auto'>
                  <Box className='bx'>
                    <Tooltip id='logo-tooltip' title='Add logo to slide' placement='bottom'>
                      <Button
                        ref={logoTooltipTarget}
                        className='logo-button'
                        sx={{ textTransform: 'none' }}
                        onClick={() => {
                          props.onLogoSettings()
                        }}
                        color={'solid'}
                      >
                        Logo
                      </Button>
                    </Tooltip>
                  </Box>
                </Col>

                <Col xs='auto'>
                  <Box className='bx'>
                    <Tooltip id='transition-tooltip' title='Change slide transition' placement='bottom'>
                      <Button
                        ref={transitionTooltipTarget}
                        className='transition-button'
                        sx={{ textTransform: 'none' }}
                        onClick={() => {
                          props.onTransitionSettings();
                        }}
                        color={'solid'}
                      >
                        Transitions
                      </Button>
                    </Tooltip>
                  </Box>
                </Col>
                <Col xs='auto'>
                  <Box className='bx'>
                    <Tooltip id='notes-tooltip' title='Add slide notes' placement='bottom'>
                      <Button
                        ref={notesTooltipTarget}
                        className='notes-button'
                        sx={{ textTransform: 'none' }}
                        onClick={() => {
                          props.onSlideNotes();
                        }}
                        color={'solid'}
                      >
                        Notes
                      </Button>
                    </Tooltip>
                  </Box>
                </Col>




              </Row>
            )}
          </Col>
        </Row>


      </Container>
    </div>
  );
};
