import { CloseIcon, ImageIcon, PinIcon } from '../../icons/icons';

/**
 * Build styles
 */
require('./index.css').toString();

export default class Map {

  static get toolbox() {
    return {
      icon: PinIcon,
      title: 'Map',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'map-container',
      wrapper: 'map',
      newButton: 'map-btn',
      replaceButton: 'map-replace-btn',

      modal: 'map-modal',
      modalInner: 'map-modal-inner',
      input: 'map-input',
      modalImgContainer: 'modal-fallback-img-container',
      modalImg: 'modal-fallback-img',
      fallbackButton: 'map-fallback-btn',
      codeEditor: 'map-editor',
      close: 'map-close',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      clear: 'clear',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      label: data.label || '',
      latitude: data.latitude || '',
      longitude: data.longitude || '',
      url: data.url || '',
      mid: data.mid || '',
      width: data.width || '',
      height: data.height || '',
      x: data.x || '',
      y: data.y || '',
      maxWidth: data.maxWidth || '',
      maxHeight: data.maxHeight || '',
    };

    this._element = this.getElement();
    this._labelElement = this.labelElement();
    this._latitudeElement = this.latitudeElement();
    this._longitudeElement = this.longitudeElement();
    this._toggleElement = this.toggleElement();
    this._modalFallbackElement = this.modalFallbackElement();
    this._modal = this.modalElement();
  }

  getElement() {
    const tag = document.createElement('div');
    tag.classList.add(this._CSS.wrapper);

    tag.setAttribute('location-label', this._data.label);
    tag.setAttribute('location-lat', this._data.latitude);
    tag.setAttribute('location-lng', this._data.longitude);

    let html;

    const apiKey = this._settings.googleApiKey;

    if (!apiKey) {
      html = '<div class="d-flex h-100 justify-content-center align-items-center"><b>Map can\'t be displayed - API key error</b></div>';
    } else {
      const pinSrc = `${this._settings.themeDirectory}/dist/images/icons/${this._settings.pin}`;
      const label = encodeURIComponent(this._data.label);

      const params = `key=${apiKey}&lat=${this._data.latitude}&lng=${this._data.longitude}&label=${label}&pin=${pinSrc}&mode=edit`;
      const src = `/map.html?${params}`;
      html = `<iframe src="${src}" />`;
    }

    tag.innerHTML = html;

    return tag;
  }

  labelElement() {
    const label = document.createElement('p');
    label.dataset.placeholder = `Location label`;
    label.classList.add(this._CSS.input);

    label.contentEditable = this.readOnly ? 'false' : 'true';

    label.innerHTML = this._data.label || '';

    const that = this;
    label.addEventListener('input', function (event) {
      that._data.label = event.target.value;
    });

    return label;
  }

  latitudeElement() {
    const latitude = document.createElement('p');
    latitude.dataset.placeholder = `Location coordinates (latitude)`;
    latitude.classList.add(this._CSS.input);
    latitude.contentEditable = this.readOnly ? 'false' : 'true';

    latitude.innerHTML = this._data.latitude || '';

    const that = this;
    latitude.addEventListener('input', function (event) {
      that._data.latitude = event.target.value;
    });

    return latitude;
  }

  longitudeElement() {
    const longitude = document.createElement('p');
    longitude.dataset.placeholder = `Location coordinates (longitude)`;
    longitude.classList.add(this._CSS.input);
    longitude.contentEditable = this.readOnly ? 'false' : 'true';

    longitude.innerHTML = this._data.longitude || '';

    const that = this;
    longitude.addEventListener('input', function (event) {
      that._data.longitude = event.target.value;
    });

    return longitude;
  }

  modalFallbackElement() {
    const imgContainer = document.createElement('div');
    const btn = document.createElement('button');

    imgContainer.classList.add(this._CSS.modalImgContainer);
    btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon);
    btn.innerHTML = `${ImageIcon}`;
    btn.onclick = () => {
      const blockIndex = this.api.blocks.getCurrentBlockIndex();
      const block = this.api.blocks.getBlockByIndex(blockIndex);
      this._settings.showMediaLibrary({
        details: block,
        data: this._data,
        bundle: 'image',
      });
    };

    if (this._data.url) {
      const fallbackImg = document.createElement('img');
      fallbackImg.setAttribute('src', this._data.url);
      fallbackImg.setAttribute('data-mid', this._data.mid);
      fallbackImg.setAttribute('data-x', this._data.x);
      fallbackImg.setAttribute('data-y', this._data.y);
      fallbackImg.setAttribute('data-width', this._data.width);
      fallbackImg.setAttribute('data-height', this._data.height);
      fallbackImg.setAttribute('data-maxwidth', this._data.maxWidth);
      fallbackImg.setAttribute('data-maxheight', this._data.maxHeight);

      fallbackImg.classList.add(this._CSS.modalImg);
      imgContainer.appendChild(fallbackImg);
    }

    imgContainer.appendChild(btn);

    return imgContainer;
  }

  modalElement() {
    const modal = document.createElement('div');
    const modalInner = document.createElement('div');

    const row1 = document.createElement('div');
    const row2 = document.createElement('div');
    const row3 = document.createElement('div');
    const row4 = document.createElement('div');
    const row5 = document.createElement('div');
    const col1 = document.createElement('div');
    const col2 = document.createElement('div');
    const col3 = document.createElement('div');
    const col4 = document.createElement('div');
    const col5 = document.createElement('div');
    const col6 = document.createElement('div');
    const col7 = document.createElement('div');
    const col8 = document.createElement('div');
    const col9 = document.createElement('div');
    const col10 = document.createElement('div');
    const labelLabel = document.createElement('label');
    const labelLat = document.createElement('label');
    const labelLong = document.createElement('label');
    const labelFallback = document.createElement('label');
    const modalTitle = document.createElement('h4');

    row1.classList.add('row');
    row2.classList.add('row');
    row3.classList.add('row');
    row4.classList.add('row');
    row5.classList.add('row');
    row5.classList.add('mb-3');
    col1.classList.add('col-3');
    col2.classList.add('col');
    col3.classList.add('col-3');
    col4.classList.add('col');
    col5.classList.add('col-3');
    col6.classList.add('col');
    col7.classList.add('col-3');
    col8.classList.add('col');
    col9.classList.add('col');
    col10.classList.add('col-2');
    col10.classList.add('d-flex');
    col10.classList.add('justify-content-end');
    labelLabel.classList.add('map-block-modal-label');
    labelLat.classList.add('map-block-modal-label');
    labelLong.classList.add('map-block-modal-label');
    labelFallback.classList.add('map-block-modal-label');
    modalTitle.classList.add('map-block-modal-label');

    labelLabel.innerHTML = "Location label";
    labelLat.innerHTML = "Latitude";
    labelLong.innerHTML = "Longitude";
    labelFallback.innerHTML = "Fallback image";
    modalTitle.innerHTML = "Google map latitude & longitude";

    const close = document.createElement('button');
    close.innerHTML = `${CloseIcon}`;
    close.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.clear);
    close.classList.add(this._CSS.close);

    col1.appendChild(labelLabel)
    col3.appendChild(labelLat)
    col5.appendChild(labelLong)
    col7.appendChild(labelFallback)
    col9.appendChild(modalTitle)

    col2.appendChild(this._labelElement)
    col4.appendChild(this._latitudeElement)
    col6.appendChild(this._longitudeElement)
    col7.appendChild(labelFallback)
    col8.appendChild(this._modalFallbackElement)
    col10.appendChild(close)

    row1.appendChild(col1);
    row1.appendChild(col2);
    row2.appendChild(col3);
    row2.appendChild(col4);
    row3.appendChild(col5);
    row3.appendChild(col6);
    row4.appendChild(col7);
    row4.appendChild(col8);
    row5.appendChild(col9);
    row5.appendChild(col10);


    close.addEventListener('click', function (event) {
      event.preventDefault();
      modal.classList.remove('show');
    });


    modal.classList.add(this._CSS.modal);
    modal.setAttribute('data-mutation-free', true);
    modalInner.classList.add(this._CSS.modalInner);

    modalInner.appendChild(row5);
    modalInner.appendChild(row1);
    modalInner.appendChild(row2);
    modalInner.appendChild(row3);
    modalInner.appendChild(row4);

    modal.appendChild(modalInner);

    return modal;
  }

  showEditor() {
    this._modal.classList.add('show');
  }

  toggleElement() {
    const tag = document.createElement('button');
    tag.classList.add('sidebar-btn');

    const leftIcon = document.createElement('span');
    const rightIcon = document.createElement('span');

    leftIcon.classList.add('material-icons', 'left');
    rightIcon.classList.add('material-icons', 'right');

    leftIcon.innerHTML = 'chevron_left';
    rightIcon.innerHTML = 'chevron_right'

    tag.addEventListener('click', function (event) {
      event.preventDefault();

      this.classList.toggle('active');
    });

    tag.appendChild(leftIcon);
    tag.appendChild(rightIcon);

    return tag;
  }

  render() {
    let container = document.createElement('div');
    let tag = this._element;
    let btn = document.createElement('button');

    container.classList.add(this._CSS.container);

    if (this._data.label || this._data.latitude || this._data.longitude) {
      btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon);
      btn.innerHTML = `${Map.toolbox.icon}`;
    } else {
      btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon);
      btn.classList.add(this._CSS.newButton);
      btn.innerHTML = `${Map.toolbox.icon}`;
    }

    btn.onclick = () => {
      this.showEditor();
    }

    container.appendChild(tag);
    // container.appendChild(this._toggleElement);
    container.appendChild(this._modal);

    container.appendChild(btn);
    return container;
  }

  save() {
    this._data = {
      label: this._labelElement?.innerHTML || '',
      latitude: this._latitudeElement?.innerHTML || '',
      longitude: this._longitudeElement?.innerHTML || '',
      url: this._data.url || '',
      mid: this._data.mid || '',
      x: this._data.x || '',
      y: this._data.y || '',
      width: this._data.width || '',
      height: this._data.height || '',
      maxWidth: this._data.maxWidth || '',
      maxHeight: this._data.maxHeight || '',
    }

    return this._data;
  }
}