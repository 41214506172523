import React from 'react';

// Modules

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../assets/ca-logo-pwa.png';
import { Box, CircularProgress } from '@mui/material';

export default function CompanyappLoader() {
  return (
    <div className='loading-container w-100 h-100 d-flex justify-content-center align-items-center'>
      <Row className='text-center'>
        <Col xs={12}>
          <Box sx={{ width: 240, textAlign: 'center', margin: '0 auto 20px' }}>
            <img style={{ margin: '0 auto', display: 'block' }} src={logo} alt={"companyapp logo"} />
          </Box>
        </Col>
        <Col xs={12}><CircularProgress size={32} disableShrink /></Col>
        <Col xs={12}>
          <span style={{ margin: '5px 0 0 10px', display: 'block' }}>
            Loading...
          </span>
        </Col>
      </Row>
    </div>
  );
}