import axios from 'axios';
axios.defaults.withCredentials = true;

export const serverUrl = process.env.REACT_APP_CMS;
export const filesystem_root = process.env.REACT_APP_FILES;
export const subdomain = process.env.REACT_APP_SUBDOMAIN;
export const proxy = process.env.REACT_APP_FRAME_PROXY;
export const publishing_sse = process.env.REACT_APP_PUBLISHING_SSE;
export const globalThemeDirectory = `${filesystem_root}/${subdomain}/themes/presenter_global_theme`;
export const globalThemeStylesheet = `${globalThemeDirectory}/dist/css/main.css`;
export const root_auth_handshake = process.env.REACT_APP_ROOT_AUTH_HANDSHAKE;

export const api = axios.create({
  baseURL: serverUrl,
  withCredentials: true,
  timeout: 300000, // 30s
  headers: {
    'Content-Type': 'application/json',
  },
});
