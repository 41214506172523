import { createTheme } from '@mui/material/styles';

export const companyappTheme = createTheme({
  palette: {
    primary: {
      main: '#1d9bf1',
      light: '#edf7fe',
      dark: '#164a8d',
      contrastText: '#fff',
      confirm: '#20bf55',
    },
    secondary: {
      main: '#20bf55',
      light: '#edfaf1',
      dark: '#188c3e',
      contrastText: '#fff',
    },
    error:{
      main: '#bf1e2c',
      light: '#fcedef',
      dark: '#8d1620',
      contrastText: '#fff',
    },
    solid: {
      light: '#fff',
      main: '#777',
      dark: '#121212',
      contrastText: 'rgba(0, 0, 0, 0.87)',
      confirm: '#20bf55',
    },
    white: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: 'rgba(0, 0, 0, 0.87)',
      confirm: '#fff',
    },
    modal:{
      main: '#d4ecff',
      light: '#415262',
      dark: '#16324f',
      contrastText: '#fff',
    },
    rhg:{
      main: '#C00D0D',
      light: '#EF1A1A',
      dark: '#860909',
      contrastText: '#fff',
    }
  },
});