import React from "react";

// Modules
import { FormControl, InputLabel, MenuItem } from "@mui/material";

// App
import { decodeHtmlEntities } from "../utils/helpers";

// UI components
import { Select } from '@mui/material';

class CategorySelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentValue: props.selectedCategory
    }
  }

  handleCategorySelection = (event) => {
    this.props.handleCategorySelection(event);
  };

  render() {
    // Decode categories
    let decodedCategories = [];

    if (
      this.props.categories !== "undefined" &&
      this.props.categories !== undefined
    ) {

      this.props.categories.map((item) => {
        let valueParam = item.value;
        let labelParam = item.label;

        if (this.props.name === 'presentationCategories') {
          valueParam = item.tid;
          labelParam = item.title;
        }

        if (this.props.name === 'presentationThemes') {
          valueParam = item.tid;
          labelParam = item.label;

          if (!item.label || item.label === '') {
            labelParam = item.title;
          }
        }

        return decodedCategories.push({
          value: valueParam,
          label: decodeHtmlEntities(labelParam),
        });
      });
    }

    return (
      <>
        <FormControl
          variant={this.props.variant ? this.props.variant : "standard"}
          size={this.props.size}
          sx={{
            width: 1,
            "& label[data-shrink=true]":{
              visibility: "hidden"
            },
            "& legend":{
              width: 0
            }
          }}>
          <InputLabel id={`${this.props.label}-id`}>{this.props.label}</InputLabel>
          <Select
            labelId={`${this.props.label}-id`}
            defaultValue={decodedCategories[0]?.value}
            value={this.props.selectedCategory?.value}
            label={this.props.label}
            disabled={this.props.disabled}
            onChange={(e => {
              this.handleCategorySelection(e.target.value);
            })}
          >
            {decodedCategories.map((item, index) => (
              <MenuItem
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  "&.Mui-selected": {
                    backgroundColor: "primary.light"
                  }
                }}
                key={`d-${index}`}
                id={item.value}
                value={item.value}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl >
      </>
    );
  }
}

export default CategorySelector;
