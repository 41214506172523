import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Navigate,
  Route,
  Routes
} from 'react-router-dom';

// Components
import Home from '../screens/home/home';
import PublicHome from '../screens/home/publicHome';
import Account from '../screens/account/account';
import Profile from '../screens/account/profile';
import Notices from '../screens/account/notices';
import SSOAuth from '../screens/auth/ssoauth';
import Login from '../screens/auth/login';
import AuthCallback from '../screens/auth/authCallback';
import AddPresentation from '../screens/presentations/addPresentation';
import EditPresentation from '../screens/presentations/editPresentation';
import PresentationsCreator from '../screens/presentations/presentationsCreator';
import Presentations from '../screens/presentations/presentationsIndex';
import ViewPresentation from '../screens/presentations/viewPresentation';

// Radisson 4 U
import Reports from '../screens/reports/reports';

// App
import withRouter from '../hooks/withRouter';

class RoutesComponent extends Component {
  componentDidMount() {
    this.onRouteChanged();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const location = this.props.location.pathname.split('/');
    this.props.toggleHeader(true);

    if (location[1].length > 0) {
      if (location[1] === 'presentations') {
        if (location[2]) {
          const loc = `${location[2]}-${location[1]}`;
          this.props.setLocation(loc);

          if (loc === 'view-presentations' || loc === 'preview-presentations' || loc === 'thumbnail-presentations') {
            this.props.toggleHeader(false);
          }

        } else {
          this.props.setLocation(location[1]);
        }
      } else {
        this.props.setLocation(location[1]);
      }
    } else {
      this.props.setLocation('home');
    }
  }

  render() {
    if (this.props.user.current_user) {
      return (
        /**
         * A <Switch> looks through its children <Route>s and
         * renders the first one that matches the current URL.
         */
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/account' element={<Account />} />
          <Route path='/account/edit-profile' element={<Profile />} />
          <Route path='/account/notices' element={<Notices />} />
          <Route path='/presentations' element={<Presentations />} />
          <Route path='/presentations-creator' element={<PresentationsCreator />} />
          <Route path='/presentations/add' element={<AddPresentation />} />
          <Route path='/presentations/edit/:id' element={<EditPresentation />} />
          <Route path='/presentations/view/:id' element={<ViewPresentation preview={false} />} />
          <Route path='/presentations/preview/:id' element={<ViewPresentation preview={true} />} />
          {/* <Route path='/radisson4u' element={<Reports />} /> */}
          {/* 404 */}
          <Route
            path='*'
            element={<Navigate to='/' replace />}
          />
        </Routes>
      )
    } else {
      return (
        <Routes>
          <Route path='/' element={<PublicHome />} />
          <Route path='/presentations/view/:id' element={<ViewPresentation preview={false} />} />
          <Route path='/basicAuth' element={<Login />} />
          <Route path='/login' element={<SSOAuth />} />
          <Route path='/login/callback' element={<AuthCallback />} />
          
          {/* 404 */}
          <Route
            path='*'
            element={<Navigate to='/' replace />}
          />
        </Routes>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  permissions: state.permissionsReducer.permissions,
  showHeader: state.headerReducer.showHeader,
});

const mapDispatchToProps = (dispatch) => ({
  setLocation: (data) => dispatch({ type: 'SET_LOCATION', payload: data }),
  toggleHeader: (data) => dispatch({ type: 'TOGGLE_HEADER', payload: data }),
});



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoutesComponent));
