import { IconDotCircle } from '@codexteam/icons'
import { ImageIcon, CloseIcon } from '../../icons/icons';


/**
 * Build styles
 */
require('./index.css').toString();

export default class Ancillary {

  static get toolbox() {
    return {
      icon: IconDotCircle,
      title: 'Ancillary',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow to use native Enter behaviour
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  static get DEFAULT_HEADING_PLACEHOLDER() {
    return 'Ancillary heading'
  }

  static get DEFAULT_BODY_PLACEHOLDER() {
    return 'Ancillary body'
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'cdx-ancillary-container',
      contentContainer: 'cdx-ancillary-content-container',
      wrapper: 'cdx-ancillary',
      btn: 'cdx-media-btn',
      replaceButton: 'replace-btn',
      removeButton: 'remove-btn',
      input: 'cdx-ancillary-input',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      clear: 'clear',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      heading: data.heading || '',
      body: data.body || '',
    };

    this._headingPlaceholder = config.headingPlaceholder ? config.headingPlaceholder : Ancillary.DEFAULT_HEADING_PLACEHOLDER;
    this._bodyPlaceholder = config.bodyPlaceholder ? config.bodyPlaceholder : Ancillary.DEFAULT_BODY_PLACEHOLDER;
    this._headingElement = this.getHeadingElement();
    this._bodyElement = this.getBodyElement();
  }


  getHeadingElement() {
    const tag = document.createElement('span');
    tag.dataset.placeholder = this.api.i18n.t(this._headingPlaceholder);
    tag.innerHTML = this._data.heading || '';
    tag.classList.add(this._CSS.input);
    tag.classList.add('ancillary-heading');
    tag.contentEditable = this.readOnly ? 'false' : 'true';

    return tag;
  }

  getBodyElement() {
    const tag = document.createElement('span');
    tag.dataset.placeholder = this.api.i18n.t(this._bodyPlaceholder);
    tag.innerHTML = this._data.body || '';
    tag.classList.add(this._CSS.input);
    tag.classList.add('ancillary-body');
    tag.contentEditable = this.readOnly ? 'false' : 'true';

    return tag;
  }

  render() {
    let container = document.createElement('div');
    container.classList.add(this._CSS.container);
    let contentContainer = document.createElement('div');
    contentContainer.classList.add(this._CSS.contentContainer);

    container.appendChild(contentContainer);


    contentContainer.appendChild(this._headingElement);
    contentContainer.appendChild(this._bodyElement);

    return container;
  }

  save() {
    this._data.heading = this._headingElement.innerHTML;
    this._data.body = this._bodyElement.innerHTML;

    return this._data;
  }
}