import { IconDotCircle } from '@codexteam/icons'
import { ImageIcon, CloseIcon, PointToggleIcon } from '../../icons/icons';


/**
 * Build styles
 */
require('./index.css').toString();

export default class Point {

  static get toolbox() {
    return {
      icon: IconDotCircle,
      title: 'Point',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow to use native Enter behaviour
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  static get DEFAULT_HEADING_PLACEHOLDER() {
    return 'Point heading'
  }

  static get DEFAULT_BODY_PLACEHOLDER() {
    return 'Point body'
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'cdx-point-container',
      imgContainer: 'cdx-point-img-container',
      contentContainer: 'cdx-point-content-container',
      wrapper: 'cdx-point',
      btn: 'cdx-media-btn',
      replaceButton: 'replace-btn',
      removeButton: 'remove-btn',
      input: 'cdx-point-input',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      mdIcon: 'md-icon',
      clear: 'clear',
      alt: 'alt',
      alternate: 'index-alt',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;


    this._data = {
      url: data.url || '',
      mid: data.mid || '',
      width: data.width || '',
      height: data.height || '',
      x: data.x || '',
      y: data.y || '',
      maxWidth: data.maxWidth || '',
      maxHeight: data.maxHeight || '',
      heading: data.heading || '',
      body: data.body || '',
      altIndex: data.altIndex || false,
      altIndexText: data.altIndexText || '',
    };
    this._container = ''
    this._headingPlaceholder = config.headingPlaceholder ? config.headingPlaceholder : Point.DEFAULT_HEADING_PLACEHOLDER;
    this._bodyPlaceholder = config.bodyPlaceholder ? config.bodyPlaceholder : Point.DEFAULT_BODY_PLACEHOLDER;
    this._imageElement = this.getImageElement();
    this._headingElement = this.getHeadingElement();
    this._bodyElement = this.getBodyElement();
    this._alternateIndexElement = this.getAltIndex();
  }

  getImageElement() {
    /**
     * Create element for current Block's level
     */
    let img;

    if (this._data.url) {
      img = document.createElement('img');
      img.classList.add(this._CSS.wrapper);
      img.setAttribute('src', this._data.url);
      img.setAttribute('data-mid', this._data.mid);
      img.setAttribute('data-x', this._data.x);
      img.setAttribute('data-y', this._data.y);
      img.setAttribute('data-width', this._data.width);
      img.setAttribute('data-height', this._data.height);
      img.setAttribute('data-maxwidth', this._data.maxWidth);
      img.setAttribute('data-maxheight', this._data.maxHeight);
    }

    return img;
  }

  getHeadingElement() {
    const tag = document.createElement('h4');
    tag.dataset.placeholder = this.api.i18n.t(this._headingPlaceholder);
    tag.innerHTML = this._data.heading || '';
    tag.classList.add(this._CSS.input);
    tag.classList.add('point-heading');
    tag.contentEditable = this.readOnly ? 'false' : 'true';

    return tag;
  }

  getBodyElement() {
    const tag = document.createElement('p');
    tag.dataset.placeholder = this.api.i18n.t(this._bodyPlaceholder);
    tag.innerHTML = this._data.body || '';
    tag.classList.add(this._CSS.input);
    tag.classList.add('point-body');
    tag.contentEditable = this.readOnly ? 'false' : 'true';

    return tag;
  }

  getAltIndex() {
    const tag = document.createElement('h1')
    tag.dataset.placeholder = this.api.i18n.t('#');
    tag.innerHTML = this._data.altIndexText || '';
    tag.classList.add(this._CSS.input);
    tag.classList.add('point-alternate');
    tag.contentEditable = this.readOnly ? 'false' : 'true';

    return tag
  }

  handleAlternate(container, button) {
    if (this._data.altIndex) {
      if (!container.classList.contains(this._CSS.alternate)) {
        container.classList.add(this._CSS.alternate)
        button.innerHTML = `${ImageIcon}`
        button.classList.remove(this._CSS.mdIcon)
        button.classList.add(this._CSS.xlIcon)
      }
    }
    else {
      container.classList.remove(this._CSS.alternate)
      button.innerHTML = `${PointToggleIcon}`
      button.classList.remove(this._CSS.xlIcon)
      button.classList.add(this._CSS.mdIcon)
    }
  }

  render() {
    this._container = document.createElement('div');
    this._container.classList.add(this._CSS.container);
    let img = this._imageElement;
    let imgContainer = document.createElement('div');
    imgContainer.classList.add(this._CSS.imgContainer);
    let contentContainer = document.createElement('div');
    contentContainer.classList.add(this._CSS.contentContainer);
    let btnAlt = document.createElement('button');

    this._container.appendChild(imgContainer);
    this._container.appendChild(contentContainer);
    this.handleAlternate(this._container, btnAlt)

    if (!this.readOnly) {
      let btn = document.createElement('button');
      let btnRemove = document.createElement('button');
      btnAlt.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.mdIcon, this._CSS.alt);
      btn.innerHTML = `alternate`;

      if (this._data.url) {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xlIcon, 'point-img-btn');
        btn.innerHTML = `${ImageIcon}`;
        btnRemove.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.alt);
        btnRemove.innerHTML = `${CloseIcon}`;
      } else {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xlIcon, 'point-img-btn');
        btn.innerHTML = `${ImageIcon}`;
      }

      btn.onclick = () => {
        const blockIndex = this.api.blocks.getCurrentBlockIndex();

        const block = this.api.blocks.getBlockByIndex(blockIndex);
        this._settings.showMediaLibrary({
          details: block,
          data: this._data,
          bundle: 'image',
        });
      };

      btnAlt.onclick = () => {
        this._data.altIndex = !this._data.altIndex
        this.handleAlternate(this._container, btnAlt)
      }

      if (typeof img !== 'undefined') {
        imgContainer.appendChild(img);
        if (!this.readOnly) {
          if (!this._settings.imageReadOnly) {
            imgContainer.appendChild(btn);
          }
        }
      } else {
        imgContainer.appendChild(btn);
      }
      imgContainer.appendChild(btnAlt)
    }

    imgContainer.appendChild(this._alternateIndexElement)
    contentContainer.appendChild(this._headingElement);
    contentContainer.appendChild(this._bodyElement);

    return this._container;
  }

  save() {
    if (typeof this._imageElement !== 'undefined') {
      this._data.url = this._imageElement.getAttribute('src') || '';
      this._data.mid = this._imageElement.getAttribute('data-mid') || '';
      this._data.x = this._imageElement.getAttribute('data-x') || '';
      this._data.y = this._imageElement.getAttribute('data-y') || '';
      this._data.width = this._imageElement.getAttribute('data-width') || '';
      this._data.height = this._imageElement.getAttribute('data-height') || '';
      this._data.maxWidth = this._imageElement.getAttribute('data-maxwidth') || '';
      this._data.maxHeight = this._imageElement.getAttribute('data-maxheight') || '';
    } else {
      this._data.url = '';
      this._data.mid = '';
      this._data.width = '';
      this._data.height = '';
      this._data.x = '';
      this._data.y = '';
      this._data.maxWidth = '';
      this._data.maxHeight = '';
    }

    this._data.heading = this._headingElement.innerHTML;
    this._data.body = this._bodyElement.innerHTML;
    this._data.altIndexText = this._alternateIndexElement.innerHTML;

    return this._data;
  }
}