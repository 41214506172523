import React from "react";

// Modules
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// App
import { auth_logout, get_logout_token, get_csrf_token } from "../core/auth";
import { getOnlineNode } from "../core/getNode";
import { patchNode } from "../core/postNode";
import withRouter from '../hooks/withRouter';
import { alertMessages } from "../partials/alertMessages";
import AlertModal from "../partials/alertModal";
import store from "../redux/store";
import MediaLibrary from "./mediaLibrary";
import UserImage from "./userImage";
import Notification from "./notificationModal";

// UI components
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
  ChevronDown, Menu as MenuIcon, Search, User,
  // Menu,
  X
} from "react-feather";
// import logo from "../assets/ca-logo-default-stroke.svg";
import logo from "../assets/rhg-logo.svg";
import caLogo from "../assets/ca-logo-pwa.png"
// import logo from "../assets/radisson-dark.svg";
import { Avatar, Badge, Button, Dialog, DialogTitle, Divider, IconButton, Menu, MenuItem, ListItemIcon, ListItem } from '@mui/material';
import { Container } from "react-bootstrap";
import { ReactComponent as AccountIcon } from "../assets/account-icon.svg"
import { ReactComponent as NotificationIcon } from "../assets/notification-icon.svg"
import { ReactComponent as MediaLibraryIcon } from "../assets/media-library-icon.svg"
import { ReactComponent as LogoutIcon } from "../assets/logout-icon.svg"

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isAccountLoading: true,
      isAccountError: false,
      accountErrorStatus: "",
      accountErrorMessage: "",

      user: "",
      csrf_token: "",
      search: "",

      userMenu: false,
      userMenuAnchor: null,

      modalVisible: false,
      activeItem: 0,

      scrolled: "",
      searchModal: false,

      showMediaLibrary: false,
      mediaType: '',

      noticeLoadingArr: [],
      noticeReminderInterval: 60
    };

    // This binding is necessary to make `this` work in the callback
    this.submitSearchForm = this.submitSearchForm.bind(this);
    this.unsubscribe = store.subscribe(() => {
      this.getAccount();
    });
  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    this.getToken();
    this.getAccount();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount = () => {
    this.unsubscribe();
    window.removeEventListener('scroll', this.handleScroll);
  };

  setAccount = (_data) => {
    this.setState({
      isAccountLoading: false,
      isRefreshing: false,
      user: _data,
    });
  };

  getToken = () => {
    get_csrf_token()
      .then(response => {
        this.setState({
          csrf_token: response.data,
        });
      })
  };

  getAccount = () => {
    if (typeof this.props.user.current_user === "undefined") {
      //
      this.setState({
        isAccountLoading: false,
        isRefreshing: false,
      });
    } else {
      getOnlineNode('custom_user/' + this.props.user.current_user.uid)
        .then((response) => {
          this.setAccount(response.data.rows[0]);
        })
        .catch((_error) => { });
    }
  };

  getTokens = () => {
    if (this.props.user.current_user) {
      get_logout_token().then((get_logout_token_response) => {
        get_csrf_token().then((get_xcsrf_token_response) => {
          this.setState(
            {
              logout_token: get_logout_token_response.data.logout_token,
              csrf_token: get_xcsrf_token_response.data,
            },
            function () {
              this.logout();
            }
          );
        })
          .catch((_error) => {
            if (_error.response) {
              if (_error.response.status === 403) {
                this.setState({
                  user: '',
                });
                this.props.removeUserSession();
              } else {
                this.setState({
                  modalVisible: true,
                  alertType: "error",
                  alertTitle: alertMessages.unkownError.title,
                  alertBody: _error.response.data,
                  alertConfirmButton: true,
                });
              }
            } else if (_error.request) {
              this.setState({
                modalVisible: true,
                alertType: "error",
                alertTitle: alertMessages.serverNoResponse.title,
                alertBody: alertMessages.serverNoResponse.message,
                alertConfirmButton: true,
              });
            } else {
              this.setState({
                modalVisible: true,
                alertType: "error",
                alertTitle: alertMessages.unkownError.title,
                alertBody: alertMessages.unkownError.message,
                alertConfirmButton: true,
              });
            }
          });
      })
        .catch((_error) => {
          if (_error.response) {
            if (_error.response.status === 403) {
              this.setState({
                user: '',
              });
              this.props.removeUserSession();
            } else {
              this.setState({
                modalVisible: true,
                alertType: "error",
                alertTitle: alertMessages.unkownError.title,
                alertBody: _error.response.data,
                alertConfirmButton: true,
              });
            }
          } else if (_error.request) {
            this.setState({
              modalVisible: true,
              alertType: "error",
              alertTitle: alertMessages.serverNoResponse.title,
              alertBody: alertMessages.serverNoResponse.message,
              alertConfirmButton: true,
            });
          } else {
            this.setState({
              modalVisible: true,
              alertType: "error",
              alertTitle: alertMessages.unkownError.title,
              alertBody: alertMessages.unkownError.message,
              alertConfirmButton: true,
            });
          }
        });
    }
  };

  logout = () => {
    const { logout_token, csrf_token } = this.state;

    if (!logout_token && !csrf_token) {
      this.setState({
        modalVisible: true,
        alertType: "error",
        alertTitle: alertMessages.noTokens.title,
        alertBody: alertMessages.noTokens.message,
        alertConfirmButton: true,
      });

      this.getTokens();

      return false;
    }

    auth_logout(logout_token, csrf_token)
      .then((_response) => {
        this.setState({
          user: '',
        });
        this.props.removeUserSession();
      })
      .catch((_error) => {
        if (_error.response) {
          if (_error.response.status === 403) {
            this.setState({
              user: '',
            });
            this.props.removeUserSession();
          } else {
            this.setState({
              modalVisible: true,
              alertType: "error",
              alertTitle: alertMessages.unkownError.title,
              alertBody: _error.response.data,
              alertConfirmButton: true,
            });
          }
        } else if (_error.request) {
          this.setState({
            modalVisible: true,
            alertType: "error",
            alertTitle: alertMessages.serverNoResponse.title,
            alertBody: alertMessages.serverNoResponse.message,
            alertConfirmButton: true,
          });
        } else {
          this.setState({
            modalVisible: true,
            alertType: "error",
            alertTitle: alertMessages.unkownError.title,
            alertBody: alertMessages.unkownError.message,
            alertConfirmButton: true,
          });
        }
      });
  };

  toggleActiveItem = (eventKey) => {
    this.setState({
      activeItem: eventKey,
    });
  };

  handleuserMenuClose = () => {
    this.setState({
      userMenu: false,
      userMenuAnchor: null
    })
  };

  renderUser = () => {
    if (this.props.user.current_user) {
      const firstName = this.state.user.field_first_name;
      const lastName = this.state.user.field_last_name;
      const userPicture = this.state.user.user_picture;
      let username;

      if (firstName && lastName) {
        username = firstName + " " + lastName;
      } else {
        username = this.state.user.name;
      }



      if (this.props.user.current_user) {
        return (
          <>
            <Button
              id='user-dropdown-button'
              sx={{
                textTransform: 'none',
                color: 'text.secondary'
              }}
              onClick={(e) => {
                this.setState(prevState => ({
                  ...prevState,
                  userMenu: !prevState.userMenu,
                  userMenuAnchor: document.querySelector("#user-dropdown-button")
                  // e.target
                }))

                const location = this.props.location.pathname;

                switch (location) {
                  case '/account':
                    this.toggleActiveItem(1);
                    break;
                  case '/account/messages':
                    this.toggleActiveItem(2);
                    break;
                  case '/account/notices':
                    this.toggleActiveItem(3);
                    break;
                  default:
                    this.toggleActiveItem(0);
                }

                if (location.indexOf('/account/messages') > -1) {
                  this.toggleActiveItem(2);
                }

                if (location.indexOf('edit-notice') > -1) {
                  this.toggleActiveItem(3);
                }
              }
              }

              startIcon={
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  <UserImage
                    name={`${firstName} ${lastName}`}
                    image={userPicture}
                  />
                  {/* <Avatar
                    sx={{
                      border: '1px solid gray',
                      mx: 1.5
                    }}
                    alt=""
                    src={userPicture}
                  /> */}
                </Badge>
              }
            >

              <span className="user__name  d-none d-md-block">{username}</span>
              <ChevronDown className="user__toggle  d-none d-sm-block" />
            </Button>
            <Menu
              anchorEl={this.state.userMenuAnchor}
              open={this.state.userMenu}
              onClose={this.handleuserMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                className={this.state.activeItem === 1 ? "active" : ""}
                selected={this.state.activeItem === 1}
                onClick={() => {
                  this.props.navigate('/account');
                  this.handleuserMenuClose()
                }}
              >
                <ListItemIcon>
                  <AccountIcon style={{ width: '24px', height: 'auto' }} />
                </ListItemIcon>
                Account
              </MenuItem>
              <MenuItem
                className={this.state.activeItem === 4 ? "active" : ""}
                selected={this.state.activeItem === 4}
                onClick={() => {
                  this.props.navigate('/account/notices');
                  this.handleuserMenuClose()
                }}>
                <ListItemIcon>
                  <NotificationIcon style={{ width: '24px', height: 'auto' }} />
                </ListItemIcon>
                Notices
              </MenuItem>
              {!this.checkRole() &&
                <MenuItem
                  className={this.state.activeItem === 3 ? "active" : ""}
                  onClick={() => {
                    this.setState({ showMediaLibrary: true })
                    this.handleuserMenuClose()
                  }}
                >
                  <ListItemIcon>
                    <MediaLibraryIcon style={{ width: '24px', height: 'auto' }} />
                  </ListItemIcon>
                  Media Library
                </MenuItem>
              }
              <Divider />
              <MenuItem
                onClick={() => {
                  this.getTokens();
                  this.handleuserMenuClose()
                }}
              >
                <ListItemIcon>
                  <LogoutIcon style={{ width: '24px', height: 'auto' }} />
                </ListItemIcon>
                Logout</MenuItem>
            </Menu>

            {this.state.showMediaLibrary && (
              <MediaLibrary
                user={this.props.user}
                csrf_token={this.state.csrf_token}
                mediaType={this.state.mediaType}
                showMediaLibrary={this.state.showMediaLibrary}
                isNotForInsert={true}
                setMediaType={(type) => {
                  this.setState({ mediaType: type });
                }
                }
                setShowMediaLibrary={(condition) => {
                  this.setState({ showMediaLibrary: condition });
                }
                }
              />
            )}
          </>
        );
      } else {
        return (
          <Link to={"/login"} className="login-button">
            <User size={20} />
            <span>Login</span>
          </Link>
        );
      }
    } else {
      return (
        <Link to={"/login"} className="login-button">
          <User size={20} />
          <span>Login</span>
        </Link>
      );
    }
  };

  submitSearchForm = async (event) => {
    if (this.state.search.length < 1) {
      event.preventDefault();
    } else {
      event.preventDefault();
      this.props.navigate('/search?q=' + this.state.search);
    }
  };

  clearSearchForm = async () => {
    this.setState({ search: '' });
    //await this.props.saveSearchTerm('')

    // Only redirect to search page when the form is cleared if on search page
    if (this.props.location === '/search') {
      this.props.navigate('/search');
    } else {
      return false;
    }
  };

  handleScroll = () => {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      this.setState({
        scrolled: "scrolled"
      })
    } else {
      this.setState({
        scrolled: ""
      })
    }
  };

  /**
   * Set modal visibility
   * @param {*} visible
   */
  setModalVisible = (visible) => {
    this.setState({
      modalVisible: visible,
    });
  };

  setSearchModal = () => {
    this.setState(prevState => ({
      searchModal: !prevState.searchModal
    })
    )
  }

  checkRole = () => {
    if (this.state?.user?.roles_target_id) {
      let rolesArr = this.state?.user?.roles_target_id?.split(',');
      if (rolesArr.length > 1) {
        return false
      }
      else {
        if (rolesArr.find((el) => el === 'RHG viewer')) {
          return true
        }
        else {
          return false
        }
      }
    }
  }

  addLoading = (id) => {
    let loadingArray = this.state.noticeLoadingArr
    if (!loadingArray.includes(id)) {
      loadingArray.push(id)
      this.setState({ noticeLoadingArr: loadingArray })
    }
  }

  removeLoading = (id) => {
    let loadingArray = this.state.noticeLoadingArr
    let index = loadingArray.indexOf(id)
    if (index > -1) {
      loadingArray.splice(index, 1)
      this.setState({ noticeLoadingArr: loadingArray })
    }
  }

  // Toggle single notification status
  toggleNotification = (id, status) => {
    let path = `api/notifications/read?id=${id}`
    if (status == false) {
      path = path + '&unread=true'
    }
    this.addLoading(id)
    patchNode(`${path}&_format=hal_json`, {}, this.state.csrf_token, true)
      .then((response) => {
        if (response.status == 201 || response.status == 200) {
          this.props.toggleRead({ id: id, status: status })
          this.removeLoading(id)
        }
      })
      .catch((error) => {
        console.log(error)
        this.removeLoading(id)
      })
  }

  // update next reminder time
  forceNextReminder = () => {
    patchNode(`api/notifications/forced?_format=hal_json`, {}, this.state.csrf_token, true)
      .then((response) => {
        let tempSettings = this.props.notifications.settings
        tempSettings["lastReminder"] = tempSettings.nextReminder
        tempSettings["nextReminder"] += this.state.noticeReminderInterval
        this.props.forceTime({ settings: tempSettings })
        this.removeNotices()
      })
      .catch((error) => {
        console.log('forced response', error)
      })
  }

  // Mark all notifications as read
  markAllNotifications = () => {
    let path = `api/notifications/read?all=true`
    patchNode(`${path}&_format=hal_json`, {}, this.state.csrf_token, true)
      .then((response) => {
        if (response.status == 201 || response.status == 200) {
          this.props.markAllRead()
          this.forceNextReminder()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // Remove read notifications from popup
  removeNotices = () => {
    let tempRows = this.props.notifications.rows.filter((e,i) => {
      if(!e.isRead){
        return e
      }
    })
    this.props.modifyRows({ rows: tempRows })
  }

  render() {
    let pageLocation = this.props.location.pathname
    return (
      <>
        <header className={`header show-${this.props.showHeader} ${this.state.scrolled}`} >
          <Container fluid>
            <Row className="align-items-center  justify-content-between">
              <div className="hmg-container me-0">
                <IconButton
                  onClick={() => {
                    this.props.sidebarVisibility();
                  }}
                  className="navbar-btn"
                >
                  <MenuIcon />
                </IconButton>
              </div>
              {/* <Col xs={"auto"} className="d-none d-sm-block justify-content-start search-container ps-0">
                <form className="search ms-0" onSubmit={this.submitSearchForm}>
                  <div className="flex-grow-1">
                    <input
                      type="text"
                      placeholder="Search"
                      value={this.state.search}
                      onChange={(event) => {
                        this.setState({
                          search: event.target.value,
                        });
                      }}
                    />
                  </div>
                  {this.state.search.length > 0 && (
                    <IconButton
                      type="button"
                      onClick={() => this.clearSearchForm()}
                    >
                      <X />
                    </IconButton>
                  )}
                  <IconButton type="submit">
                    <Search />
                  </IconButton>
                </form>
              </Col> */}
              <Col xs={"auto"} className="d-sm-none small-search ps-1 pe-0">
                <IconButton onClick={this.setSearchModal}>
                  <Search />
                </IconButton>
                <Dialog
                  open={this.state.searchModal}
                  onClose={this.setSearchModal}
                  className="search-modal"
                // dialogClassName="search-modal"
                >
                  <DialogTitle>
                    <Col xs={"auto"} className="justify-content-start search-container ps-0">
                      <form className="search ms-0" onSubmit={this.submitSearchForm}>
                        <div className="flex-grow-1">
                          <input
                            type="text"
                            placeholder="Search"
                            value={this.state.search}
                            onChange={(event) => {
                              this.setState({
                                search: event.target.value,
                              });
                            }}
                          />
                        </div>
                        {this.state.search.length > 0 && (
                          <IconButton
                            type="button"
                            onClick={() => this.clearSearchForm()}
                          >
                            <X />
                          </IconButton>
                        )}
                        <IconButton type="submit">
                          <Search />
                        </IconButton>
                      </form>
                    </Col>
                  </DialogTitle>
                </Dialog>
              </Col>
              <div className={`branding-container ${this.state.scrolled} ${this.props.navbarState} ${pageLocation.search("presentations-creator") > -1 || pageLocation.search("edit") > -1 || pageLocation.search("preview") > -1 ? 'presentation-variant' : ''}`}>
                <Link to="/" className="branding">
                  <img src={logo} alt={"companyapp logo"} />
                  {/* <img src={caLogo} alt={"companyapp logo"} /> */}
                </Link>
              </div>
              <Col xs={"auto"} className="flex-sm-grow-1">
                <Row className="justify-content-end  align-items-center">
                  <Col xs={"auto"}>{this.renderUser()}</Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </header>

        <AlertModal
          showAlert={this.state.modalVisible}
          showAlertCallback={this.setModalVisible}
          alertType={this.state.alertType}
          alertMessageTitle={this.state.alertTitle}
          alertMessageBody={this.state.alertBody}
          confirmButton={this.state.alertConfirmButton}
        />

        <Notification
          showPath={'/presentations-creator'}
          currentPath={this.props.location.pathname}
          notices={this.props.notifications.rows}
          settings={this.props.notifications.settings}
          statusChange={(id, status) => {
            this.toggleNotification(id, status)
          }}
          forceNextReminder={() => {
            this.forceNextReminder()
          }}
          loadingArray={this.state.noticeLoadingArr}
          markAllNotifications={() => {
            this.markAllNotifications()
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  search: state.searchReducer.search,
  showHeader: state.headerReducer.showHeader,
  notifications: state.noticeReducer
});

const mapDispatchToProps = (dispatch) => ({
  removeUserSession: () => dispatch({ type: 'REMOVE_SESSION' }),
  saveSearchTerm: (data) => dispatch({ type: 'SAVE_TERM', payload: data }),
  removeSearchTerm: () => dispatch({ type: 'REMOVE_TERM' }),
  toggleHeader: (data) => dispatch({ type: 'TOGGLE_HEADER', payload: data }),
  toggleRead: (data) => dispatch({ type: 'TOGGLE_READ', payload: data }),
  markAllRead: (data) => dispatch({ type: 'MARK_ALL_NOTICES', payload: data }),
  forceTime: (data) => dispatch({ type: 'FORCE_TIME', payload: data }),
  modifyRows: (data) => dispatch({ type: 'MODIFY_ROWS', payload: data })
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
