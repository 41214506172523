import { defaultAnimations } from './defaultAnimations';

export default class AnimationTune {
  static get isTune() {
    return true;
  }

  /**
  *
  * @param api
  * @param data
  * @param settings
  * @param block
  */
  constructor({ api, data, config, block }) {
    this.api = api;
    this.block = block;
    this.settings = config;
    this.animations = defaultAnimations();

    if (this.settings.animations && this.settings.animations.length > 0) {
      this.animations = this.settings.animations;
    }

    this.data = {
      animation: this.animations[0]
    };

    if (data && data.animation && data.animation.class) {
      this.data = {
        animation: data.animation
      };
    }

    this._CSS = {
      wrapper: 'cdx-animations-settings',
      select: 'cdx-settings-select',
      // fragment: 'fragment',
    };
  }

  /**
    * rendering block tune
    * @returns {*}
    */
  render() {
    const { animation } = this.data;

    const wrapper = document.createElement('div');
    wrapper.classList.add(this._CSS.wrapper);

    const selectContainer = document.createElement('div');
    selectContainer.classList.add('select-container');

    const select = document.createElement('select');
    select.classList.add(this._CSS.select);
    select.setAttribute('name', 'animation');

    this.animations.forEach((animationItem, index) => {
      const option = document.createElement('option');
      option.setAttribute('value', animationItem.class);
      option.innerHTML = animationItem.label;

      if (animationItem.class === animation.class) {
        option.setAttribute('selected', true);
      }

      select.appendChild(option);
    });

    select.addEventListener('change', () => {
      const value = select.value;
      let animationItem;

      this.animations.forEach(item => {
        if (item.class === value) {
          animationItem = item;
        }
      });

      this.data = {
        animation: animationItem
      };

      this.block.dispatchChange();
    });

    selectContainer.appendChild(select);
    wrapper.appendChild(selectContainer);
    return wrapper;
  }
  /**
  * save
  * @returns {*}
  */
  save() {
    return this.data;
  }
};