
import React, { useEffect, useState } from 'react';


// Modules
import { ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate, useLocation } from 'react-router-dom';

// App
import { subdomain, filesystem_root } from './config';
import { getOnlineNode } from '../src/core/getNode';
import Header from './partials/header';
import LoadingSpinner from './partials/loadingSpinner';
import Routes from './partials/routes';
import ScrollToTop from './partials/scrollToTop';
import Sidebar from './partials/sidebar';

// UI components
import Container from 'react-bootstrap/Container';

// Style
import { companyappTheme } from './core/theme';
import './styles/main.scss';

export default function App() {
  const [isLoading, setLoading] = useState(true);
  const [isThemesLoading, setThemesLoading] = useState(true);
  const [isMobile, setMobile] = useState(window.innerWidth < 993 ? true : false);
  const [isFluid, setFluid] = useState(false);
  const [navbarState, setNavbarState] = useState('nav-collapsed');

  // hooks
  // const location = useLocation();
  const showNavigation = useSelector((state) => state).navigationReducer.showNavigation;
  const user = useSelector((state) => state).authReducer.user;
  const currentLocation = useSelector((state => state.locationReducer.currentLocation));

  // hooks
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const handleResize = () => {
    const { innerWidth: width } = window;

    if (width < 993) {
      if (showNavigation) {
        dispatch({ type: 'TOGGLE_NAVIGATION', payload: false });
      }

      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const checkSession = (_user) => {
    getOnlineNode('user/' + _user.current_user.uid)
      .then(() => {
        dispatch({ type: 'SAVE_SESSION', payload: _user });
        checkPermissions(_user);
      })
      .catch((_error) => {
        dispatch({ type: 'REMOVE_SESSION', payload: {} });
        setLoading(false);
      });
  };

  const checkPermissions = (_user) => {
    if (_user.current_user) {
      getOnlineNode(`api/user_permissions`)
        .then(response => {
          if (response.data) {
            dispatch({ type: 'SAVE_PERMISSIONS', payload: response.data });
            setLoading(false);
          }
        })
        .catch(error => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }

  const sidebarVisibility = () => {
    dispatch({ type: 'TOGGLE_NAVIGATION', payload: !showNavigation });
  };

  // resize effect
  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check user
  useEffect(() => {
    let session = localStorage.getItem('user');
    session = JSON.parse(session);
    session = {
      user: session,
    };

    if (session && session.user && session.user.current_user) {
      checkSession(session.user);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Lister to user redux
  useEffect(() => {
    if (user.current_user) {
      getOnlineNode(`api/user_permissions`)
        .then(response => {
          if (response.data) {
            dispatch({ type: 'SAVE_PERMISSIONS', payload: response.data });
            setLoading(false);
          }
        })
        .catch(error => {
          setLoading(false);
        });
    }
  }, [dispatch, user.current_user]);

  // Check for notifications
  useEffect(() => {
    if (user.current_user) {
      getOnlineNode(`api/notifications?debug=true&_format=json`)
        .then((response) => {
          dispatch({type: 'SAVE_NOTIFICATIONS', payload: response.data})
        })
        .catch((error) => {
          console.log('notifications error', error)
        })
    }
  }, [user.current_user])


  useEffect(() => {
    let _navState = 'nav-collapsed';
    if (showNavigation || showNavigation === 'true') {
      _navState = 'nav-expanded';
    }

    setNavbarState(_navState);
  }, [showNavigation]);

  useEffect(() => {
    let _fluid = false;

    if (
      currentLocation === 'presentations-creator' ||
      currentLocation === 'edit-presentations' ||
      currentLocation === 'view-presentations' ||
      currentLocation === 'preview-presentations'
    ) {
      _fluid = true;
    }

    setFluid(_fluid);
  }, [currentLocation]);

  // get themes
  useEffect(() => {
    getOnlineNode('api/themes')
      .then(response => {
        dispatch({ type: 'SAVE_THEMES', payload: response.data });
        setThemesLoading(false);
      })
      .catch(error => {
        setThemesLoading(false);
        console.log('err: ', error);
      });
  }, []);

  if (isLoading || isThemesLoading) {
    return <LoadingSpinner className='spinner-container--app' />
  }

  return (
    <ThemeProvider theme={companyappTheme}>
      <ScrollToTop />
      <Header showNavigation={showNavigation} sidebarVisibility={sidebarVisibility} navbarState={navbarState} />
      <Sidebar showNavigation={showNavigation} isMobile={isMobile} sidebarVisibility={sidebarVisibility} />
      <div className={`page-container ${navbarState}`} data-page={currentLocation}>
        <Container fluid={isFluid} className={isFluid ? '' : 'content-container'} data-page={currentLocation}>
          <Routes />
        </Container>
      </div>
    </ThemeProvider>
  );
}