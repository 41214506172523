import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Notification from '../../partials/notificationModal';
import { patchNode } from '../../core/postNode';

import { ReactComponent as NotificationItem } from '../../assets/notification-icon.svg';
import { Row, Col } from 'react-bootstrap';
import { OpenInNewOutlined, MarkEmailReadOutlined, Markunread } from '@mui/icons-material';
import { ReactComponent as OpenInNewIcon } from '../../assets/notices/open-link.svg';
import { ReactComponent as OpenedEmailIcon } from '../../assets/notices/open-email.svg';
import { ReactComponent as UnopenedEmailIcon } from '../../assets/notices/unopened-email.svg';

export default function NoticeItem(props) {
  const [readStatus, setReadStatus] = useState(props.item.isRead ? props.item.isRead : false)
  const [itemDialog, setItemDialog] = useState(false)
  const [loadingId, setLoadingId] = useState('')

  const dispatch = useDispatch()

  // const toggleRead = () => {
  //   let status = readStatus
  //   setReadStatus(!status)
  //   if (props.toggleRead) {
  //     props.toggleRead(!status)
  //   }
  // }

  let publishDate = new Date(props.item.published_on * 1000)
  let publishDateFormatted = `${publishDate.toLocaleString("en-GB", { day: "numeric" })} ${publishDate.toLocaleString("en-GB", { month: "short" })} ${publishDate.toLocaleString("en-GB", { year: "2-digit" })}`

  // Toggle single notification status
  const toggleRead = (id, status) => {
    let path = `api/notifications/read?id=${id}`
    if (status == false) {
      path = path + '&unread=true'
    }
    setLoadingId(props?.item?.id)
    patchNode(`${path}&_format=hal_json`, {}, props.user.csrf_token, true)
      .then((response) => {
        if (response.status == 201 || response.status == 200) {
          setReadStatus(status)
          modifyRows(id, status)
          setLoadingId('')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const modifyRows = (id, status) => {
    let dupArr = props.rows
    let index = dupArr.findIndex((e) => {
      return e.id == id
    })
    if (status) {
      if (index > -1) {
        dupArr.splice(index, 1)
        dupArr.sort((a, b) => a.published_on - b.published_on)
        dispatch({ type: 'MODIFY_ROWS', payload: { rows: dupArr } })
      }
    }
    if (!status) {
      if (index == -1) {
        let tempItem = { ...props.item, isRead: status }
        dupArr.push(tempItem)
        dupArr.sort((a, b) => b.published_on - a.published_on)
        dispatch({ type: 'MODIFY_ROWS', payload: { rows: dupArr } })
      }
    }
  }

  return (
    <>
      <Row
        className={`notification-item ${readStatus ? '' : 'unread'}`}
        onClick={() => {
          setItemDialog(true)
        }}
      >
        <Col xs={1} className='notification-item-column'>
          <span>{props.item?.id}</span>
        </Col>
        <Col xs={1} className='notification-item-column d-xl-flex d-none px-0'>
          <span className='notification-item-label'>{publishDateFormatted}</span>
        </Col>
        <Col xs={5} md={'auto'} className='flex-grow-1 notification-item-column d-flex align-items-center ps-4'>
          <span className='notification-item-label'>{props.item?.label}</span>
        </Col>
        <Col xs={1} className='notification-item-column text-end d-xl-block d-none'>
          <OpenInNewIcon />
        </Col>
        <Col xs={1} className='notification-item-column text-end'>
          {readStatus ?
            <OpenedEmailIcon /> :
            <UnopenedEmailIcon />
          }
        </Col>
      </Row>
      {itemDialog &&
        <Notification
          singleItem={true}
          notices={[props?.item]}
          readStatus={readStatus}
          dialogOpen={itemDialog}
          dialogClose={() => {
            setItemDialog(false)
          }}
          statusChange={(id, status) => {
            toggleRead(id, status)
          }}
          loadingArray={[loadingId]}
        />
      }
    </>
  )
}