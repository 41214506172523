import { InlineImageIcon, CloseIcon, SizeIcon } from '../../icons/icons';

export default class InlineImage {

  static get toolbox() {
    return {
      icon: InlineImageIcon,
      title: 'Inline image',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'cdx-inline-media-container',
      wrapper: 'cdx-inline-media-wrapper',
      btn: 'cdx-media-btn',
      replaceButton: 'replace-btn',
      removeButton: 'remove-btn',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      clear: 'clear',
      dimModalMain: 'cdx-dim-modal-main',
      dimModalInner: 'cdx-dim-modal-inner',
      dimensionsLabel: 'cdx-dim-label',
      dimensionsInput: 'cdx-dim-input',
      dimensionBtn: 'cdx-dim-btn',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      url: data.url || '',
      mid: data.mid || '',
      width: data.width || '',
      height: data.height || '',
      x: data.x || '',
      y: data.y || '',
      maxWidth: data.maxWidth || '',
      maxHeight: data.maxHeight || '',
      dimensions: data.dimensions || { width: 'auto', height: 'auto' }
    };

    this._element = this.getElement();
  }

  getElement() {
    let img;

    if (this._data.url) {
      img = document.createElement('img');
      img.classList.add(this._CSS.wrapper);
      img.setAttribute('src', this._data.url);
      img.setAttribute('data-mid', this._data.mid);
      img.setAttribute('data-x', this._data.x);
      img.setAttribute('data-y', this._data.y);
      img.setAttribute('data-width', this._data.width);
      img.setAttribute('data-height', this._data.height);
      img.setAttribute('data-maxwidth', this._data.maxWidth);
      img.setAttribute('data-maxheight', this._data.maxHeight);
      img.style.width = `${this._data.dimensions.width}px`;
      img.style.height = `${this._data.dimensions.height}px`;
    }

    return img;
  }

  renderDimensionsModal() {
    let mainContainer = document.createElement('div');
    let innerContainer = document.createElement('div');
    let widthLabel = document.createElement('label');
    let widthInput = document.createElement('input');
    let heightLabel = document.createElement('label');
    let heightInput = document.createElement('input');
    let btnClose = document.createElement('button');

    mainContainer.classList.add(this._CSS.dimModalMain);
    innerContainer.classList.add(this._CSS.dimModalInner);
    widthLabel.classList.add(this._CSS.dimensionsLabel);
    widthInput.classList.add(this._CSS.dimensionsInput);
    heightLabel.classList.add(this._CSS.dimensionsLabel);
    heightInput.classList.add(this._CSS.dimensionsInput);
    btnClose.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.clear, "map-close");

    widthInput.setAttribute('type', 'number');
    heightInput.setAttribute('type', 'number');
    widthInput.setAttribute('min', 0);
    heightInput.setAttribute('min', 0);
    widthInput.setAttribute('max', 500);
    heightInput.setAttribute('max', 500);

    widthLabel.innerHTML = 'Width';
    heightLabel.innerHTML = 'Height';
    widthInput.value = this._data.dimensions.width;
    heightInput.value = this._data.dimensions.height;
    btnClose.innerHTML = `${CloseIcon}`;

    widthInput.addEventListener("input", (e) => this.inputHandler.call(this, "width", widthInput, e.target.value), false);
    widthInput.addEventListener("change", (e) => this.inputHandler.call(this, "width", widthInput, e.target.value), false);
    heightInput.addEventListener("input", (e) => this.inputHandler.call(this, "height", heightInput, e.target.value), false);
    heightInput.addEventListener("change", (e) => this.inputHandler.call(this, "height", heightInput, e.target.value), false);
    btnClose.onclick = () => {
      this.container.removeChild(this._dimModal);
    }

    innerContainer.appendChild(widthLabel);
    innerContainer.appendChild(widthInput);
    innerContainer.appendChild(heightLabel);
    innerContainer.appendChild(heightInput);
    innerContainer.appendChild(btnClose);
    mainContainer.appendChild(innerContainer);

    return (mainContainer);
  }

  inputHandler(dimension, input, value) {
    if (value <= 0 || value == '') {
      input.value = ''
      if (dimension === "width") {
        this._data.dimensions.width = 'auto';
        this._element.style.width = 'auto';
      }
      if (dimension === "height") {
        this._data.dimensions.height = 'auto';
        this._element.style.height = 'auto';
      }
    }
    else {
      if (dimension === "width") {
        this._data.dimensions.width = value;
        this._element.style.width = `${value}px`;
      }
      if (dimension === "height") {
        this._data.dimensions.height = value;
        this._element.style.height = `${value}px`;
      }
    }
  }

  render() {
    this.container = document.createElement('div');
    this.container.classList.add(this._CSS.container);
    let img = this._element;
    this._dimModal = this.renderDimensionsModal();

    if (!this.readOnly) {
      let btn = document.createElement('button');
      let btnRemove = document.createElement('button');
      let btnDimension = document.createElement('button');

      if (this._data.url) {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon);
        btn.innerHTML = `${InlineImage.toolbox.icon}`;
        btnRemove.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.clear);
        btnRemove.innerHTML = `${CloseIcon}`;
        btnDimension.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon, this._CSS.dimensionBtn);
        btnDimension.innerHTML = `${SizeIcon}`;
        this.container.classList.add('has-img');
      } else {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon);
        btn.innerHTML = `${InlineImage.toolbox.icon}`;
      }

      btn.onclick = () => {
        const blockIndex = this.api.blocks.getCurrentBlockIndex();
        const block = this.api.blocks.getBlockByIndex(blockIndex);
        this._settings.showMediaLibrary({
          details: block,
          data: this._data,
          bundle: 'image',
        });
      };

      btnRemove.onclick = () => {
        this._data.url = '';
        this._data.mid = '';
        this._data.width = '';
        this._data.height = '';
        this._data.x = '';
        this._data.y = '';
        this._data.maxWidth = '';
        this._data.maxHeight = '';

        this._element.remove();
        btnRemove.remove();
        this._dimModal.remove();
        btnDimension.remove()
      }

      btnDimension.onclick = () => {
        this.container.appendChild(this._dimModal);
      }

      if (typeof img !== 'undefined') {
        this.container.appendChild(img);
        this.container.appendChild(btn);
        this.container.appendChild(btnRemove);
        this.container.appendChild(btnDimension);
        this.container.classList.add('has-img');
      } else {
        // hidden input hack
        // prevents empty blocks being created
        let input = document.createElement('input');
        input.setAttribute('style', 'visibility: hidden; width: 0; height: 0; padding: 0; margin: 0; border: 0; position: absolute');

        this.container.appendChild(input);
        this.container.appendChild(btn);
      }
    } else {
      if (typeof img !== 'undefined') {
        this.container.appendChild(img);
        this.container.classList.add('has-img');
      }
    }

    return this.container;
  }

  save() {
    if (typeof this._element !== 'undefined') {
      this._data.url = this._element.getAttribute('src') || '';
      this._data.mid = this._element.getAttribute('data-mid') || '';
      this._data.x = this._element.getAttribute('data-x') || '';
      this._data.y = this._element.getAttribute('data-y') || '';
      this._data.width = this._element.getAttribute('data-width') || '';
      this._data.height = this._element.getAttribute('data-height') || '';
      this._data.maxWidth = this._element.getAttribute('data-maxwidth') || '';
      this._data.maxHeight = this._element.getAttribute('data-maxheight') || '';
    } else {
      this._data.url = '';
      this._data.mid = '';
      this._data.width = '';
      this._data.height = '';
      this._data.x = '';
      this._data.y = '';
      this._data.maxWidth = '';
      this._data.maxHeight = '';
    }

    return this._data;
  }
}