import React, { Component } from 'react';

// Modules
import { decode } from 'html-entities';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Bookmark, FileText } from 'react-feather';
import { Link } from 'react-router-dom';
import { FormControlLabel, Switch, Button } from '@mui/material';

class PageHeader extends Component {

  renderFilters = () => {

    if (this.props.filters) {
      return (
        <Col
          xs={12}
          xl={this.props.getArchives ? 9 : 4}
          lg={this.props.getArchives ? 9 : 4}
          className="mt-4 mt-xl-0 spacer"
        >
          <Row
            className="page-header__filters justify-content-xl-end align-items-end g-0"
          >
            <Col xs={12} sm={3} md={3} xl={this.props.getArchives ? 4 : 7} lg={this.props.getArchives ? 4 : 7}>
              {this.props.categorySelector}
            </Col>
            <Col
              xs={12}
              sm={3}
              md={3}
              lg={this.props.getArchives ? 8 : 5}
              className={
                this.props.getArchives
                  ? "mt-4 mt-md-0 ms-xl-0 d-flex spacer"
                  : "mt-4 mt-sm-0 ms-xl-0 d-flex spacer"
              }
            >
              <Button
                className={
                  this.props.activeTabIndex === 1
                    ? "filter-btn active"
                    : "filter-btn"
                }
                onClick={() => {
                  if (this.props.getFavourites) {
                    this.props.getFavourites();
                  }
                }}
              >
                <span>Favourites</span>
                <Bookmark />
              </Button>
              {this.props.getArchives && (
                <Button
                  className={
                    this.props.activeTabIndex === 2
                      ? "filter-btn active"
                      : "filter-btn"
                  }
                  onClick={() => {
                    if (this.props.getArchives) {
                      this.props.getArchives();
                    }
                  }}
                >
                  <FileText />
                  <span>Archive</span>
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      );
    }
  };

  listHeaderLabel = (item) => {
    return (
      <Col
        className={`list-header-label-column ${item.grow ? `flex-grow-${item.grow}` : ''}${item.align ? item.align : ''} ${item?.className? item.className: ""}`}
        xs={item?.width.xs ? item.width.xs : ""}
        sm={item?.width.sm ? item.width.sm : ""}
        md={item?.width.md ? item.width.md : ""}
        lg={item?.width.lg ? item.width.lg : ""}
        xl={item?.width.xl ? item.width.xl : ""}
        xxl={item?.width.xxl ? item.width.xxl : ""}
        style={item?.style ? item.style : {}}
      >
        <label className='list-header-label'>
          {item.title}
        </label>
      </Col>
    )
  }

  render() {
    let pageName = this.props.pageName;
    try {
      pageName = decode(pageName)
    } catch (error) {
      // console.log('cannot decode')
    }

    return (
      <div className="page-header">
        <Row className="align-items-end">
          <Col className="page-name">
            <Row className='align-items-end'>
              <h1 className="mb-0">{pageName}</h1>
              {this.props.toggleSwitch &&
                <Link to={this.props.switchLink}>
                  <FormControlLabel label="Edit Mode" control={<Switch id="edit-switch" defaultChecked={this.props.switchStatus} />} />
                </Link>
              }
            </Row>
            {this.props.listHeader &&
              <Row className='list-header-label-container pt-3 me-0'>
                {this.props.listLabels.length > 0 &&
                  this.props.listLabels.map((item) => {
                    return this.listHeaderLabel(item)
                  })
                }
              </Row>
            }
          </Col>
          {this.renderFilters()}
        </Row>
      </div>
    );
  }
}

export default PageHeader;
