// Dependencies
import React from "react";

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AlertTriangle, CheckCircle, Info } from "react-feather";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import logo from "../assets/ca-logo.png"

class AlertModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: this.props.showAlert,
    };
  }

  /**
   * Set modal visibility
   * @param {*} visible
   */
  setModalVisible = (visible) => {
    this.setState(
      {
        modalVisible: visible,
      },
      () => {
        if (this.props.showAlertCallback) {
          this.props.showAlertCallback(this.state.modalVisible);
        }
      }
    );
  };

  /**
   * Render action buttons (Cancel / OK)
   * @param {*} alertType 
   * @returns 
   */
  renderAlertActions = (alertType) => {
    return (
      <Row className="g-0">
        {this.props.cancelButton && (
          <Col className="pe-3">
            <Button
              type="button"
              className="close-modal-btn"
              size="small"
              variant="outlined"
              color={this.props.cancelButtonColor ?
                this.props.cancelButtonColor :
                "primary"
              }
              onClick={() => {
                this.setModalVisible(false);

                if (this.props.onCancel) {
                  this.props.onCancel();
                }
              }}
            >
              {this.props.cancelButtonLabel
                ? this.props.cancelButtonLabel
                : "Cancel"}
            </Button>
          </Col>
        )}

        {this.props.confirmButton && (
          <Col className="ps-3">
            <Button
              type="button"
              className="close-modal-btn"
              size="small"
              variant="contained"
              color={this.props.confirmButtonColor ?
                this.props.confirmButtonColor :
                "primary"
              }
              onClick={() => {
                this.setModalVisible(false);

                if (this.props.onConfirm) {
                  this.props.onConfirm();
                }
              }}
            >
              {this.props.confirmButtonLabel
                ? this.props.confirmButtonLabel
                : "OK"}
            </Button>
          </Col>
        )}
      </Row>
    );
  }

  /**
   * Render the alert modal
   * @returns 
   */
  renderAlert = () => {

    const iconSelector = {
      success: <CheckCircle />,
      info: <Info />,
      error: <AlertTriangle />,
    }

    let alertType = "default";
    let icon = iconSelector.info;

    if (this.props.alertType !== "undefined") {
      alertType = this.props.alertType;
    }

    if (this.props.alertType === "success") {
      icon = iconSelector.success;
    }
    if (this.props.alertType === "warning") {
      icon = iconSelector.info;
    }
    if (this.props.alertType === "error") {
      icon = iconSelector.error;
      alertType = "danger"; // set to bootstrap colours
    }

    return (
      <Dialog
        open={this.props.showAlert}
        onClose={() => {this.props.onClose ? this.props.onClose() : this.setModalVisible(false)}}
        className="alert-dialog"
      // centered
      // dialogClassName={`alert-modal--${alertType} alert-modal`}
      >
        <DialogTitle
          // className={`d-flex justify-content-center text-${alertType}`}
          className={`d-flex justify-content-center`}
        >
          {this.props.showCaLogo &&
            <img className="ca-logo" src={logo} />
          }
          {this.props.alertMessageTitle}
          {/* <Typography variant="h5" color="modal.dark">
            {this.props.alertMessageTitle}
          </Typography> */}
        </DialogTitle>
        <DialogContent className="text-center">
          <p className="mb-0">{this.props.alertMessageBody}</p>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center mb-2">
          {this.renderAlertActions(alertType)}
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    if (this.props.showAlert) {
      return this.renderAlert();
    } else {
      return false;
    }
  }
}

export default AlertModal;
