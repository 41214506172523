import { Box, Button } from "@mui/material/";
import { KeyboardBackspace } from "@mui/icons-material";

export default function BackButton(props) {
  return (
    <Button className={`back-btn`} onClick={() => props.onCancel()}>
      <Box>
        <KeyboardBackspace />
      </Box>
      Back to library
    </Button>
  );
}
