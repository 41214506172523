import React, { Component } from 'react';

// UI components
import { CircularProgress } from '@mui/material';

class LoadingSpinner extends Component {

  render() {
    let containerStyle = 'spinner-container';

    if (this.props.className && this.props.className !== undefined) {
      containerStyle = `${this.props.className} spinner-container`;
    }

    return (
      <div className={containerStyle}>
        <CircularProgress size={20} />
      </div>
    );
  }

}

export default LoadingSpinner;