import React, { useEffect, useState } from 'react';

// Modules
import { NestedMenuItem } from 'mui-nested-menu';

// App
import { decodeHtmlEntities } from '../../../../../utils/helpers';
import { getOnlineNode } from '../../../../../core/getNode';
import Share from '../../share';

// UI components
import { Container, Row, Col } from 'react-bootstrap';
import { Alert, CircularProgress, Box, Stack, Button, Dialog, DialogContent, Stepper, Step, StepButton, Checkbox, FormControl, FormControlLabel, InputLabel, Select, MenuItem, TextField, Autocomplete, Typography, OutlinedInput } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

const steps = [
  'Access',
  'Category, SITA & Language',
  'Ready'
];

const cleanTaxonomies = (taxonomies) => {
  let tax = [];

  taxonomies.forEach(taxonomy => {
    if (taxonomy.value !== 0) {
      tax.push(taxonomy);
    }
  });

  return tax;
}

export default function Publishing(props) {
  const [isLoading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);

  // categories
  const categories = cleanTaxonomies(props.categories);
  const languages = cleanTaxonomies(props.languages);
  const sitas = cleanTaxonomies(props.sitas);
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(parseInt(props.item?.category?.tid ? props.item?.category?.tid : null));
  const [selectedLanguage, setSelectedLanguage] = useState(parseInt(props.item?.language?.tid ? props.item?.language?.tid : null));
  const [selectedSita, setSelectedSita] = useState(null);

  const [permissionsComplete, setPermissionsComplete] = useState(false);
  const [categoryComplete, setCategoryComplete] = useState(selectedCategory === 0 ? false : true);
  const [languageComplete, setLanguageComplete] = useState(selectedLanguage === 0 ? false : true);
  const [sitaComplete, setSitaComplete] = useState(false);
  const [completed, setCompleted] = useState(false);

  // permissions
  const [presentationPermissions, setPresentationPermissions] = useState({});
  const [customPermsError, setCustomPermsError] = useState(false);
  const [keepPrivate, setPrivate] = useState(false);
  const [makePublic, setPublic] = useState(false);
  const [advancedPermissions, setAdvancedPermissions] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);

  // permissions
  useEffect(() => {
    getOnlineNode(`api/presentation_permissions/${props.item.id}`)
      .then(response => {
        setPresentationPermissions(response.data);
        checkState(true, response.data);
      })
      .catch(_error => {
        const emptyData = {
          access_collection: [],
          access_collection_list: [],
          role_list: [],
          roles: {},
          users: []
        }

        setCustomPermsError(true);
        setPresentationPermissions(emptyData);
        checkState(true, emptyData);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // selected sita
  useEffect(() => {
    let selected;
  
    if (props.item?.sita?.tid) {
      selected = sitas.find(item => parseInt(item.tid) === parseInt(props.item?.sita?.tid));
    } else {
      selected = sitas.find(item => parseInt(item.tid) === 0);
    }
    setSelectedSita(selected);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (permissionsComplete && categoryComplete && languageComplete && sitaComplete) {
      setCompleted(true);
    } else {
      if (completed) {
        setCompleted(false);
      }
    }
  }, [permissionsComplete, categoryComplete, languageComplete, sitaComplete, completed]);

  // permissions
  useEffect(() => {
    if (!keepPrivate && !makePublic && !advancedPermissions) {
      setPermissionsComplete(false);
    } else {
      if (!advancedPermissions) {
        setPermissionsComplete(true);
      } else {
        // check permissions here
      }
    }
  }, [keepPrivate, makePublic, advancedPermissions]);

  // categories
  useEffect(() => {
    if (!selectedCategory || parseInt(selectedCategory) === 0) {
      setCategoryComplete(false);
    } else {
      setCategoryComplete(true);
    }
  }, [selectedCategory]);

  // language
  useEffect(() => {
    if (!selectedLanguage || parseInt(selectedLanguage) === 0) {
      setLanguageComplete(false);
    } else {
      setLanguageComplete(true);
    }
  }, [selectedLanguage]);

  // sita
  useEffect(() => {
    if (!selectedSita || parseInt(selectedSita) === 0) {
      setSitaComplete(false);
    } else {
      setSitaComplete(true);
    }
  }, [selectedSita]);

  const checkState = (shouldUpdateSteps, perms) => {
    let step = 0;
    let permsComplete = false;
    let catComplete = false;
    let langComplete = false;


    if (Object.keys(perms?.roles)?.length > 0 || perms?.users?.length > 0) {
      if ( Object.keys(perms?.roles)?.length > 1) {
        setPrivate(false);
        setPublic(false);
        setAdvancedPermissions(true);
      } else {
        if (perms.roles['rhg_viewer']) {
          setPrivate(false);
          setPublic(true);
          setAdvancedPermissions(false);
        } else {
          setPrivate(false);
          setPublic(false);
          setAdvancedPermissions(true);
        }
      }

      step = step + 1;
      permsComplete = true;
    } else {
      setPrivate(true);
      setPublic(false);
      setAdvancedPermissions(false);

      step = step + 1;
      permsComplete = true;
    }

    if (props.item.category?.tid) {
      catComplete = true;
    }

    if (props.item.language?.tid) {
      langComplete = true;
    }

    setSitaComplete(true);
    setPermissionsComplete(permsComplete);
    setCategoryComplete(catComplete);
    setLanguageComplete(langComplete);

    if (permsComplete) {
      step = 1;

      if (permsComplete && catComplete && langComplete) {
        step = 2;
      }
    } else {
      step = 0;
    }

    if (shouldUpdateSteps) {
      setActiveStep(step);
    }

    setLoading(false);
  }

  const hideModal = () => {
    props.onHide();
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleCategorySelection = (tid) => {
    setSelectedCategory(parseInt(tid, 10));
    setOpen(false);
  };

  const hasChildren = (id) => {
    if (categories) {
      const nested = categories.filter(item => item.parent_target_id === id);

      if (nested.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getChildItems = (arr, id) => {
    return arr.filter(item => item.parent_target_id === id);
  };

  const menuProps = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    PaperProps: { style: { pointerEvents: 'auto' } },
  };

  const onPublish = () => {
    let params = {
      category: selectedCategory,
      sita: selectedSita?.tid ? parseInt(selectedSita?.tid) : null,
      language: selectedLanguage
    };

    if (makePublic) {
      params.permissions = 1;
    }

    if (keepPrivate) {
      params.permissions = 0;
    }

    props.onPublish(params);
    hideModal();
  };

  return (
    <>
      <Dialog
        open={props.modalVisible}
        onClose={() => hideModal}
        className='ps-modal share-modal'
        maxWidth='lg'
        fullWidth={true}
      >
        <Box className='ps-modal-title'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col xs={12} sm={6}>
                <h6 style={{ margin: 0 }}>Publish presentation <b>(ID: {props.item.id})</b></h6>
              </Col>
              <Col xs={12} sm={6}>
                <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
                  <>
                    <Button
                      variant='outlined'
                      className='cancel'
                      onClick={() => {
                        hideModal();
                      }}>
                      Cancel
                    </Button>
                  </>
                </Stack>
              </Col>
            </Row >
          </Container>
        </Box>

        <DialogContent>
          <Box className='ps-modal-content'>
            <Container fluid>
              {isLoading ? (
                <Row>
                  <Col className='d-flex justify-content-center'>
                    <CircularProgress size={20} />
                  </Col>
                </Row>
              ) : (
                <>
                  <Row className='mb-5 mt-3'>
                    <Col>
                      <Stepper nonLinear={completed ? true : false} activeStep={activeStep}>
                        {steps.map((label, index) => {
                          let isComplete = false;

                          if (index === 0) {
                            isComplete = permissionsComplete;
                          }

                          if (index === 1) {
                            isComplete = (categoryComplete && languageComplete && sitaComplete);
                          }

                          if (index === 2) {
                            isComplete = (permissionsComplete && categoryComplete && languageComplete && sitaComplete && activeStep === 2);
                          }

                          return (
                            <Step
                              key={label}
                              completed={isComplete}
                              sx={{
                                padding: 0,
                                '& .MuiStepIcon-root.Mui-completed': {
                                  color: 'secondary.main',
                                },
                              }}
                            >
                              <StepButton onClick={handleStep(index)}>
                                {label}
                              </StepButton>
                            </Step>
                          );
                        })}
                      </Stepper>
                    </Col>
                  </Row>

                  <Row>
                    {activeStep === 0 && (
                      <>
                        <Col xs={12} className='mb-3'>
                          <label>Choose who can see your presentation</label>
                        </Col>

                        <Col xs={12} sm={6} md={4}>
                          <div className='form-group mb-0'>
                            <FormControlLabel
                              className='dark'
                              control={
                                <Checkbox
                                  id='keep-private-checkbox'
                                  checked={keepPrivate}
                                  onChange={(event) => {
                                    setPrivate(!keepPrivate);
                                    setAdvancedPermissions(false);

                                    if (event.target.checked) {
                                      setPublic(false);
                                    }
                                  }}
                                />
                              }
                              label='1. Keep private'
                            />
                          </div>
                        </Col>

                        <Col xs={12} sm={6} md={4}>
                          <div className='form-group mb-0'>
                            <FormControlLabel
                              className='dark'
                              control={
                                <Checkbox
                                  id='make-public-checkbox'
                                  checked={makePublic}
                                  onChange={(event) => {
                                    setPublic(!makePublic);
                                    setAdvancedPermissions(false);
                                    if (event.target.checked) {
                                      setPrivate(false);
                                    }
                                  }}
                                />
                              }
                              label='2. Give view access to everyone'
                            />
                          </div>
                        </Col>

                        <Col xs={12} sm={6} md={4}>
                          <div className='form-group mb-0'>
                            <FormControlLabel
                              className='dark'
                              sx={customPermsError ? {opacity: 0.5} : {}}
                              control={
                                <Checkbox
                                  id='advanced-checkbox'
                                  checked={advancedPermissions}
                                  disabled={customPermsError ? true : false}
                                  onChange={(event) => {
                                    setPublic(false);
                                    setPrivate(false)
                                    setAdvancedPermissions(event.target.checked);
                                    setShowAdvanced(true);
                                  }}
                                />
                              }
                              label='3. Give specific user access'
                            />
                            {customPermsError && (
                              <Box sx={{marginTop: 1}}>
                                <Alert variant='filled' severity='error' sx={{fontSize: 12}}>Due to an error, this option cannot be selected.</Alert>
                              </Box>
                            )}
                            {/* <br /> */}
                            {/* <FormControlLabel
                              sx={{ visibility: 'hidden', marginRight: 0 }}
                              control={
                                <Checkbox
                                  id='advanced-checkbox'
                                  checked={advancedPermissions}
                                  onChange={(event) => {
                                    setPublic(false);
                                    setPrivate(false)
                                    setAdvancedPermissions(event.target.checked);
                                    setShowAdvanced(true);
                                  }}
                                />
                              }
                            /> */}
                          </div>
                        </Col>
                      </>
                    )}

                    {activeStep === 1 && (
                      <>
                        <Col xs={12} className='mb-4'>
                          <label>Choose a category (*), language (*) and a SITA code for your presentation</label>
                        </Col>
                        <Col xs={12} sm={6} md={4}>
                          <div className='form-group'>
                            <FormControl fullWidth size='small' sx={{ backgroundColor: 'primary.light' }}>
                              <InputLabel id={`category`}>Category *</InputLabel>
                              <Select
                                labelId={`category-id`}
                                value={selectedCategory}
                                open={open}
                                onClose={() => setOpen(false)}
                                onOpen={() => setOpen(true)}
                                input={
                                  <OutlinedInput
                                    sx={{
                                      fontSize: '16px',
                                      '&.MuiInput-underline:before': {
                                        borderBottomColor: '#d2d0cf',
                                      },
                                    }}
                                    label='Category'
                                  />
                                }
                              >
                                {categories?.map((category, index) => {
                                  const has_child = hasChildren(category.value);
                                  const is_top_level = category.parent_target_id ? false : true;

                                  if (is_top_level) {
                                    if (has_child) {
                                      const region_child_items = getChildItems(categories, category.value);

                                      return (
                                        <NestedMenuItem
                                          key={`category-${category.value}`}
                                          leftIcon={null}
                                          rightIcon={<ChevronRight />}
                                          parentMenuOpen={open}
                                          label={category.label}
                                          MenuProps={menuProps}
                                          className='settings-nested'
                                        >
                                          {
                                            region_child_items.map((region) => {
                                              const countries = getChildItems(categories, region.value);

                                              return (
                                                <NestedMenuItem
                                                  key={`region-${region.value}`}
                                                  leftIcon={null}
                                                  rightIcon={<ChevronRight />}
                                                  parentMenuOpen={open}
                                                  label={region.label}
                                                  MenuProps={menuProps}
                                                  className='settings-nested'
                                                >
                                                  {
                                                    countries.map((country) => {
                                                      const cities = getChildItems(categories, country.value);

                                                      return (
                                                        <NestedMenuItem
                                                          key={`country-${country.value}`}
                                                          leftIcon={null}
                                                          rightIcon={<ChevronRight />}
                                                          parentMenuOpen={open}
                                                          label={country.label}
                                                          MenuProps={menuProps}
                                                          className='settings-nested'
                                                        >
                                                          {
                                                            cities.map((city, cityIndex) => {
                                                              return (
                                                                <MenuItem
                                                                  key={`city-${city.value}`}
                                                                  value={city.value}
                                                                  sx={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "400",
                                                                    "&.Mui-selected": {
                                                                      backgroundColor: "primary.light"
                                                                    }
                                                                  }}
                                                                  onClick={() => {
                                                                    handleCategorySelection(city.value);
                                                                  }}
                                                                >
                                                                  {decodeHtmlEntities(city.label)}
                                                                </MenuItem>
                                                              )
                                                            })
                                                          }
                                                        </NestedMenuItem>
                                                      )
                                                    })
                                                  }
                                                </NestedMenuItem>
                                              )
                                            })
                                          }
                                        </NestedMenuItem>
                                      )
                                    } else {
                                      return (
                                        <MenuItem
                                          key={`category-${category.value}`}
                                          value={category.value}
                                          sx={{
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            "&.Mui-selected": {
                                              backgroundColor: "primary.light"
                                            }
                                          }}
                                          onClick={() => {
                                            handleCategorySelection(category.value);
                                          }}
                                        >
                                          {decodeHtmlEntities(category.label)}
                                        </MenuItem>
                                      );
                                    }
                                  } else {
                                    return (
                                      <MenuItem
                                        key={`category-${category.value}`}
                                        value={category.value}
                                        sx={{ display: 'none' }}
                                        onClick={() => {
                                          handleCategorySelection(category.value);
                                        }}
                                      >
                                        {decodeHtmlEntities(category.label)}
                                      </MenuItem>
                                    );
                                  }
                                })}
                              </Select>
                            </FormControl>
                            <span className='help-text'>This field is <b>required</b></span>
                          </div>
                        </Col>
                        <Col xs={12} sm={6} md={4}>
                          <div className='form-group'>
                            <FormControl fullWidth size='small' sx={{ backgroundColor: 'primary.light' }}>
                              <Autocomplete
                                options={sitas}
                                getOptionLabel={(option) => option.title}
                                value={selectedSita}
                                disableClearable={true}
                                onChange={(event, value) => {
                                  setSelectedSita(value);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="SITA"
                                    InputProps={{
                                      ...params.InputProps,
                                      type: 'search',
                                      sx: {
                                        paddingBottom: 0,
                                        '& .MuiOutlinedInput-input': {
                                          paddingBottom: '0 !important',
                                          paddingTop: '0 !important',
                                          borderColor: '#c2ccd6',
                                        },
                                        '&:hover .MuiOutlinedInput-input': {
                                          borderWidth: '2px',
                                          borderColor: 'primary.main'
                                        },
                                        '& .MuiAutocomplete-endAdornment': {
                                          top: 'calc(50% - 16px)'
                                        }
                                      }
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                            <span className='help-text'>This field is <b>optional</b></span>
                          </div>
                        </Col>
                        <Col xs={12} sm={6} md={4}>
                          <div className='form-group'>
                            <FormControl fullWidth size='small' sx={{ backgroundColor: 'primary.light' }}>
                              <InputLabel id='language-select-label'>Language *</InputLabel>
                              <Select
                                labelId='language-select-label'
                                id='language-select'
                                value={selectedLanguage}
                                onChange={(event) => {
                                  setSelectedLanguage(parseInt(event.target.value));
                                }}
                                input={
                                  <OutlinedInput
                                    sx={{
                                      fontSize: '16px',
                                      '&.MuiInput-underline:before': {
                                        borderBottomColor: '#d2d0cf',
                                      },
                                    }}
                                    label='Language'
                                  />
                                }
                              >
                                {languages?.map((language, index) =>
                                  <MenuItem key={`language-${index}`} value={language.value} sx={{ fontSize: '16px' }} >{language.label}</MenuItem>)}
                              </Select>
                            </FormControl>
                            <span className='help-text'>This field is <b>required</b></span>
                          </div>
                        </Col>
                      </>
                    )}

                    {activeStep === 2 && (
                      <>
                        {completed ? (
                          <>
                            <Col xs={12} className='mt-3'>
                              <Typography>All steps are completed. The presentation is now ready to be published.</Typography>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col xs={12} className='mt-3'>
                              <Typography>Please complete all steps. You cannot publish a presentation before completing all steps.</Typography>
                            </Col>
                          </>
                        )}
                      </>
                    )}
                  </Row>

                  <Row className='mt-5 mb-5'>
                    <Col xs={12} className='d-flex justify-content-center'>
                      {activeStep !== 0 && (
                        <Button
                          variant='outlined'
                          className='cancel'
                          onClick={() => {
                            setActiveStep(activeStep - 1);
                          }}>
                          Back
                        </Button>
                      )}
                      {activeStep !== 2 && (
                        <>
                          {activeStep === 0 && (
                            <Button
                              variant='contained'
                              onClick={() => {
                                setActiveStep(activeStep + 1);
                              }}
                              disabled={permissionsComplete ? false : true}
                            >
                              Next
                            </Button>
                          )}

                          {activeStep === 1 && (
                            <Button
                              variant='contained'
                              onClick={() => {
                                setActiveStep(activeStep + 1);
                              }}
                              disabled={(categoryComplete && languageComplete && sitaComplete) ? false : true}
                            >
                              Next
                            </Button>
                          )}
                        </>
                      )}
                      {activeStep === 2 && (
                        <Button
                          variant='contained'
                          color='secondary'
                          className='action'
                          disabled={(completed && activeStep === 2) ? false : true}
                          onClick={() => {
                            // save
                            onPublish();
                          }}>
                          Publish
                        </Button>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          </Box>
        </DialogContent >
      </Dialog >

      {advancedPermissions && showAdvanced && (
        <Share
          presentation={props.item}
          user={props.user}
          permissions={props.permissions}
          modalVisible={showAdvanced}
          onPermissionsUpdate={(data) => {
            setPresentationPermissions(data);
            checkState(false, data);
            setShowAdvanced(false);
          }}
          hideShare={true}
          onHide={() => {
            console.log('on hide');
            setShowAdvanced(false);
          }}
        />
      )}
    </>
  );
};
