import React, { useState } from 'react';

// Modules

// App

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { IconButton, Slider } from '@mui/material';

export default function ColorPickerNew(props) {
  const [color, setColor] = useState(props.slideBackgroundColor);
  const colors = props.colors ? props.colors : ['#F9F9F9', '#333333', '#555555'];
  const colorsSecondary = props.colorsSecondary ? props.colorsSecondary : [];
  const colorsDefault = props.colorsDefault ? props.colorsDefault : ['', '#000000', '#ffffff']
  const transparentBgUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQAWJ84A0+ScZRAxiGSRgQSAb40wkoDAgBvAlt1AAGcEIiBGgbiAAgXwixcH9GzgAAAABJRU5ErkJggg=='

  const handleColorSelect = (selected) => {
    setColor(selected);
    props.onColorSelected(selected);
  }

  return (
    <div className='colorpicker-container mb-4'>
      <Row>
        {!props.hideTitle && (
          <Col xs={'auto'} className='d-flex align-items-end pe-4'>
            <h5 className='mb-0 ss-subtitle'>{props.title}</h5>
          </Col>
        )}
        {!props.hideDefault && (
          <Col xs={'auto'}>
            <Row>
              <span className='color-group-label'>
                Default
              </span>
            </Row>
            <Row className='colors-container primary'>
              {colorsDefault.map((el, index) => {
                return (
                  <Col key={`picker-${index}`} xs={'auto'} className='pe-0'>
                    <div className={`color-item d-flex justify-content-center align-items-center ${el === color ? 'selected' : ''}`}>
                      <IconButton
                        className={`color-btn ${el === '' ? 'none' : ''}`}
                        style={{ background: el === 'transparent' ? `url(${transparentBgUrl})` : el, width: '15px', height: '15px' }}
                        onClick={() => handleColorSelect(el)} />
                    </div>
                  </Col>
                )
              })}
            </Row>
          </Col>
        )}
        <Col xs={'auto'}>
          {!props.hideDefault && (
            <Row>
              <span className='color-group-label'>
                Primary
              </span>
            </Row>
          )}
          <Row className='colors-container primary'>
            {colors.map((el, index) => {
              return (
                <Col key={`picker-${index}`} xs={'auto'} className='pe-0'>
                  <div className={`color-item d-flex justify-content-center align-items-center ${el === color ? 'selected' : ''}`}>
                    <IconButton
                      className='color-btn'
                      style={{ background: el === 'transparent' ? `url(${transparentBgUrl})` : el, width: '15px', height: '15px' }}
                      onClick={() => handleColorSelect(el)} />
                  </div>
                </Col>
              )
            })}
          </Row>
        </Col>
        {colorsSecondary.length > 0 &&
          <Col xs={'auto'}>
            <Row>
              <span className='color-group-label'>
                Secondary
              </span>
            </Row>
            <Row>
              {colorsSecondary.map((el, index) => {
                return (
                  <Col key={`picker-${index}`} xs={'auto'} className='pe-0'>
                    <div className={`color-item d-flex justify-content-center align-items-center ${el === color ? 'selected' : ''}`}>
                      <IconButton
                        className='color-btn'
                        style={{ background: el === 'transparent' ? `url(${transparentBgUrl})` : el, width: '15px', height: '15px' }}
                        onClick={() => handleColorSelect(el)} />
                    </div>
                  </Col>
                )
              })}
            </Row>
          </Col>}

        {props.title === 'Overlay colour' && 
          <Col xs={'auto'} className='d-flex align-items-end pe-4'>
            <Row className='flex-nowrap align-items-center'>
             <label className='setting-title'>Overlay opacity</label>
             <Slider
              // size='small'
              className='slider'
              min={0}
              max={1}
              step={0.01}
              valueLabelDisplay='auto'
              color='primary'
              value={props.overlayOpacity}
              onChange={(e) => {
                props.changeOverlayOpacity(e.target.value)
              }}

              sx={{
                '& .MuiSlider-track, & .MuiSlider-rail': {
                  color: '#ced4da !important',
                },
                '& .MuiSlider-thumb': {
                  color: '#1d9bf1 !important'
                },
                '& .MuiSlider-markLabel, & .MuiSlider-valueLabel': {
                  color: '#fff !important'
                },
                '.MuiSlider-valueLabelLabel': {
                  color: '#fff !important'
                }
              }}
             />
            </Row>
          </Col>
        }
      </Row>
    </div>
  );
}