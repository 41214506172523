import React, { useState, useEffect } from "react";

// Modules

// App
import Layout from "../layouts/item";
import { getThemeJson } from "../../../../utils/helpers";

// UI components
import { Container, Row, Col } from "react-bootstrap";
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";

export default function AddSlide(props) {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Unknown error. Please refresh and try again."
  );

  const [layoutFilter, setLayoutFilter] = useState(
    props.slideLayout ? props.slideLayout.category : "all"
  );

  const [layouts, setLayouts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (!props.samplePresentationLoading) {
      if (props.presentation) {
        try {
          const theme_json = getThemeJson(props?.presentation?.theme?.label);

          setCategories(theme_json?.layoutCategories);

          let slides = [];

          if (props.samplePresentation) {
            theme_json.layouts.forEach((item) => {
              let version = 1;

              if (props.samplePresentation?.slides?.length > 0) {
                props.samplePresentation?.slides.forEach((slide) => {
                  try {
                    const json = JSON.parse(slide.slide_json);
                    if (json.reveal.slideLayout.id === item.id) {
                      slides.push({
                        ...item,
                        slide: slide,
                        version: version++,
                      });
                    }
                  } catch (error) {
                    // nothing to see here
                  }
                });
              } else {
                slides.push({
                  ...item,
                  version: version++,
                });
              }
            });

            setLayouts(slides);
            setLoading(false);
            setError(false);
          } else {
            let showEmptySlides = false;

            if (props.user) {
              props.user.current_user.roles.forEach((role) => {
                if (role === "administrator") {
                  showEmptySlides = true;
                }
              });
            }

            if (showEmptySlides) {
              theme_json.layouts.forEach((item) => {
                let version = 1;

                slides.push({
                  ...item,
                  version: version++,
                });
              });

              setError(false);
              setLayouts(slides);
              setLoading(false);
            } else {
              setError(true);
              setErrorMessage("Network error. Please refresh and try again");
              setLoading(false);
            }
          }
        } catch (error) {
          setLoading(false);
          setError(true);
          setErrorMessage("Unknown error. Please refresh and try again.");
        }
      } else {
        setLoading(false);
        setErrorMessage(
          "Failed to fetch presentation. Please refresh and try again."
        );
        setError(true);
      }
    }
  }, [
    props.samplePresentation,
    props.presentation,
    props.user,
    props.samplePresentationLoading,
  ]);

  return (
    <Dialog
      fullScreen={true}
      open={props.fromLayoutOpen}
      onClose={props.onLayoutClose}
    >
      <AppBar
        sx={{
          position: "relative",
          backgroundColor: "primary.light",
          color: "solid.dark",
        }}
      >
        <DialogContent sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {props.title}
            </Typography>
            <Button
              sx={{
                backgroundColor: "primary.contrastText",
                width: "130px",
                height: "35px",
                textTransform: "none",
                fontWeight: "400",
              }}
              edge="start"
              onClick={props.onLayoutClose}
              aria-label="close"
              variant="outlined"
            >
              Cancel
            </Button>
          </Toolbar>
        </DialogContent>
      </AppBar>

      {props.samplePresentationLoading ? (
        <DialogContent sx={{ textAlign: "center" }}>
          <CircularProgress color="primary" />
        </DialogContent>
      ) : (
        <>
          {isLoading ? (
            <DialogContent sx={{ textAlign: "center" }}>
              <CircularProgress color="primary" />
            </DialogContent>
          ) : (
            <>
              {isError ? (
                <DialogContent>
                  <Container fluid>
                    <Row className="mb-5">
                      <Col>
                        <Typography variant="body">{errorMessage}</Typography>
                        <br />
                        <br />
                        <Button
                          onClick={() => {
                            window.location.reload();
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Refresh
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </DialogContent>
              ) : (
                <Container fluid>
                  <Row className="mb-5">
                    <Col>
                      <Tabs
                        value={layoutFilter}
                        onChange={(event, newValue) => {
                          setLayoutFilter(newValue);
                        }}
                        aria-label="Layout categories"
                      >
                        <Tab key="all" value="all" label={"All"} />
                        {categories.map((category, index) => (
                          <Tab
                            key={`label-${index}`}
                            value={category}
                            label={category}
                          />
                        ))}
                      </Tabs>
                    </Col>
                  </Row>
                  <Row>
                    {layouts.map((layout, index) => {
                      if (layout.id === "advanced") {
                        if (!props.permissions.access_advanced_layout) {
                          return null;
                        }
                      }

                      if (layout.id === "blank") {
                        if (!props.permissions.access_blank_layout) {
                          return null;
                        }
                      }

                      return (
                        <Layout
                          layout={layout}
                          key={`layout-${index}`}
                          className={`${layout.category} ${
                            layoutFilter !== "all" &&
                            layoutFilter !== layout.category
                              ? "d-none"
                              : ""
                          }`}
                          onAddSlide={(slide, _layout) => {
                            props.onAddSlide(slide, _layout);
                          }}
                          samplePresentation={props.samplePresentation}
                        />
                      );
                    })}
                  </Row>
                </Container>
              )}
            </>
          )}
        </>
      )}
    </Dialog>
  );
}
