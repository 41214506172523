import React from "react";

// Modules
import {connect} from 'react-redux';

// App
import { TwitterTimelineEmbed } from 'react-twitter-embed';
// import InstagramEmbed from 'react-instagram-embed';
import Error from "../../partials/error";
import SSOAuth from '../auth/ssoauth';
import HomeSkeletonPublicScreen from "./homeSkeletonPublicScreen copy";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Twitter } from "react-feather";
import { Dialog, DialogContent, Button, DialogTitle, Typography } from '@mui/material';

class PublicHome extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isRefreshing: false,
      modalVisible: true,
      isError: false,
      errorStatus: '',
      errorMessage: '',
    };
  }

  renderTwitterTimeline = () => {
    return (
      <Col xs={12} sm={8} className="mb-5 mb-md-0">
        <div className="twitter-container">
          <div className="twitter-container__header">
            <div className="twitter-brand">
              <div className="logo">
                <Twitter />
              </div>
              <span>Twitter</span>
            </div>
            <h6 className="twitter-handle">@RadissonHotels</h6>
          </div>
          <div className="twitter-container__timeline">
            <TwitterTimelineEmbed
              sourceType='profile'
              screenName='RadissonHotels'
              
            />
          </div>
        </div>
      </Col>
    );
  };

  render() {
    if (this.state.isError) {
      return (
        <Error 
          status={this.state.errorStatus}
          message={this.state.errorMessage}
        />
      );
    } else {
      if (this.state.isLoading) {
        return <HomeSkeletonPublicScreen />;
      } else {
        return (
          <main className='home--public home screen'>
            <Dialog
              open={this.state.modalVisible}
              maxWidth={'xs'}
              fullWidth={true}
              onClose={() => {
                this.setState({
                  modalVisible: false,
                });
              }}
              className='login_modal'
            >
              <DialogTitle
                sx={{
                  backgroundColor: 'primary.light',
                  color: 'solid.dark',
                  paddingLeft: '34px',
                  paddingRight: '34px',
                }}
              >
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Typography 
                          sx={{
                            fontSize: '32px',
                            fontWeight: 500,
                            lineHeight: '51.2px',
                            spacing: '0.24px'
                          }}
                        >
                          Login
                        </Typography>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-center" >
                        <Button
                          type="button"
                          variant='outlined'
                          sx={{
                            backgroundColor: 'primary.contrastText',
                            width: '130px',
                            height: '35px',
                            textTransform: 'none',
                            fontWeight: '400',
                          }}
                          className="close-modal-btn"
                          onClick={() => {
                            this.setState({
                              modalVisible: false,
                            });
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </DialogTitle>
              <DialogContent
                sx={{
                  paddingLeft: '34px',
                  paddingRight: '34px',
                }}
              >
                <SSOAuth type='modal' />
              </DialogContent>
            </Dialog>

            <Row className='mt-5 justify-content-center'>
              {this.renderTwitterTimeline()}
            </Row>
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(PublicHome);