import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

const withRouter = WrappedComponent => props => {
  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();

  return (
    <WrappedComponent
      {...props}
      params={params}
      location={location}
      navigate={navigate}
      // match={match}
    />
  );
};

export default withRouter;