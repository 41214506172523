import React, { useEffect, useState } from 'react';

// Modules
import Gallery from 'react-photo-gallery';
import { useSelector } from 'react-redux';

// App
import { filesystem_root, subdomain } from '../config';
import { getOnlineNode } from '../core/getNode';
import { paginator } from '../core/paginator';
import Add from './mediaLibrary/add';
import SelectedMedia from './mediaLibrary/selectedMedia';
import NoData from './noData';
import { decodeHtmlEntities, getLayoutFiles } from '../utils/helpers';

// UI components
import { SearchOutlined, Close, Add as AddIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Box,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  OutlinedInput,
  Pagination as MuiPagination,
  Typography,
  IconButton,
} from '@mui/material';
import { ReactComponent as AudioIcon } from '../assets/medialibrary-audio.svg';
import { ReactComponent as VideoIcon } from '../assets/medialibrary-video.svg';
import LoadingSpinner from './loadingSpinner';

import { Carousel, Row, Container, Col } from 'react-bootstrap';

const pageSize = 24;
const MB = 1048576; // bytes

const mediaTypes = [
  { label: 'All', value: 0 },
  { label: 'Image', value: 1 },
  { label: 'Video', value: 2 },
  { label: 'Audio', value: 3 },
];
const libraryOptions = [
  { label: 'All libraries', value: 0 },
  { label: 'My library', value: 2 },
  { label: 'Corporate library', value: 1 },
];

const input_font_size = 14;

export default function MediaLibrary(props) {
  /** redux */
  const permissions = useSelector((state) => state).permissionsReducer.permissions;

  const [isLoading, setLoading] = useState(true);
  const [isPaginating, setPaging] = useState(false);
  const [isError, setError] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [cropApplied, setCropApplied] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [showAdd, setShowAdd] = useState(false);

  const [media, setMedia] = useState([]);
  const [search, setSearch] = useState('');
  const [categories, setCategories] = useState([]);

  const [selectedMediaType, setSelectedMediaType] = useState(
    props.mediaType ? props.mediaType : '0'
  );
  const [selectedLibrary, setSelectedLibrary] = useState('2');
  const [selectedCategory, setSelectedCategory] = useState('0');

  const [widthFilter, setWidthFilter] = useState(0);
  const [heightFilter, setHeightFilter] = useState(0);

  const [mediaTypeFilterDisabled, setMediaTypeFilterDisabled] = useState(false);

  const [minWidth, setMinWidth] = useState(
    props?.files?.minWidth ? props.files.minWidth : 100
  );
  const [minHeight, setMinHeight] = useState(
    props?.files?.minHeight ? props.files.minHeight : 100
  );
  const [maxWidth, setMaxWidth] = useState(
    props?.files?.maxWidth ? props.files.maxWidth : 3000
  );
  const [maxHeight, setMaxHeight] = useState(
    props?.files?.maxHeight ? props.files.maxHeight : 3000
  );
  const [aspectRatio, setAspectRatio] = useState(
    props.files?.aspectRatio ? props.files.aspectRatio : null
  );
  const maxSize = props?.files?.maxSize ? MB * props.files.maxSize : MB * 300; // 2MB
  const maxImageSize = props?.files?.maxImageSize
    ? MB * props.files.maxImageSize
    : MB * 50; // 50MB
  const maxAudioSize = props?.files?.maxAudioSize
    ? MB * props.files.maxAudioSize
    : MB * 100; // 10MB
  const maxVideoSize = props?.files?.maxVideoSize
    ? MB * props.files.maxVideoSize
    : MB * 300; // 300MB

  useEffect(() => {
    // should trigger each time this is opened
    if (props.showMediaLibrary) {
      if (props.files && Object.keys(props.files).length !== 0) {
        if (props.files.minWidth) {
          setMinWidth(props.files.minWidth);
        }

        if (props.files.minHeight) {
          setMinHeight(props.files.minHeight);
        }

        if (props.files.maxWidth) {
          setMaxWidth(props.files.maxWidth);
        }

        if (props.files.maxHeight) {
          setMaxHeight(props.files.maxHeight);
        }

        if (props.files.aspectRatio) {
          setAspectRatio(props.files.aspectRatio);
        }
      } else {
        // get sizes from theme
        if (props.slideLayout && props.slideLayout.id) {
          let files = getLayoutFiles(
            props.slideLayout.id,
            props.slide.theme.label
          );

          if (files?.minWidth) {
            setMinWidth(files.minWidth);
          }

          if (files?.minHeight) {
            setMinHeight(files.minHeight);
          }

          if (files?.maxWidth) {
            setMaxWidth(files.maxWidth);
          }

          if (files?.maxHeight) {
            setMaxHeight(files.maxHeight);
          }

          if (files?.aspectRatio) {
            setAspectRatio(files.aspectRatio);
          }
        }
      }
    }
  }, [props.slideLayout, props.files, props.slide, props.showMediaLibrary]);

  useEffect(() => {
    async function getContent() {
      setError(false);
      setPaging(false);
      setLoading(true);

      /**
       * @TODO
       *
       * user library: my library, core library, shared
       * media category
       */

      let path = `media_api?items_per_page=${pageSize}&page=${currentPage - 1}`;

      if (search !== '') {
        path = `${path}&search=${search}`;
      }

      if (
        props.mediaType ||
        props.mediaType !== 'all' ||
        selectedMediaType !== '0'
      ) {
        if (props.mediaType.length > 0 || selectedMediaType) {
          path = `${path}&bundle=${mediaTypes[
            selectedMediaType
          ]?.label.toLowerCase()}`;
        }
      }

      if (selectedLibrary) {
        let bundle = 'all';

        if (selectedLibrary !== 0) {
          bundle =  selectedLibrary === 1 ? 'core_library' : 'user_library';
        }

        path = `${path}&library=${bundle}`;
      }

      if (selectedCategory !== 0) {
        path = `${path}&category=${selectedCategory}`;
      }

      if (selectedMediaType === 1 || props.mediaType === 'image') {
        if (widthFilter) {
          path = `${path}&width=${widthFilter}`;
        }

        if (heightFilter) {
          path = `${path}&height=${heightFilter}`;
        }
      }

      console.log('path: ', path);

      getOnlineNode(path)
        .then((response) => {
          setMedia(response.data.rows);
          setTotalItems(response.data.pager.total_items);
          setPaging(false);
          setLoading(false);
          setError(false);
        })
        .catch((_error) => {
          setError(true);
        });
    }

    // refresh on modal show
    if (props.showMediaLibrary) {
      getContent();
    }
  }, [
    props.selectedMid,
    currentPage,
    props.mediaType,
    props.slide,
    props.user.current_user.uid,
    uploadCount,
    props.showMediaLibrary,
    selectedMediaType,
    selectedLibrary,
    selectedCategory,
    search,
    widthFilter,
    heightFilter,
  ]);

  useEffect(() => {
    getOnlineNode('api/categories?vid=media_categories')
      .then((response) => {
        let categories = [
          {
            value: 0,
            label: 'All Media',
          },
        ];

        response.data.forEach((item) => {
          if (item.has_view_permission === true) {
            categories.push({
              value: item.tid,
              label: item.title,
            });
          }
        });

        setCategories(categories);
      })
      .catch((_error) => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    mediaTypes.forEach((type) => {
      if (type.label.toLowerCase() === props.mediaType) {
        setSelectedMediaType(type.value);
        setMediaTypeFilterDisabled(true);
      }
    });
  }, [props.mediaType]);

  useEffect(() => {
    async function getContent() {
      setError(false);
      setPaging(false);
      setLoading(true);

      /**
       * @TODO
       *
       * user library: my library, core library, shared
       * media category
       */

      let path = `media_api?mid=${props.selectedMid}`;

      getOnlineNode(path)
        .then((response) => {
          if (response.data?.rows?.length > 0) {
            setSelectedMedia(response.data.rows[0]);
            setCarouselIndex(1);
          }

          setPaging(false);
          setLoading(false);
          setError(false);
        })
        .catch((_error) => {
          setError(true);
        });
    }

    if (props.selectedMid && props.currentBlock?.details?.name !== 'gallery') {
      getContent();
    }
  }, [props.currentBlock?.details?.name, props.selectedMid]);

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  const onPaginate = (event, value) => {
    setLoading(true);
    setPaging(true);
    setCurrentPage(value);
  };

  const libraryReturn = () => {
    setCarouselIndex(0);
    setTimeout(() => {
      setSelectedMedia(null);
      setCropApplied(false);
    }, 250);
  };

  const RenderPagination = () => {
    // Pagination component
    if (totalItems <= pageSize) {
      return null;
    } else {
      let pagination = paginator(totalItems, currentPage, pageSize, 3);

      return (
        <MuiPagination
          className='creator-pagination d-flex justify-content-center mt-4'
          count={pagination.totalPages}
          page={pagination.currentPage}
          siblingCount={2}
          boundaryCount={2}
          showFirstButton
          showLastButton
          color='primary'
          onChange={onPaginate}
        />
      );
    }
  };

  const GalleryElement = ({ photo }) => {
    const media = photo;
    let imgMod;
    if (media.field_media_image) {
      imgMod = { ...media, src: media.field_media_image_thumbnail };
    }
    return (
      <Button
        key={`el-${media.mid}`}
        className={'gallery-element ' + media.bundle}
        onClick={() => {
          setSelectedMedia(media);
          setCarouselIndex(1);
        }}
        style={{ height: media.height, width: media.width }}
      >
        {media.field_media_image && <img {...imgMod} alt='' />}
        {media.field_media_video && (
          <>
            <VideoIcon className='video-icon'/>
            <img src={`${filesystem_root}/${subdomain}/videos/${media.mid}.jpg`} alt={`Poster for Video ${media.mid}`} />
            <div className='video-overlay-indicator'>
              <VideoIcon className='video-icon-indicator'/>
            </div>
          </>
          // <video
          //   // src={
          //   //   media.field_poster_timestamp
          //   //     ? `${media.field_media_video}#t=${media.field_poster_timestamp}`
          //   //     : `${media.field_media_video}#t=5`
          //   // }
          //   src={media.field_media_video}
          //   poster={`${filesystem_root}/${subdomain}/videos/${media.mid}.jpg`}
          //   preload='auto'
          //   muted
          // ></video>
        )}
        {media.field_media_audio && <AudioIcon className='audio-icon' />}
        <div className='element-name'>{media.name}</div>
      </Button>
    );
  };

  const RenderGallery = () => {
    let normalPhotos = media.map((el) => {
      return {
        ...el,
        src:
          el.field_media_image || el.field_media_audio || el.field_media_video,
        width: el?.properties ? el.properties[0] : 4,
        height: el?.properties ? el.properties[1] : 4,
      };
    });

    return (
      <Gallery
        photos={normalPhotos}
        renderImage={GalleryElement}
        targetRowHeight={180}
      />
    );
  };

  const onReset = () => {
    setSearch('');
    setSelectedMediaType(props.mediaType ? props.mediaType : '0');
    setSelectedLibrary('0');
    setSelectedCategory('0');
    setWidthFilter(0);
    setHeightFilter(0);
    setCurrentPage(1);
  }

  const regex = /^[0-9\b]+$/;

  return (
    <>
      <Dialog
        className={'media-library-modal'}
        fullScreen={true}
        open={
          props.showMediaLibrary !== 'undefined'
            ? props.showMediaLibrary
            : false
        }
        onClose={() => {
          props.setShowMediaLibrary(false);
          setSelectedMedia(null);
          setCarouselIndex(0);
          setCurrentPage(1);
          setShowAdd(false);
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: 'primary.light',
            color: 'solid.dark',
          }}
        >
          <Container fluid>
            <Row>
              <Col className='d-flex align-items-center'>
                <Row>
                  <Col>
                    <div className='media-library-title'>Media Library</div>
                  </Col>
                  <Col className='d-sm-block d-md-none'>
                    <Row>
                      <Col>
                        <Box
                          sx={{
                            marginLeft: 2,
                            visibility:
                              carouselIndex === 0 ? 'visible' : 'hidden',
                            whiteSpace: 'nowrap',
                            width: carouselIndex === 0 ? '' : '0',
                          }}
                        >
                          <Tooltip title={`Add new media`}>
                            <Fab
                              className='add-btn'
                              size='small'
                              color='primary'
                              aria-label='add'
                              onClick={() => setShowAdd(!showAdd)}
                            >
                              {showAdd ? <Close /> : <AddIcon />}
                            </Fab>
                          </Tooltip>
                          <Typography className='add-btn-label' variant='span'>
                            Add media
                          </Typography>
                        </Box>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className='d-sm-none d-md-block'>
                <Box
                  sx={{
                    visibility: carouselIndex === 0 ? 'visible' : 'hidden',
                  }}
                  className='file-add-container'
                >
                  <Box className='file-add-container-wrapper'>
                    <Add
                      open={true}
                      onClose={() => setShowAdd(false)}
                      user={props.user}
                      csrf_token={props.csrf_token}
                      mediaType={props.mediaType}
                      onUpload={() => {
                        setUploadCount(uploadCount + 1);
                        setShowAdd(false);
                      }}
                      minWidth={minWidth}
                      minHeight={minHeight}
                      maxWidth={maxWidth}
                      maxHeight={maxHeight}
                      maxSize={maxSize}
                      maxImageSize={maxImageSize}
                      maxAudioSize={maxAudioSize}
                      maxVideoSize={maxVideoSize}
                    />
                  </Box>
                </Box>
              </Col>
              <Col className='text-end align-self-center'>
                <Button
                  variant='outlined'
                  sx={{
                    backgroundColor: 'primary.contrastText',
                    width: '130px',
                    height: '35px',
                    textTransform: 'none',
                    fontWeight: '400',
                  }}
                  edge='start'
                  onClick={() => {
                    props.setShowMediaLibrary(false);
                    setSelectedMedia(null);
                    setCarouselIndex(0);
                    setCurrentPage(1);
                    setShowAdd(false);
                  }}
                  aria-label='close'
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </DialogTitle>
        <DialogContent>
          {showAdd && (
            <Add
              open={showAdd}
              onClose={() => setShowAdd(false)}
              user={props.user}
              csrf_token={props.csrf_token}
              mediaType={props.mediaType}
              onUpload={() => {
                setUploadCount(uploadCount + 1);
                setShowAdd(false);
              }}
              minWidth={minWidth}
              minHeight={minHeight}
              maxWidth={maxWidth}
              maxHeight={maxHeight}
              maxSize={maxSize}
              maxImageSize={maxImageSize}
              maxAudioSize={maxAudioSize}
              maxVideoSize={maxVideoSize}
            />
          )}
          <Container fluid className='media-container'>
            <div className='media-library'>
              <Carousel
                activeIndex={carouselIndex}
                controls={false}
                indicators={false}
                slide={false}
              >
                <Carousel.Item>
                  <Row className='mb-3 mt-3 w-100 filter-input-container'>
                    <Col className='filter-input' xs={12} sm={6} md={3} >
                      <FormControl
                        variant='outlined'
                        size='small'
                        sx={{ m: 1, width: '100%' }}
                      >
                        <TextField
                          fullWidth
                          size='small'
                          value={search ? search : ''}
                          label='Search'
                          onChange={(event) => {
                            setCurrentPage(1);
                            setSearch(event.target.value);
                          }}
                          InputProps={{
                            style: { fontSize: input_font_size },
                            endAdornment: search ?
                              (
                                <IconButton
                                  sx={{
                                    padding: 0
                                  }}
                                  onClick={() => {
                                    setCurrentPage(1);
                                    setSearch('');
                                  }}
                                >
                                  <Close
                                    sx={{
                                      width: '20px',
                                      height: '20px',
                                    }}
                                  />
                                </IconButton>
                              ) : (
                                <SearchOutlined
                                  sx={{
                                    width: '20px',
                                    height: '20px',
                                    marginBottom: '5px',
                                    marginRight: '8px',
                                  }}
                                />
                              )
                          }}
                          InputLabelProps={{
                            style: { fontSize: input_font_size },
                          }}
                          sx={{
                            '& label[data-shrink="true"]': {
                              color: "primary.main",
                            },
                            '& label[data-shrink="false"]': {
                              color: "rgba(0, 0, 0, 0.87)",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: "primary.light",
                              paddingRight: "10px",
                              "& fieldset": {
                                borderColor: "#c2ccd6",
                              },
                              "&:hover fieldset": {
                                borderColor: "primary.main",
                                borderWidth: "2px",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "primary.main",
                              },
                            },
                          }}
                        />
                      </FormControl>
                    </Col>
                    <Col className="filter-input" xs={12} sm={6} md={3} >
                      <FormControl
                        variant="outlined"
                        sx={{
                          m: 1,
                          width: "100%",
                          backgroundColor: "primary.light",
                        }}
                      >
                        <InputLabel
                          sx={{
                            fontSize: input_font_size,
                            color: "primary.main",
                          }}
                          id="media-type"
                        >
                          Media type
                        </InputLabel>
                        <Select
                          size="small"
                          labelId="media-type"
                          id="media-type-standard"
                          value={selectedMediaType}
                          onChange={(event) => {
                            setCurrentPage(1);
                            setSelectedMediaType(event.target.value);
                          }}
                          label="Media type"
                          disabled={mediaTypeFilterDisabled}
                          input={
                            <OutlinedInput
                              sx={{
                                fontSize: input_font_size,
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#c2ccd6",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderWidth: "2px",
                                  borderColor: "primary.main",
                                },
                              }}
                              label="Media type"
                            />
                          }
                        >
                          {mediaTypes.map((type, index) => (
                            <MenuItem
                              key={`media-type-${index}`}
                              value={type.value}
                            >
                              {type.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col className="filter-input" xs={12} sm={6} md={3} >
                      <FormControl
                        variant="outlined"
                        sx={{
                          m: 1,
                          width: "100%",
                          backgroundColor: "primary.light",
                        }}
                      >
                        <InputLabel
                          sx={{
                            fontSize: input_font_size,
                            color: "primary.main",
                          }}
                          id="library-type"
                        >
                          Library
                        </InputLabel>
                        <Select
                          size="small"
                          labelId="library-type"
                          id="library-type-standard"
                          value={selectedLibrary}
                          onChange={(event) => {
                            setCurrentPage(1);
                            setSelectedLibrary(event.target.value);
                          }}
                          label="Library"
                          input={
                            <OutlinedInput
                              sx={{
                                fontSize: input_font_size,
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#c2ccd6",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderWidth: "2px",
                                  borderColor: "primary.main",
                                },
                              }}
                              label="Library"
                            />
                          }
                        >
                          {libraryOptions.map((type, index) => (
                            <MenuItem
                              key={`library-type-${index}`}
                              value={type.value}
                            >
                              {type.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col className="filter-input" xs={12} sm={6} md={3} >
                      <FormControl
                        variant="outlined"
                        sx={{
                          m: 1,
                          width: "100%",
                          backgroundColor: "primary.light",
                        }}
                      >
                        <InputLabel
                          sx={{
                            fontSize: input_font_size,
                            color: "primary.main",
                          }}
                          id="categories"
                        >
                          Category
                        </InputLabel>
                        <Select
                          size="small"
                          labelId="categories"
                          id="categories-standard"
                          value={selectedCategory}
                          onChange={(event) => {
                            setCurrentPage(1);
                            setSelectedCategory(event.target.value);
                          }}
                          label="Category"
                          input={
                            <OutlinedInput
                              sx={{
                                fontSize: input_font_size,
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#c2ccd6",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderWidth: "2px",
                                  borderColor: "primary.main",
                                },
                              }}
                              label="Category"
                            />
                          }
                        >
                          {categories.map((category, index) => (
                            <MenuItem
                              key={`category-${index}`}
                              value={category.value}
                            >
                              {decodeHtmlEntities(category.label)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    {(selectedMediaType === 1 ||
                      props.mediaType === "image") && (
                        <>
                          <Col className="filter-input" xs={12} sm={6} md={3} >
                            <FormControl
                              variant="outlined"
                              sx={{ m: 1, width: "100%" }}
                            >
                              <TextField
                                size="small"
                                fullWidth
                                variant="outlined"
                                value={widthFilter ? widthFilter : ""}
                                label="Min width"
                                onChange={(event) => {
                                  if (
                                    event.target.value === "" ||
                                    regex.test(event.target.value)
                                  ) {
                                    setCurrentPage(1);
                                    setWidthFilter(event.target.value);
                                  }
                                }}
                                InputProps={{
                                  style: { fontSize: input_font_size },
                                  endAdornment: widthFilter ?
                                    (
                                      <IconButton
                                        sx={{
                                          padding: 0
                                        }}
                                        onClick={() => {
                                          setCurrentPage(1);
                                          setWidthFilter(0);
                                        }}
                                      >
                                        <Close
                                          sx={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      </IconButton>
                                    ) : (
                                      <SearchOutlined
                                        sx={{
                                          width: "20px",
                                          height: "20px",
                                          marginBottom: "5px",
                                          marginRight: "8px",
                                        }}
                                      />
                                    )
                                }}
                                InputLabelProps={{
                                  style: { fontSize: input_font_size },
                                }}
                                sx={{
                                  '& label[data-shrink="true"]': {
                                    color: "primary.main",
                                  },
                                  '& label[data-shrink="false"]': {
                                    color: "rgba(0, 0, 0, 0.87)",
                                  },
                                  "& .MuiInput-underline:after": {
                                    borderBottomColor: "primary.main",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor: "primary.light",
                                    paddingRight: "10px",
                                    "& fieldset": {
                                      borderColor: "#c2ccd6",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "primary.main",
                                      borderWidth: "2px",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "primary.main",
                                    },
                                  },
                                }}
                              />
                            </FormControl>
                          </Col>
                          <Col className="filter-input" xs={12} sm={6} md={3} >
                            <FormControl
                              variant="outlined"
                              sx={{ m: 1, width: "100%" }}
                            >
                              <TextField
                                size="small"
                                fullWidth
                                variant="outlined"
                                value={heightFilter ? heightFilter : ""}
                                label="Min height"
                                onChange={(event) => {
                                  if (
                                    event.target.value === "" ||
                                    regex.test(event.target.value)
                                  ) {
                                    setCurrentPage(1);
                                    setHeightFilter(event.target.value);
                                  }
                                }}
                                InputProps={{
                                  style: { fontSize: input_font_size },
                                  endAdornment: heightFilter ?
                                    (
                                      <IconButton
                                        sx={{
                                          padding: 0
                                        }}
                                        onClick={() => {
                                          setCurrentPage(1);
                                          setHeightFilter(0);
                                        }}
                                      >
                                        <Close
                                          sx={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      </IconButton>
                                    ) : (
                                      <SearchOutlined
                                        sx={{
                                          width: "20px",
                                          height: "20px",
                                          marginBottom: "5px",
                                          marginRight: "8px",
                                        }}
                                      />
                                    )
                                }}
                                InputLabelProps={{
                                  style: { fontSize: input_font_size },
                                }}
                                sx={{
                                  '& label[data-shrink="true"]': {
                                    color: "primary.main",
                                  },
                                  '& label[data-shrink="false"]': {
                                    color: "rgba(0, 0, 0, 0.87)",
                                  },
                                  "& .MuiInput-underline:after": {
                                    borderBottomColor: "primary.main",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor: "primary.light",
                                    paddingRight: "10px",
                                    "& fieldset": {
                                      borderColor: "#c2ccd6",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "primary.main",
                                      borderWidth: "2px",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "primary.main",
                                    },
                                  },
                                }}
                              />
                            </FormControl>
                          </Col>
                        </>
                      )}
                    <Col
                      className="filter-input reset d-flex align-items-center justify-content-center"
                      xs="auto"
                      sm={6}
                    >
                      <Button
                        className="d-flex justify-content-center align-items-center reset-btn"
                        variant="outlined"
                        sx={{ fontSize: input_font_size, textTransform: 'none' }}
                        onClick={() => onReset()}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                  {isLoading || isPaginating ? (
                    <Row>
                      <Col>
                        <LoadingSpinner />
                      </Col>
                    </Row>
                  ) : (
                    <Row className="media-container">
                      {isError ? (
                        <NoData activeIndex={0} />
                      ) : (
                        <>
                          {media.length > 0 ? (
                            <>
                              {RenderGallery()}
                              <RenderPagination />
                            </>
                          ) : (
                            <NoData activeIndex={0} />
                          )}
                        </>
                      )}
                    </Row>
                  )}
                </Carousel.Item>
                <Carousel.Item>
                  <SelectedMedia
                    user={props.user}
                    permissions={permissions}
                    selectedMedia={selectedMedia}
                    cropApplied={cropApplied}
                    setCropApplied={setCropApplied}
                    isNotForInsert={props.isNotForInsert}
                    onError={() => {
                      setSelectedMedia(null);
                      setCarouselIndex(0);
                    }}
                    onUpload={() => {
                      setSelectedMedia(null);
                      setCarouselIndex(0);
                      setUploadCount(uploadCount + 1);
                      setCurrentPage(1);
                    }}
                    onMediaInsert={(media) => {
                      props.setShowMediaLibrary(false);
                      props.onMediaInsert(media);
                      setSelectedMedia(null);
                      setCarouselIndex(0);
                      setCurrentPage(1);
                    }}
                    onAltUpdate={() => {
                      setUploadCount(uploadCount + 1);
                    }}
                    onCancel={() => libraryReturn()}
                    minWidth={minWidth}
                    minHeight={minHeight}
                    maxWidth={maxWidth}
                    maxHeight={maxHeight}
                    aspectRatio={aspectRatio}
                    maxSize={maxSize}
                    maxImageSize={maxImageSize}
                    maxAudioSize={maxAudioSize}
                    maxVideoSize={maxVideoSize}
                    currentBlock={props.currentBlock}
                    categories={categories}
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </Container>
        </DialogContent>
      </Dialog>
    </>
  );
}
