import React from 'react';

// Modules
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// App
import withRouter from '../../hooks/withRouter';
import { getOnlineNode } from '../../core/getNode';
import AccountSkeletonScreen from './skeleton-screens/accountSkeletonScreen';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import UserImage from '../../partials/userImage';
import { ContentCopy, WidgetsOutlined } from '@mui/icons-material';
import { Button, Snackbar, Alert, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Box, Stack, Typography, Avatar } from '@mui/material';
import { Edit } from 'react-feather';
import caLogo from '../../assets/ca-logo-pwa.png';

const version = require('../../../package.json').version;

class Account extends React.Component {
  constructor() {
    super();
    this.state = {
      // account
      isAccountLoading: true,
      isAccountError: false,
      accountErrorStatus: '',
      accountErrorMessage: '',
      user: '',
      snackOpen: false,
      addInDialogOpen: false,
    };
  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    // this.getToken();
    this.getAccount();
  }

  // getToken = () => {
  //   getToken().then((_response) => {
  //     this.setState({
  //       token: _response.data,
  //     });
  //   });
  // };

  setAccount = (_data) => {
    this.setState({
      isAccountLoading: false,
      isRefreshing: false,
      user: _data,
    });
  };

  getAccount = () => {
    if (typeof this.props.user.current_user === 'undefined') {
      this.props.navigate('/');
    } else {
      getOnlineNode('custom_user/' + this.props.user.current_user.uid)
        .then((response) => {
          this.setAccount(response.data.rows[0]);
        })
        .catch((_error) => {
          if (_error.response) {
            this.setState({
              isAccountLoading: false,
              isAccountError: true,
              accountErrorStatus: 200,
              accountErrorMessage: 'No data found.',
            });
          } else if (_error.request) {
            this.setState({
              isAccountLoading: false,
              isAccountError: true,
              accountErrorStatus: 0,
              accountErrorMessage: 'Could not make the request.',
            });
          } else {
            this.setState({
              isAccountLoading: false,
              isAccountError: true,
              accountErrorStatus: 0,
              accountErrorMessage: 'Unknown error.',
            });
          }
        });
    }
  };

  renderProfilePic = () => {
    let imageURI = undefined;

    if (this.state.user.user_picture) {
      imageURI = this.state.user.user_picture;
    }

    return (
      <Row>
        <Col className="d-flex justify-content-center align-content-center" xs={12} lg={12}>
          <UserImage
            name={`${this.state.user?.field_first_name} ${this.state.user?.field_last_name}`}
            image={imageURI}
            userBrand={'rhg'}
          />
        </Col>
      </Row>
    )
  };

  renderUserGreeting = () => {
    if (this.state.user.field_first_name) {
      return (
        <Row className={"greeting"}>
          <Col
            className="d-flex justify-content-center align-content-center"
            xs={12}
            lg={12}
          >
            <h4>
              <span className="font-weight-light">Hi, </span>
              {this.state.user.field_first_name}
            </h4>
          </Col>
        </Row>
      );
    }
  };

  renderUsername = () => {
    if (this.state.user.name) {
      return (
        <Row className={'username'}>
          <Col className="d-flex justify-content-center align-content-center" xs={12} lg={12}>
            <p style={{ marginBottom: 0 }}>Username: <strong>{this.state.user.name}</strong></p>
          </Col>
        </Row>
      );
    }
  };

  renderUserMail = () => {
    if (this.state.user.mail) {
      return (
        <div>
          <p>{this.state.user.mail}</p>
        </div>
      );
    }
  };

  renderUserRoles = () => {
    if (this.state.user.roles_target_id) {
      return (
        <Row className={'role'}>
          <Col className="d-flex justify-content-center align-content-center" xs={12} lg={12}>
            <p>Role: <strong>{this.state.user.roles_target_id}</strong></p>
          </Col>
        </Row>
      );
    }
  };


  copyToClipboard = (string) => {
    navigator.clipboard.writeText(string);
    this.setState({ snackOpen: true });
  };


  stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: this.stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  checkRole = () => {
    let rolesArr = this.state?.user?.roles_target_id.split(',');
    if (rolesArr.length > 1) {
      return false
    }
    else {
      if (rolesArr.find((el) => el === 'RHG viewer')) {
        return true
      }
      else {
        return false
      }
    }
  }

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isAccountLoading) {
      return <AccountSkeletonScreen />;
    } else {
      if (this.state.isAccountError) {
        return (
          <div>
            <h3>{this.state.accountErrorStatus}</h3>
            <p>{this.state.accountErrorMessage}</p>
          </div>
        );
      } else {
        return (
          <main className={"account screen"}>
            {this.renderProfilePic()}
            {this.renderUserGreeting()}
            {this.renderUsername()}
            {this.renderUserRoles()}

            {!this.checkRole() &&
              <>
                <Row className={"actions"}>
                  <Col
                    className="d-flex justify-content-center align-content-center"
                    xs={12}
                    lg={12}
                  >
                    <Link
                      className={"edit-profile-link d-flex align-items-center"}
                      to={"/account/edit-profile"}
                      params={{ user: this.state.user }}
                    >

                      <Button
                        variant='contained'
                        sx={{
                          textTransform: 'none'
                        }}
                      >
                        <Edit className={"icon"} />
                        {"Edit profile"}
                      </Button>
                    </Link>
                  </Col>
                </Row>
                <Row className="add-in justify-content-center">
                  <Col xs={8}>
                    <Row className='justify-content-center mb-3'>
                      <Col className='outlook-icon' xs={'auto'} >
                        <div className='add-in-container'>
                          <a title="Microsoft Corporation, Public domain, via Wikimedia Commons" href="https://commons.wikimedia.org/wiki/File:Microsoft_Office_Outlook_(2018%E2%80%93present).svg">
                            <img width="256" alt="Microsoft Office Outlook (2018–present)" src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg/512px-Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg.png" />
                          </a>
                        </div>
                      </Col>
                      <Col xs={'auto'} className='d-flex align-items-center ps-0'>
                        <h5>
                          Outlook Add-In
                        </h5>
                      </Col>
                    </Row>
                    <Row className='justify-content-center' >
                      <Col xs={'auto'}>
                        <Button
                          className='get-add-in'
                          variant='contained'
                          sx={{
                            textTransform: 'none'
                          }}
                          onClick={() => this.setState({ addInDialogOpen: true })}
                          startIcon={
                            <WidgetsOutlined />
                          }
                        >
                          Get Add-In
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            }
            <Row className='mt-5'>
              <Col className='mt-4 d-flex justify-content-center'>
                <a
                  href="https://companyapp.co.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="ca-logo-link" src={caLogo} alt="companyapp.co.uk" />
                </a>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex justify-content-center">
                  <p className='mb-0'>
                    Designed &amp; developed by{" "}
                    <a
                      href="https://companyapp.co.uk/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      companyapp.co.uk
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex justify-content-center">
                  <p className='mb-0'>App version: {version}</p>
                </div>
              </Col>
            </Row>
            <Snackbar open={this.state.snackOpen} autoHideDuration={3000} onClose={() => this.setState({ snackOpen: false })} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <Alert onClose={() => this.setState({ snackOpen: false })} severity='success' sx={{ width: '100%' }}>
                Link copied!
              </Alert>
            </Snackbar>
            {/* {this.renderAddInDialog()} */}

            <Dialog
              open={this.state.addInDialogOpen}
              onClose={() => this.setState({ addInDialogOpen: false })}
              className='ss-modal add-in-dialog'
            >
              <Box className='ss-modal-title'>
                <Container fluid>
                  <Row className='align-items-center justify-content-between'>
                    <Col xs={'auto'}>
                      <h3>
                        Outlook Add-In
                      </h3>
                    </Col>
                    <Col xs={'auto'}>
                      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
                        <Button
                          variant='outlined'
                          className='cancel'
                          sx={{
                            backgroundColor: 'primary.contrastText',
                            textTransform: 'none',
                            marginRight: '10px'
                          }}
                          onClick={() => {
                            this.setState({ addInDialogOpen: false });
                          }}>
                          Cancel
                        </Button>
                      </Stack>
                    </Col>
                  </Row>
                </Container>
              </Box>
              <DialogContent className='ps-modal-content'>
                <Container>
                  <Row className=''>
                    <Typography variant='body1'>
                      The Companyapp Outlook Add-In allows you to send published presentations directly from your Outlook email client. A short video with installation instructions, and a demonstration of how to use it, can be found on <a href='https://youtu.be/IRmGUmq-o_g' target='_blank' rel="noreferrer">this link</a>.
                      <br />
                      <br />
                      You will also need the URL below. Click the icon to copy it to your computer clipboard.
                    </Typography>
                  </Row>
                  <Row>
                    <Col>
                      <Tooltip title="Copy link" placement='bottom'>
                        <Button variant='outlined' className='add-in-url mt-4 mb-3' onClick={() => this.copyToClipboard("https://files-radisson.companyapp.net/cms-radisson/addin/manifest.xml")} startIcon={<ContentCopy />}>
                          https://files-radisson.companyapp.net/cms-radisson/addin/manifest.xml
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                </Container>
              </DialogContent>
            </Dialog>
          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  removeUserSession: () => dispatch({ type: 'REMOVE_SESSION' }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account));
