import React, { useEffect, useState } from 'react';

// Modules

// App
import MediaLibrary from '../mediaLibrary';
import BackgroundImageOpacity from './backgroundImageOpacity';
import BackgroundImageSize from './backgroundImageSize';
import BackgroundImageRepeat from './backgroundImageRepeat';
import BackgroundImagePosition from './backgroundImagePosition';

// UI components
import {Row, Col } from 'react-bootstrap';
import { Tooltip, Tabs, Tab, Button } from '@mui/material';

const DEFAULT_DIMENSIONS = {
  minWidth: 1366,
  minHeight: 768,
  maxWidth: 1920,
  maxHeight: 1080,
  aspectRatio: 16 / 9,
}

export default function BackgroundImagePicker(props) {
  const [backgroundFilter, setBackgroundFilter] = useState('all');
  const [backgroundImage, setBackgroundImage] = useState(props.slideBackgroundImage ? props.slideBackgroundImage : '');
  const [isUyoImage, setUyoImage] = useState(false);
  const [uyo, setUyo] = useState(false);
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  const [mediaType, setMediaType] = useState('image');
  const dimensions = (props?.slideLayout?.id === 'one-column-arch-image' || props?.slideLayout?.id === 'one-column-half-circle-image') ? undefined : DEFAULT_DIMENSIONS;

  const background_images = props.background_images ? props.background_images : [''];

  useEffect(() => {
    if (typeof backgroundImage === 'string'){
      setUyo(false);
      setUyoImage(false);
    } else {
      setUyo(true);
      setUyoImage(true);
    }
  }, [backgroundImage, isUyoImage, props]);

  const onImageSelected = image => {
    setBackgroundImage(image);
    props.onImageSelected(image);
  }

  const onUyoImageSelected = media => {
    setBackgroundImage(media);
    props.onImageSelected(media);
  }

  const renderFilters = () => {
    let items = [];
    items.push(
      <Tab sx={{textTransform: 'none'}} key={`flt-all`} label='All' value='all'/>
    )

    for(var key in background_images) {
      if (key === 'Old system') {
        if (props.user?.current_user?.name === 'companyapp') {
          items.push(
            <Tab sx={{textTransform: 'none'}} key={`flt-${key}`} value={key} label={key} />
          );
        }
      } else {
        items.push(
          <Tab sx={{textTransform: 'none'}} key={`flt-${key}`} value={key} label={key} />
        );
      }
    }

    return (
      <Tabs
        value={backgroundFilter}
        onChange={(event, newValue)=>{
          setBackgroundFilter(newValue)
        }}
      >{items}</Tabs>
    );
  }

  const renderImages = () => {
    let data = [];
    data.push(
      <div
        key={`image-col-no-img`}
        className={`image-item ${backgroundImage === '' ? `selected` : ''} all ${backgroundFilter !== 'all' ? 'd-none' : ''} col-xxl-2 col-xl-3 col-lg-4 col-sm-6`}>
          <div className='btn-container'>
            {props.showTooltip ? (
              <Tooltip title={`none`} placement='top'>
                <Button onClick={() => {
                  onImageSelected('');
                  setUyoImage(false);
                }}>
                  <span className='nbg'>No image</span>
                </Button>
              </Tooltip>
            ) : (
              <Button onClick={() => {
                onImageSelected('');
                setUyoImage(false);
              }}>
                <span className='nbg'>No image</span>
              </Button>
            )}
          </div>
      </div>
    );

    if (Object.keys(background_images).length > 0) {
      for(var key in background_images) {
        const itemKey = key;
        const group = background_images[itemKey];

        if (group.length > 0) {
          group.forEach((image, index) => {
            const isSelected = `${props.themeDirectory}/dist/images/backgrounds/${image}` === backgroundImage ? true : false;
            const bgGroup = itemKey;
            let buttonClass = isSelected ? 'selected' : '';
    
            data.push(
              <div
                key={`image-col-${itemKey}-${index}`}
                className={`image-item ${buttonClass} ${bgGroup} ${backgroundFilter !== 'all' && backgroundFilter !== bgGroup ? 'd-none' : ''} col-xxl-2 col-xl-3 col-lg-4 col-sm-6`}>
                <div className='btn-container'>
                  {props.showTooltip ? (
                    <Tooltip id={`tooltip-${itemKey}-${index}`} title={image} placement='top'>
                      <Button onClick={() => {
                        onImageSelected(`${props.themeDirectory}/dist/images/backgrounds/${image}`);
                      }}>
                        <img alt={`bg-${image}`} key={`${index}-image`} src={`${props.themeDirectory}/dist/images/bg-thumb/${image}`} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button onClick={() => {
                      onImageSelected(`${props.themeDirectory}/dist/images/backgrounds/${image}`);
                    }}>
                      <img alt={`bg-${image}`} key={`${index}-image`} src={`${props.themeDirectory}/dist/images/backgrounds/${image}`} />
                    </Button>
                  )}
                </div>
              </div>
            )
          });
        }
      }
    }

    return data;
  }

  const renderUyo = () => {
    if(typeof backgroundImage == 'object') {
      return(
        <div className='img-selected-container'>
          <Row>
            <Col>
              <img alt={`bg`} src={backgroundImage.field_media_image} />
            </Col>
            <Col>
              <Row className='my-3 ps-4'>
                <Col  className='property'>
                  <label>MID:</label>
                </Col>
                <Col className='value'>
                  <span>{backgroundImage.mid}</span>
                </Col>
              </Row>
              <Row className='my-3 ps-4'>
                <Col  className='property'>
                  <label>File name:</label>
                </Col>
                <Col className='value'>
                  <span>{backgroundImage.name}</span>
                </Col>
              </Row>
              <Row className='my-3 ps-4'>
                <Col  className='property'>
                  <label>Original</label>
                </Col>
                <Col className='value'>
                  <span>{backgroundImage.name}</span>
                </Col>
              </Row>
              <Row className='my-3 ps-4'>
                <Col  className='property'>
                  <label>Dimensions</label>
                </Col>
                <Col className='value'>
                  <span>{backgroundImage.properties[0]}x{backgroundImage.properties[1]}px</span>
                </Col>
              </Row>
              <Row className='my-3 ps-4'>
                <Col  className='property'>
                  <label>File size</label>
                </Col>
                <Col className='value'>
                  <span>450kb</span>
                </Col>
              </Row>
              <Row className='mt-xl-5 btn-grp ps-4'>
                <Col xs={3}>
                  <Button className='change-btn'
                    onClick={() => {
                      setShowMediaLibrary(true);
                    }}
                  >Change image</Button>
                </Col>
                <Col xs={3}>
                  <Button className='remove-btn'
                    onClick={() => {
                      setBackgroundImage('');
                      setUyoImage(false);
                      onImageSelected('');
                    }}>Remove image</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    }
  }

  const renderBgSettings = () => {
    return ( 
      <Row className='image-settings-container mt-3'>
        <Col xs={3}>
          <Row className='opacity-container justify-content-xl-start'>
            <Col xs={'12'} lg={'auto'} className='title d-flex align-items-center pe-0 '>
              <label className='setting-title'>{props.opacityTitle}</label>
            </Col>
            <Col xs={12} xxl={6} className='flex-grow-1'>
              <BackgroundImageOpacity
                // title={props.opacityTitle}
                slideBackgroundOpacity={props.slideBackgroundOpacity}
                onBackgroundImageOpacity={props.onBackgroundImageOpacity}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={3}>
          <Row className='size-container justify-content-xl-start justify-content-xxl-end'>
            <Col xs={'12'} lg={'auto'} className='title d-flex align-items-center pe-0 '>
              <label className='setting-title'>{props.sizeTitle}</label>
            </Col>
            <Col xs={12} xxl={6} className='flex-grow-1'>
              <BackgroundImageSize
                slideBackgroundSize={props.slideBackgroundSize}
                onBackgroundImageSize={props.onBackgroundImageSize}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={3} className='d-flex flex-row align-items-center'>
          <Row className='repeat-container justify-content-xl-start justify-content-xxl-end w-100'>
            <Col xs={'12'} lg={'auto'} className='title d-flex align-items-center pe-0 '>
              <label className='setting-title'>{props.repeatTitle}</label>
            </Col>
            <Col xs={12} xxl={6} className='flex-grow-1'>
              <BackgroundImageRepeat 
                slideBackgroundRepeat={props.slideBackgroundRepeat}
                onBackgroundImageRepeat={props.onBackgroundImageRepeat}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={3}>
          <Row className='position-container justify-content-xl-start justify-content-xxl-end'>
            <Col xs={'12'} lg={'auto'} className='title d-flex align-items-center pe-0 '>
              <label className='setting-title'>{props.positionTitle}</label>
            </Col>
            <Col xs={12} xxl={6} className='flex-grow-1'>
              <BackgroundImagePosition
                slideBackgroundPosition={props.slideBackgroundPosition}
                onBackgroundImagePosition={props.onBackgroundImagePosition}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  return (
    <div className='settings-wrap background-images'>
      <Row className='mb-3'>
        <Col xs='auto' className='d-flex align-items-center'>
          <h5 className='m-0 ss-subtitle'>{props.title}</h5>
        </Col>
        <Col xs='auto' className='uyo-switch d-flex align-items-center'>
          <Row>
            <Col xs='auto' className='p-0'>
              <Button className={`preset-btn ${uyo ? '' : 'active'}`} onClick={() => setUyo(false)}>
                Preset Image
              </Button>
            </Col>
            <Col xs='auto' className='p-0'>
              <Button className={`new-btn ${uyo ? 'active' : ''}`} onClick={() => setUyo(true)}>
                New Image
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='bi-tpl'>
        <Col>
          <div className={`main-wrapper ${uyo ? 'upload-own' : ''}`}>
            <Col>
            {uyo ? (
              <>
                {isUyoImage?
                  (<Row>
                    <Col>
                      <div>
                        {renderUyo()}
                      </div>
                    </Col>
                  </Row>)
                  :
                (<Row className='bi-uyo'>
                  <Col className='d-flex justify-content-center flex-column'>
                    <Row className='hint'>
                      <Col className='d-flex justify-content-center text-center'>
                        <div>To create your own background, please select<br />or upload an image from the media library</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='d-flex justify-content-center text-center'>
                        <Button 
                          variant='contained' 
                          color='primary'
                          onClick={() => {
                            setShowMediaLibrary(true);
                          }}>
                          Media library
                        </Button>
                      </Col>
                    </Row>
                    
                  </Col>
                </Row>)}
              </>
            ) : (
              <>
                <Row>
                  <Col>
                    <div className='background-ct'>
                      {renderFilters()}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='images-container'
                    // 'inner-wrapper'
                    >
                      {renderImages()}
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {(typeof backgroundImage == 'string' && backgroundImage !== '' && !uyo) &&
              renderBgSettings()
            }
            {(typeof backgroundImage == 'object' && uyo) && 
              renderBgSettings()
            }
            </Col>
          </div>
        </Col>
      </Row>

      {showMediaLibrary && (
        <MediaLibrary
          user={props.user}
          presentation={props.presentation}
          slide={props.slide}
          mediaType={mediaType}
          setMediaType={(type => {
            setMediaType(type);
          })}
          setShowMediaLibrary={setShowMediaLibrary}
          showMediaLibrary={showMediaLibrary}
          onMediaInsert={(media) => {
            if (media.bundle === 'image') {
              onUyoImageSelected(media);
            }
          }}
          files={dimensions}
          slideLayout={props.slideLayout}
          selectedMid={backgroundImage?.mid}
          currentBlock={{
            data: {
              x: backgroundImage?.x,
              y: backgroundImage?.y,
              width: backgroundImage?.width,
              height: backgroundImage?.height,
            }
          }}
        />
      )}
    </div>
  );
};
