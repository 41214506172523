import { CloseIcon, IconBlockIcon } from '../../icons/icons';

/**
 * Build styles
 */
require('./index.css').toString();

export default class Icon {

  static get toolbox() {
    return {
      icon: IconBlockIcon,
      title: 'Icon',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'cdx-media-container',
      wrapper: 'cdx-media-wrapper',
      btn: 'cdx-media-btn',
      replaceButton: 'replace-btn',
      removeButton: 'remove-btn',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      xxlIcon: 'xl-icon',
      smIcon: 'sm-icon',
      xsIcon: 'xs-icon',
      clear: 'clear',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      name: data.name || '',
      icon: data.icon || '',
      color: data.color || '#222',
    };

    this._element = this.getElement();
  }

  getElement() {
    let div;

    if (this._data.icon) {
      div = document.createElement('div');
      div.classList.add(this._CSS.wrapper);
      div.innerHTML = this._data.icon;
      div.setAttribute('data-name', this._data.name);
      div.setAttribute('data-color', this._data.color);
      div.style.color = this._data.color;
    }

    return div;
  }

  render() {
    let container = document.createElement('div');
    container.classList.add(this._CSS.container);
    let div = this._element;

    if (!this.readOnly) {
      let btn = document.createElement('button');
      let btnRemove = document.createElement('button');

      if (this._data.icon) {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xxlIcon);
        btn.innerHTML = `${Icon.toolbox.icon}`;
        btnRemove.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.clear, this._CSS.smIcon);
        btnRemove.innerHTML = `${CloseIcon}`;
      } else {
        // btn.classList.add(this._CSS.btn);
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xxlIcon);
        btn.innerHTML = `${Icon.toolbox.icon}`;
      }

      btn.onclick = () => {
        const blockIndex = this.api.blocks.getCurrentBlockIndex();
        const block = this.api.blocks.getBlockByIndex(blockIndex);
        this._settings.showIconSelector({
          details: block,
          data: this._data,
        });
      };

      btnRemove.onclick = () => {
        this._data.icon = '';
        this._data.name = '';
        this._data.color = '#222';
        this._element.remove();
        btnRemove.remove();
      }

      if (typeof div !== 'undefined') {
        container.appendChild(div);
        container.appendChild(btn);
        container.appendChild(btnRemove);
      } else {
        // hidden input hack
        // prevents empty blocks being created
        let input = document.createElement('input');
        input.setAttribute('style', 'visibility: hidden; width: 0; height: 0; padding: 0; margin: 0; border: 0; position: absolute');

        container.appendChild(input);
        container.appendChild(btn);
      }
    } else {
      if (typeof div !== 'undefined') {
        container.appendChild(div);
      }
    }

    return container;
  }

  save() {
    if (typeof this._element !== 'undefined') {
      this._data.icon = this._element.innerHTML || '';
      this._data.name = this._element.getAttribute('data-name') || '';
      this._data.color = this._element.getAttribute('data-color') || '#222';
    } else {
      this._data.icon = '';
      this._data.name = '';
      this._data.color = '#222';
    }

    return this._data;
  }
}