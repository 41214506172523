import React, { Component } from 'react';

// Modules
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

// UI components
import { BookOpen, Home, User, BarChart2 } from 'react-feather';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: {
        noAuth: {
          // media: {
          //   categoryLabel: 'MEDIA LIBRARIES',
          //   options: [
          //     {
          //       label: 'Presentations',
          //       path: '/presentations',
          //       icon: <BookOpen size={20} />,
          //       key: 'presentations',
          //     },
          //   ]
          // },
        },
        auth: {
          media: {
            categoryLabel: 'MEDIA LIBRARIES',
            options: [
              {
                label: 'Radisson4u',
                path: '/radisson4u',
                icon: <BarChart2 size={20} />,
                key: 'radisson4u',
              },
              {
                label: 'Presentations',
                path: '/presentations',
                icon: <BookOpen size={20} />,
                key: 'presentations',
              },
              
            ]
          },
        }
      },
    };
  }

  handleClick = () => {
    if (this.props.isMobile) {
      this.props.sidebarVisibility();
    }
  };

  render() {
    // const isMobile = !this.state.desktopQuery.matches;
    let showNavigation = "collapsed";
    if (this.props.showNavigation === true || this.props.showNavigation === 'true') {
      showNavigation = "expanded";
      // If mobile add class to body
      // if (!this.state.desktopQuery.matches) {
      //   document.body.classList.add('navbar-expanded');
      // }
    } else {
      // document.body.classList.remove('navbar-expanded');
    }

    const {navItems} = this.state;
    const that = this;

    const hasCreate = this.props.permissions.access_presentation_creator_mode ? true : false;
    
    return (
      <div className={`navbar-container ${showNavigation}`}>
        {this.props.isMobile && (
          <div className="mobile-overlay" onClick={this.props.sidebarVisibility}></div>
        )}
        <nav className={`navbar ${showNavigation}`}>
          {typeof this.props.user.current_user !== 'undefined' ? (
            <>
              <ul>
                <li className="nav-item">
                  <NavLink
                    to="/"
                    className="nav-link"
                    onClick={that.handleClick}
                  >
                    <div className="nav-item__icon">
                      <Home size={20} />
                    </div>
                    <span className="nav-item__label">Home</span>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={hasCreate ? 'presentations-creator' : 'presentations'}
                    className="nav-link"
                    onClick={that.handleClick}
                  >
                    <div className="nav-item__icon">
                      <BookOpen size={20} />
                    </div>
                    <span className="nav-item__label">Presentations</span>
                  </NavLink>
                </li>
              </ul>
            </>
          ) : (
            <>
              <ul>
                <li className="nav-item">
                  <NavLink
                    to="/"
                    className="nav-link"
                    onClick={that.handleClick}
                  >
                    <div className="nav-item__icon">
                      <Home size={20} />
                    </div>
                    <span className="nav-item__label">Home</span>
                  </NavLink>
                </li>
              </ul>
              <ul>
                <li className="nav-item">
                  <NavLink
                    to="/login"
                    className="nav-link"
                    onClick={that.handleClick}
                  >
                    <div className="nav-item__icon">
                      <User size={20} />
                    </div>
                    <span className="nav-item__label">Login</span>
                  </NavLink>
                </li>
              </ul>
            </>
          )}
        </nav>
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  permissions: state.permissionsReducer.permissions,
});

export default connect(mapStateToProps)(Sidebar);
