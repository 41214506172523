import { api } from '../config';
import axios from 'axios';
import { useStore } from '../hooks/useStore';

const blogApi = axios.create({
  baseURL: 'https://companyapp.co.uk/',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getOnlineNode = (endpoint, options) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const user = useStore();

  let params = {
    method: 'get',
    url: endpoint,
    headers: {
      'Cache-Control': 'no-cache',
    },
    ...options
  }

  if (user && user.csrf_token) {
    params.headers['X-CSRF-Token'] = user.csrf_token;
  }

  return api(params);
};

export const getExternal = (endpoint, method) => {
  return blogApi({
    method: method ? method : 'get',
    url: endpoint,
    withCredentials: false,
  });
};