import React, { useState, useEffect, useRef, useCallback } from 'react';

// Modules
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import moment from 'moment';
import { debounce } from 'lodash';

// App
import { serverUrl } from '../../config';
import { patchNode } from '../../core/postNode';
import CategorySelector from '../categorySelector';
import BackButton from './backButton';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Checkbox, CircularProgress, Alert, TextField, FormControl, Box, Button, Stack, Tooltip } from '@mui/material/';
import { Crop, Done, Close, Send } from '@mui/icons-material';


export default function SelectedImage(props) {
  const [isCropping, setIsCropping] = useState(false);
  const [alt, setAlt] = useState(props.selectedMedia.alt ? props.selectedMedia.alt : '');

  const width = props.selectedMedia.properties[0];
  const height = props.selectedMedia.properties[1];
  const default_crop_width_in_percentage = 100;

  const [isInteracted, setInteracted] = useState(false);

  // core
  const [coreLibrary, setCoreLibrary] = useState(props.selectedMedia.core_library === '1' ? true : false);

  // category
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState(props.selectedMedia ? { value: props.selectedMedia?.category?.tid ? props.selectedMedia?.category?.tid : 0 } : { value: 0 });

  /** default crop val */
  const [crop, setCrop] = useState(centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: default_crop_width_in_percentage,
      },
      props?.aspectRatio ? props?.aspectRatio : 1,
      width,
      height
    ),
    width,
    height
  ));

  const [croppedImageUrl, setCroppedImageUrl] = useState();
  const selectedMedia = props.selectedMedia;

  const cropRef = useRef(null);
  const cropContainer = useRef(null);
  const imageRef = useRef(null);

  const [scaleValue, setScaleValue] = useState(1);

  const [isError, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isPatching, setPatching] = useState(false);

  const handleAlt = (event) => {
    setAlt(event.target.value);
    debounceAltUpdate(event.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceAltUpdate = useCallback(
    debounce((value) => {
      setPatching(true);
      syncData(value, selectedCategory, coreLibrary);
    }, 1500, true),
    []
  );

  /** Updates image caption */
  const syncData = (alt, category, core_library) => {
    const data = {
      mid: props.selectedMedia.mid,
      alt: alt,
      category: category,
      core_library: core_library,
    };

    patchNode(
      `media_library/${props.selectedMedia.mid}`, data, props.user.csrf_token)
      .then(_response => {
        setPatching(false);
        props.onAltUpdate();
      })
      .catch(error => {
        setPatching(false);
      });
  }

  /**
   * Calculate image scale value
   * 
   * Needed to get correct min/max props
   */
  useEffect(() => {
    window.addEventListener('resize', calculateScaleValue);

    return () => window.removeEventListener('resize', calculateScaleValue)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Reset crop box on resize due to scaling
   */
  useEffect(() => {
    window.addEventListener('resize', resetCrop)

    return () => window.removeEventListener('resize', resetCrop)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Set default crop
   */
  useEffect(() => {
    /**
     * Update default crop if props exists
     */
    if (props.currentBlock && props.currentBlock?.details?.name !== 'gallery') {
      const { x, y, width, height } = props.currentBlock?.data;

      if (x && y && width && height) {
        const params = {
          x: parseFloat(x) / scaleValue,
          y: parseFloat(y) / scaleValue,
          width: parseFloat(width) / scaleValue,
          height: parseFloat(height) / scaleValue,
          unit: 'px'
        }

        setCrop(params);
      }
    }
  }, [props.currentBlock, scaleValue]);

  const calculateScaleValue = () => {
    const image = imageRef?.current;
    const scaleVal = props.selectedMedia.properties[0] / image.clientWidth

    setScaleValue(scaleVal);
  };

  const resetCrop = () => {
    const cropParams = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: default_crop_width_in_percentage,
        },
        props?.aspectRatio ? props?.aspectRatio : 1,
        width,
        height
      ),
      width,
      height
    );

    setCrop(cropParams);
  };

  const getCroppedImage = (image, crop, fileName) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
  
      let trueWidth = crop.width * scaleValue;
      let trueHeight = crop.height * scaleValue;
  
      // prevent underflow
      if (trueWidth < props.minWidth) {
        trueWidth = Math.max(trueWidth, props.minWidth);
      }
  
      if (trueHeight < props.minHeight) {
        trueHeight = Math.max(trueHeight, props.minHeight);
      }
  
      canvas.width = trueWidth;
      canvas.height = trueHeight;
      const ctx = canvas.getContext('2d');
  
      const img = new Image();
      img.crossOrigin = 'use-credentials'; // enable CORS for the image
      img.onload = function() {
        ctx.drawImage(
          img,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          trueWidth,
          trueHeight
        );
  
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
  
          blob.name = fileName;
          const croppedImageUrl = window.URL.createObjectURL(blob);
  
          resolve(croppedImageUrl);
        }, props.selectedMedia.mimetype, 1);
      };
      img.onerror = function() {
        reject(new Error('Failed to load image'));
      };
      img.src = image.src;
    });
  };

  async function cropImage(crop) {
    if (imageRef?.current && crop.width && crop.height) {
      const croppedImage = await getCroppedImage(
        imageRef.current,
        crop,
        'croppedImage.jpeg'
      );

      setCroppedImageUrl(croppedImage);
    }
  };

  const saveCropParams = (c) => {
    let params = c;

    // prevent underflow
    if (params.width * scaleValue < props.minWidth) {
      params.width = props.minWidth / scaleValue;
    }

    if (params.height * scaleValue < props.minHeight) {
      params.height = props.minHeight / scaleValue;
    }

    setCrop(params);
  };

  const finishCrop = () => {
    const x = crop.x * scaleValue;
    const y = crop.y * scaleValue;
    const width = crop.width * scaleValue;
    const height = crop.height * scaleValue;

    const data = {
      ...selectedMedia,
      mid: String(selectedMedia.mid),
      x: String(x),
      y: String(y),
      width: String(width),
      height: String(height),
      maxWidth: String(props.maxWidth),
      maxHeight: String(props.maxHeight),
      field_media_image: `${serverUrl}/api/image?mid=${selectedMedia.mid}&x=${x}&y=${y}&width=${width}&height=${height}&maxWidth=${props.maxWidth}&maxHeight=${props.maxHeight}`
    };

    props.onMediaInsert(data);
  };

  const handleCategorySelection = (tid) => {
    let catItem = props.categories.filter(item => {
      return item.value === tid
    });

    setSelectedCategoryItem(
      {
        label: catItem[0].label,
        value: catItem[0].value
      }
    );

    setPatching(true);
    setSelectedCategory(parseInt(catItem[0].value, 10));
    syncData(alt, catItem[0].value, coreLibrary);
  };

  const handleCoreLibrary = (checked) => {
    setPatching(true);
    setCoreLibrary(checked ? true : false);
    syncData(alt, selectedCategory, checked);
  };

  return (
    <>
      <Row className='mt-3 mb-3 justify-content-center'>
        <Col>
          <div className={`crop-actions ${props.isNotForInsert ? 'singular' : ''}`}>
            <BackButton onCancel={props.onCancel} />
            {isCropping ? (
              <Stack direction='row' spacing={2} textAlign='center'>
                <Button variant='outlined' startIcon={<Close />} onClick={() => setIsCropping(false)}>Cancel</Button>
                {/* <Button variant='contained' endIcon={<SendIcon />} onClick={() => uploadCrop()}>Save crop</Button> */}
                {isInteracted ? (
                  <Button variant='contained' color='secondary' endIcon={<Send />} onClick={() => finishCrop()}>Save crop</Button>
                ) : (
                  <Tooltip title="You must interact with the crop handle before you can save image.">
                    <span>
                      <Button disabled={true} variant='contained' color='secondary' endIcon={<Send />} onClick={() => finishCrop()}>Save crop</Button>
                    </span>
                  </Tooltip>
                )}
                
              </Stack>
            ) : (
              <>
                {!props.isNotForInsert && (
                  <Stack direction='row' spacing={2} textAlign='center'>
                    {props.selectedMedia.properties[0] > props.minWidth && props.selectedMedia.properties[1] > props.minHeight && (
                      <Button variant='contained' color='primary' endIcon={<Crop />} onClick={() => setIsCropping(true)}>Crop</Button>
                    )}

                    <Button variant='contained' color='secondary' endIcon={<Done />} onClick={() => {
                      if (props.selectedMedia.properties[0] < props.minWidth || props.selectedMedia.properties[1] < props.minHeight) {
                        setError(`This image is below the required dimensions.`)
                      } else if (props.selectedMedia.properties[0] > props.maxWidth || props.selectedMedia.properties[1] > props.maxHeight) {
                        setError(`This image exceeds the maximum dimensions.`);
                      } else {
                        props.onMediaInsert(props.selectedMedia);
                      }
                    }} >Select</Button>

                  </Stack>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>

      <Row className='image-selection mb-3'>
        <Col sm={12} lg={6} className={'mb-3'}>
          {isLoading && (
            <CircularProgress />
          )}
          {!props.isNotForInsert && (
            <>
              {props.minWidth !== undefined && props.minHeight !== undefined && (
                <div>
                  <Alert severity='warning'>
                    <Stack direction={'row'} spacing={1}>
                      {props.minWidth && props.minHeight && (
                        <label>{`SIZE REQUIRED (W x H): MIN ${props.minWidth} and ${props.minHeight} pixels / MAX = ${props.maxWidth} x ${props.maxHeight} pixels`}</label>
                      )}
                    </Stack>
                  </Alert>
                </div>
              )}
            </>
          )}
          <div className='crop-container' ref={cropContainer}>
            <div className='cropper'>
              <ReactCrop
                ref={cropRef}
                crop={isCropping ? crop : {
                  unit: '%',
                  width: 0,
                  height: 0,
                }}
                onChange={c => {
                  setInteracted(true);
                  saveCropParams(c);
                }}
                onComplete={(c) => cropImage(c)}
                aspect={props?.aspectRatio ? props?.aspectRatio : ''}
                minWidth={props?.minWidth / scaleValue}
                minHeight={props?.minHeight / scaleValue}
                // maxWidth={props?.maxWidth / scaleValue}
                // maxHeight={props?.maxHeight / scaleValue}
                // keepSelection={true} // If true is passed then selection can't be disabled if the user clicks outside the selection area.
                ruleOfThirds={isCropping ? true : false}
                disabled={isCropping ? false : true}
                locked={isCropping ? false : true}

                onDragEnd={(state) => {
                  // console.log('state: ', state);
                }}
              >
                <img
                  ref={imageRef}
                  src={props?.selectedMedia?.field_media_image}
                  alt={props?.selectedMedia?.alt ? props?.selectedMedia?.alt : ''}
                  onLoad={() => {
                    setLoading(false);
                    calculateScaleValue();
                  }}
                />
              </ReactCrop>
            </div>
          </div>
        </Col>
        {isCropping ? (
          <>
            {!isInteracted && (
              <Col sm={12} lg={6} className={'mb-3'}>
                <div>
                  <Alert severity='warning'>
                    <Stack direction={'row'} spacing={1}>
                      {props.minWidth && props.minHeight && (
                        <label>You must interact with the crop handle before you can save image.</label>
                      )}
                    </Stack>
                  </Alert>
                </div>
              </Col>
            )}
            {croppedImageUrl && (
              <Col sm={12} lg={6} className={'mb-3'}>
                <div>
                  <Alert severity='success'>
                    <Stack direction={'row'} spacing={1}>
                      {props.minWidth && props.minHeight && (
                        <label>{`CROP POSITION: X = ${Math.ceil(crop.x)}, Y = ${Math.ceil(crop.y)}, CROP SIZE: W = ${Math.ceil(crop.width * scaleValue)}, H = ${Math.ceil(crop.height * scaleValue)} pixels`}</label>
                      )}
                    </Stack>
                  </Alert>
                </div>
                <Row className='align-items-end h-100'>
                  <Col xs={12} className='align-self-start'>
                    <div className={`img-preview ${isCropping ? 'cropping' : ''}`}>
                      <img alt='Cropped-preview' src={croppedImageUrl} />
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </>
        ) : (
          <Col sm={12} lg={6} className={'mb-3 info-container'}>
            {isError && (
              <div>
                <Alert variant='filled' severity='error'>
                  <Stack direction={'row'} spacing={1}>
                    {props.minWidth && props.minHeight && (
                      <label>{isError}</label>
                    )}
                  </Stack>
                </Alert>
              </div>
            )}

            {!isError && !props.isNotForInsert && !isCropping && (
              <div style={{visibility: 'hidden'}}>
                <Alert severity='warning'>
                  <Stack direction={'row'} spacing={1}>
                    {props.minWidth && props.minHeight && (
                      <label>{`SIZE REQUIRED (W x H): MIN ${props.minWidth} and ${props.minHeight} pixels / MAX = ${props.maxWidth} x ${props.maxHeight} pixels`}</label>
                    )}
                  </Stack>
                </Alert>
              </div>
            )}
            <div className='wrapper'>
              {isPatching && (
                <Box className='info-loader'>
                  <CircularProgress size={20} />
                </Box>
              )}

              <div className='form-group'>
                <Row className='mb-2 align-items-center'>
                  <Col xs={4} sm={3}>
                    <label>File name</label>
                  </Col>
                  <Col>
                    <span>{props.selectedMedia.name}</span>
                  </Col>
                </Row>
                <Row className='mb-2 align-items-center'>
                  <Col xs={4} sm={3}>
                    <label>MID</label>
                  </Col>
                  <Col>
                    <span>{props.selectedMedia.mid}</span>
                  </Col>
                </Row>
                <Row className='mb-2 align-items-center'>
                  <Col xs={4} sm={3}>
                    <label>Created</label>
                  </Col>
                  <Col>
                    <span>{moment.unix(props.selectedMedia.created).format("h:mm:ssa - DD/MM/YYYY")}</span>
                  </Col>
                </Row>
                <Row className='mb-2 align-items-center'>
                  <Col xs={4} sm={3}>
                    <label>Dimensions</label>
                  </Col>
                  <Col>
                    {props.selectedMedia.properties[0] ? (
                      <span>{`${props.selectedMedia.properties[0]} x ${props.selectedMedia.properties[1]} pixels`}</span>
                    ) : (
                      <span></span>
                    )}
                  </Col>
                </Row>
                <Row className='mb-2 align-items-center'>
                  <Col xs={4} sm={3}>
                    <label>Caption</label>
                  </Col>
                  <Col style={{ position: 'relative' }}>
                    <FormControl variant='standard' sx={{ width: '100%' }}>
                      <TextField
                        fullWidth
                        readOnly={isPatching ? true : false}
                        disabled={isPatching ? true : false}
                        variant='standard'
                        defaultValue={alt ? alt : ''}
                        label='Add caption...'
                        onChange={(event) => {
                          handleAlt(event);
                        }}
                      />
                    </FormControl>
                  </Col>
                </Row>
                <Row className='mb-2 align-items-center'>
                  <Col xs={4} sm={3}>
                    <label>Category</label>
                  </Col>
                  <Col>
                    <CategorySelector
                      name='mediaCategory'
                      categories={props.categories}
                      selectedCategory={selectedCategoryItem}
                      handleCategorySelection={(tid) => {
                        handleCategorySelection(tid);
                      }}
                    />
                  </Col>
                </Row>
                {props.permissions.access_media_promote_to_library && (
                  <Row className='mb-2 align-items-center'>
                    <Col xs={4} sm={3}>
                      <label>Corporate library</label>
                    </Col>
                    <Col>
                      <Checkbox
                        sx={{
                          marginLeft: '-13px'
                        }}
                        id='core-lib-checkbox'
                        checked={coreLibrary}
                        onChange={(event) => {
                          handleCoreLibrary(event.target.checked ? true : false);
                        }}
                      />
                    </Col>
                </Row>
                )}
              </div>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};