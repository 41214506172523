import React, { useState } from 'react';

// Modules

// App

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Select, MenuItem, FormControl, Tooltip } from '@mui/material/';

export default function LogoPosition(props) {
  const [logoPosition, setLogoPosition] = useState(props.slideLogoPosition ? props.slideLogoPosition === 'default' ? 'Select Position' : props.slideLogoPosition : props.isLockup ? 'bottom' : 'bottom-right');
  const options = props.isLockup? [ 'Select Position', 'top', 'middle', 'bottom'] : [
    'Select Position',
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
  ];

  const onLogoPosition = position => {
    console.log('position', position)
    if(position === 'Select Position') {
      setLogoPosition('Select Position');
      props.onLogoPosition('default');
    } 
    else{
      setLogoPosition(position);
      props.onLogoPosition(position);
    }
  };

  return (
    <div className='background-position'>
      <h6>{props.title}</h6>
      <Row className='m-0'>
        <Col>
          {props.showTooltip ? (
            <Tooltip id={`tooltip-theme-warning`} title={`Please note, this option may be overriden via the brand theme.`} placement="top">
              
              <>
              <FormControl>
                {/* <InputLabel id="background-position-select">Position</InputLabel> */}
                <Select
                  labelId="background-position-select"
                  variant='standard'
                  size='small'
                  value={logoPosition}
                  onChange={(e => {
                    onLogoPosition(e.target.value);
                  })}
                  label='Position'
                >
                  {options.map((item, index) => (
                    <MenuItem key={`d-${index}`} value={item}>{item}</MenuItem>
                  ))}
                  
                </Select>
              </FormControl>
                </>
              </Tooltip>
          ) : (
            <>
            <FormControl>
              {/* <InputLabel id="background-position-select">Position</InputLabel> */}
              <Select
                labelId="background-position-select"
                variant='standard'
                size='small'
                value={logoPosition}
                onChange={(e => {
                  onLogoPosition(e.target.value);
                })}
                label='Position'
              >
                {options.map((item, index) => (
                  <MenuItem key={`d-${index}`} value={item}>{item}</MenuItem>
                ))}
                
              </Select>
            </FormControl>
              </>
          )}
        </Col>
      </Row>
    </div>
  );
};
