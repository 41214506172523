import React, { useEffect, useState } from 'react';

// Modules
import Reveal from 'reveal.js';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { DndContext, useDroppable, useSensors, useSensor, PointerSensor } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
// import RevealDist from 'reveal.js/dist/reveal.esm';
import Notes from 'reveal.js/plugin/notes/notes'

// App
import { filesystem_root, subdomain } from '../../config';
import { getOnlineNode, getExternal } from '../../core/getNode';
import Slide from './partials/slide/view/slide';
import RevealControls from './partials/revealControls';
import { jsonParser, runAnimation, addWidescreen, iframeClickEvents } from '../../utils/helpers';
import CompanyappLoader from '../../partials/companyappLoader';
import AudioPrompt from '../../partials/audioPrompt';

// UI components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Box, Alert } from '@mui/material';
import { debounce } from 'lodash';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

export default function ViewPresentation(props) {
  const [isLoading, setLoading] = useState(true);
  const [additionalLoader, setAdditionalLoader] = useState(true);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('An error occurred. Please try again or contact system administrator.');

  const [isInitial, setIsInitial] = useState(true);

  let [elementDimensions, setElementDimensions] = useState({ width: 130, height: 30 });
  let [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  // controls delta
  let [x, setX] = useState(window.innerWidth - (elementDimensions.width + 10));
  let [y, setY] = useState(30);

  // hooks
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  // redux
  const user = useSelector((state) => state).authReducer.user;

  const [presentation, setPresentation] = useState(null);
  const [presentationAudio, setPresentationAudio] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('');
  const [reveal, setReveal] = useState(null);
  const [indexh, setIndexH] = useState(0);
  const [showControls, setShowControls] = useState(true);
  // const [indexv, setIndexV] = useState(0);

  /** audio */
  const [playSlideAudio, setPlaySlideAudio] = useState(false);
  const [slideAudioPlaying, setSlideAudioPlaying] = useState(false);
  const [showSlideAudioControls, setShowSlideAudioControls] = useState(false);
  const [hasSlideAudio, setHasSlideAudio] = useState(false);
  const [delayHideSlideAudioControls, setDelayHideSlideAudioControls] = useState(false);
  const [playPresentationAudio, setPlayPresentationAudio] = useState(false);
  const [showPresentationAudioControls, setShowPresentationAudioControls] = useState(false);
  const [presentationAudioPlaying, setPresentationAudioPlaying] = useState(false);
  const [slideHasAudioPrompt, setSlideHasAudioPrompt] = useState(false);
  const [showAudioPrompt, setShowAudioPrompt] = useState(true);

  // droppable
  const { setNodeRef } = useDroppable({
    id: 'droppable',
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  // Toggle nav sidebar and getcontent
  useEffect(() => {
    dispatch({ type: 'TOGGLE_NAVIGATION', payload: false });
    getContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Resize listener
  useEffect(() => {
    setDimensions(getWindowDimensions()) // Necessary to make sure dimensions are set upon initial load

    function handleResize() {
      setDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Mouse move listener
  useEffect(() => {
    const handleMouseMove = debounce((event) => {
      setShowControls(true)
      hideRevealControls()
    });
    const hideRevealControls = debounce(() => {
      setShowControls(false)
    }, 5000);

    window.addEventListener('mousemove', handleMouseMove);


    return () => {
      window.removeEventListener(
        'mousemove',
        handleMouseMove
      );
    };
  }, []);

  // Audio controls
  useEffect(() => {
    if (delayHideSlideAudioControls) {
      let timer = setTimeout(() => {
        setDelayHideSlideAudioControls(false);
        setShowSlideAudioControls(false);
        setShowPresentationAudioControls(false)
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showSlideAudioControls, showPresentationAudioControls])

  // Audio
  useEffect(() => {
    if (!presentationAudioPlaying) {
      setPlayPresentationAudio(false)
    }
  }, [presentationAudioPlaying])

  const getContent = () => {
    if (params?.id) {
      // getContentFromApi();

      // temp
      if (props.preview) {
        getContentFromApi();
      } else {
        checkAuth();
      }
    } else {
      setError(true);
      setAdditionalLoader(false);
    }
  };

  const getContentFromApi = () => {
    // let path = `api/presentation/${params.id}?status=${props.preview ? 'false' : 'true'}&slide_status=true`;
    let path = `api/presentation/${params.id}?status=false&slide_status=true`;

    // temp
    const search_params = new URLSearchParams(location.search);
    const hash = search_params.get('hash');

    if (hash) {
      path += `&auHash=${hash}`;
    }
    // end temp

    getOnlineNode(path)
      .then((response) => {
        if (response.data) {
          setPresentation(response.data);

          const json = jsonParser(response.data.json);

          if (json?.presentationAudio) {
            setPresentationAudio(json.presentationAudio);
          }

          if (json?.backgroundColor) {
            setBackgroundColor(json.backgroundColor);
          }

          setError(false);
          setLoading(false);

          initReveal();
        } else {
          setError(true);
          setAdditionalLoader(false);
        }
      })
      .catch((_error) => {
        if (_error?.response?.status === 403) {
          setErrorMessage('You do not have access to view this presentation.');
        }

        setLoading(false);
        setError(true);
        setAdditionalLoader(false);
      });
  };

  const getContentFromStaticFS = () => {
    let path = `${filesystem_root}/${subdomain}/presentation_book.php?presentation_id=${params.id}`;

    const search_params = new URLSearchParams(location.search);
    const hash = search_params.get('hash');

    getExternal(path, 'get')
      .then((response) => {
        if (response.data) {
          if (!user.current_user) {
            let hasMatch = false;
            if (response.data?.access_token.length > 0) {
              response.data.access_token.forEach(access_token => {
                if (access_token.token === hash) {
                  hasMatch = true;
                }
              });
            } else {
              hasMatch = false;
            }

            // check hash
            if (hasMatch) {
              renderPresentation(response);
            } else {
              setErrorMessage('You do not have access to view this presentation.');
              setError(true);
              setLoading(false);
              setAdditionalLoader(false);
            }
          } else {
            renderPresentation(response);
          }
        } else {
          setError(true);
          setLoading(false);
          setAdditionalLoader(false);
        }
      })
      .catch((_error) => {
        setError(true);
        setLoading(false);
        setAdditionalLoader(false);
      });
  };

  const renderPresentation = (response) => {
    setPresentation(response.data);
    const json = jsonParser(response.data.json);

    if (json?.presentationAudio) {
      setPresentationAudio(json.presentationAudio);
    }

    if (json?.backgroundColor) {
      setBackgroundColor(json.backgroundColor);
    }

    setError(false);
    setLoading(false);
    initReveal();
  };

  const checkAuth = () => {
    const search_params = new URLSearchParams(location.search);
    const hash = search_params.get('hash');

    if (hash) {
      getContentFromStaticFS();
    } else {
      if (user.current_user) {
        getContentFromStaticFS();
      } else {
        setErrorMessage('You do not have access to view this presentation.');
        setLoading(false);
        setError(true);
        setAdditionalLoader(false);
      }
    }
  };

  const initReveal = (index) => {
    const search_params = new URLSearchParams(location.search);
    const hash = search_params.get('hash');

    let revealSettings = {
      width: 1366,
      height: 768,
      margin: 0,
      slideNumber: false,
      help: false,
      transition: 'slide',
      progress: false,
      hashOneBasedIndex: true,
      respondToHashChanges: true,
      hash: true,
      controls: false,
      controlsTutorial: false,
      overview: false,
      fragmentInURL: true,
      autoSlide: 0,
      viewDistance: 10,
      preloadIframes: true,
    };

    if (!hash) {
      revealSettings.plugins = [Notes];
    }

    let deck = new Reveal(revealSettings);

    deck.initialize()
      .then(() => {
        setTimeout(() => {
          setAdditionalLoader(false);
          checkAdvancedSlide(deck.getCurrentSlide());
        }, 2500);


        setReveal(deck);

        if (index) {
          deck.slide(index);
        }

        const indices = deck.getIndices();

        if (indices && indices.h) {
          setIndexH(indices.h);
        }

        deck.on('slidechanged', event => {
          setIndexH(event.indexh);
          // setIndexV(event.indexv);

          checkAdvancedSlide(deck.getCurrentSlide());

          const allIframes = document.querySelectorAll('.slides iframe');
          allIframes.forEach(iframe => {
            addWidescreen(iframe);
          });
        });

        deck.on('slidetransitionend', event => {
          const allIframes = document.querySelectorAll('.slides iframe');
          allIframes.forEach(iframe => {
            addWidescreen(iframe);
          });
        });
      })
      .catch((error) => {
        // console.log(error);
        setAdditionalLoader(false);
      });
  };

  const checkAdvancedSlide = (currentSlide) => {
    const allIframes = document.querySelectorAll('.slides iframe');
    allIframes.forEach(iframe => {
      addWidescreen(iframe);
    });

    const currentIframe = currentSlide.querySelector('iframe.advanced-slide');

    runAnimation(currentIframe);
    iframeClickEvents(currentIframe);
  };

  function handleDragEnd(ev) {
    setX(x += ev.delta.x);
    setY(y += ev.delta.y);
  };

  if (isLoading) {
    return (
      <CompanyappLoader />
    );
  } else {
    if (isError) {
      return (
        <Row className='h-100 justify-content-center align-self-center'>
          <Col>
            <Box className='d-flex h-100 justify-content-center align-items-center align-content-center' sx={{ flexDirection: 'column' }}>
              <Alert variant='filled' severity='error'>{errorMessage}</Alert>
            </Box>
          </Col>
        </Row>
      )
    } else {
      return (
        <main className='view-presentation screen'>
          <Container fluid className='ps-container' style={backgroundColor ? { backgroundColor: backgroundColor } : {}}>
            <Row className='ps-row justify-content-center align-self-center'>
              <Col className='ps-col p-0 ps-viewer' style={{position: 'relative'}}>
                <DndContext onDragEnd={handleDragEnd} sensors={sensors} modifiers={[restrictToParentElement]}>
                  {additionalLoader && (
                    <Box className='w-100 h-100' sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      zIndex: 5,
                      background: 'rgba(255, 255, 255, 1)'
                    }}>
                      <CompanyappLoader />
                    </Box>
                  )}
                      
                  <div
                    className={`reveal ${props.preview ? 'preview' : ''}`}
                    ref={setNodeRef}
                  >
                    <div className='slides'>
                      {presentation?.slides?.map((slide, index) => {
                        return (
                          <Slide
                            key={`slide-${index}`}
                            live={props.preview ? false : true}
                            presentation_id={presentation?.id}
                            themes={presentation.themes}
                            slide={slide}
                            activeTrigger={index === indexh ? true : false}
                            playSlideAudio={index === indexh ? playSlideAudio : false}
                            showSlideAudioControls={index === indexh ? showSlideAudioControls : false}
                            hideSlideAudioControls={() => {
                              if (index === indexh) {
                                setShowSlideAudioControls(false)
                              }
                            }}
                            onSlideAudioPlaying={(value) => {
                              if (!value) {
                                setPlaySlideAudio(false);
                              }
                              setSlideAudioPlaying(value);
                            }}
                            slideHasAudio={(value) => {
                              index === indexh ? setHasSlideAudio(value) : setHasSlideAudio(false)
                            }}
                            slideHasAudioPrompt={() => {
                              setSlideHasAudioPrompt(true)
                            }}
                            promptVisible={index === indexh ? showAudioPrompt : false}
                          />
                        )
                      })}
                    </div>

                    <RevealControls
                      reveal={reveal}
                      indexh={indexh}
                      presentation={presentation}
                      mode={props.preview ? 'preview' : 'view'}
                      showControls={showControls}
                      editMode={false}
                      hasSlideAudio={hasSlideAudio}
                      showSlideAudioControls={(value) => {
                        switch (value) {
                          case 0:
                            setShowSlideAudioControls(false);
                            break;
                          case 1:
                            setShowSlideAudioControls(!showSlideAudioControls);
                            break;
                          case 2:
                            setShowSlideAudioControls(true);
                            setDelayHideSlideAudioControls(true);
                            break;
                          case 3:
                            setShowSlideAudioControls(false);
                            setDelayHideSlideAudioControls(false);
                            break;
                          // deepcode ignore DuplicateCaseBody: <please specify a reason of ignoring this>
                          default:
                            setShowSlideAudioControls(false);
                            setDelayHideSlideAudioControls(false);
                            break;
                        }
                      }}
                      playSlideAudio={() => {
                        setPlaySlideAudio(!playSlideAudio)
                      }}
                      slideAudioActive={slideAudioPlaying}
                      hasPresentationAudio={presentationAudio}
                      showPresentationAudioControls={(value) => {
                        setShowPresentationAudioControls(value)
                        setDelayHideSlideAudioControls(true)
                      }}
                      playPresentationAudio={() => {
                        setPlayPresentationAudio(!playPresentationAudio)
                      }}
                      presentationAudioActive={presentationAudioPlaying}
                      styles={{
                        left: `min(${dimensions.width - elementDimensions.width}px , ${x}px)`,
                        top: `min(${dimensions.height - elementDimensions.height}px, ${y}px)`
                      }}
                      setDimensions={(size) => {
                        setElementDimensions(size);
                        if (isInitial) {
                          setX(window.innerWidth - (size.width + 30));
                          setY(size.height + 10);
                          setIsInitial(false);
                        }
                      }}
                      presentationAudioObject={{
                        audio: presentationAudio?.field_media_audio,
                        autoPlay: presentationAudio?.autoPlay,
                        playOverride: playPresentationAudio,
                        showControls: showPresentationAudioControls,
                        editMode: false
                      }}
                      presentationAudioPlaying={(value) => { setPresentationAudioPlaying(value) }}
                      hideSlideAudioControls={() => setShowPresentationAudioControls(false)}
                      promptVisible={showAudioPrompt}
                    />

                    {/* {presentationAudio?.field_media_audio &&
                      <AudioPlayer
                        audio={presentationAudio?.field_media_audio}
                        isSlideAudio={false}
                        editMode={false}
                        autoPlay={presentationAudio?.autoPlay}
                        playOverride={playPresentationAudio}
                        audioPlaying={(value) => { setPresentationAudioPlaying(value) }}
                        showControls={showPresentationAudioControls}
                        hideSlideAudioControls={() => setShowPresentationAudioControls(false)}
                      />
                    } */}

                    <AudioPrompt
                      open={(showAudioPrompt && presentationAudio) || (showAudioPrompt && slideHasAudioPrompt)}
                      close={() => {
                        setShowAudioPrompt(false);
                      }}
                      hasPresentationAudio={presentationAudio}
                      hasSlideAudio={slideHasAudioPrompt}
                    />
                  </div>
                </DndContext>

              </Col>
            </Row>
          </Container>
        </main>
      );
    }
  }
}