import { MapIcon, CloseIcon } from '../../icons/icons';

/**
 * Build styles
 */
require('./index.css').toString();

export default class Embed {

  static get toolbox() {
    return {
      icon: MapIcon,
      title: 'Embed',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'embed-container',
      wrapper: 'embed',
      newButton: 'embed-btn',
      replaceButton: 'embed-replace-btn',

      modal: 'embed-modal',
      codeEditor: 'embed-editor',
      close: 'embed-close',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      clear: 'clear',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      code: data.code || '',
    };

    this.container = document.createElement('div');
    this._element = this.getElement();
    this._modal = this.modalElement();
  }

  getElement() {
    const tag = document.createElement('div');
    tag.classList.add(this._CSS.wrapper);
    tag.innerHTML = this._data.code;

    return tag;
  }

  modalElement() {
    const modal = document.createElement('div');
    const tag = document.createElement('textarea');
    const close = document.createElement('button');
    const container = this.container
    const modalTitle = document.createElement('h6');

    modalTitle.classList.add('modal-title');
    modalTitle.innerHTML = "Paste embed code below"

    close.innerHTML = CloseIcon;
    close.classList.add(this._CSS.close);
    close.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.clear);

    close.addEventListener('click', function (event) {
      event.preventDefault();
      modal.classList.remove('show');
      container.classList.remove('show');
    });

    tag.innerHTML = this._data.code || '';
    tag.setAttribute('placeholder', 'Paste your code here');
    tag.classList.add(this._CSS.codeEditor);

    const that = this;
    tag.addEventListener('input', function (event) {
      that._element.innerHTML = event.target.value;
    });

    modal.classList.add(this._CSS.modal);
    modal.appendChild(modalTitle)
    modal.appendChild(tag);
    modal.appendChild(close);

    return modal;
  }

  showEditor() {
    this._modal.classList.add('show');
    this.container.classList.add('show');
  }

  render() {
    let tag = this._element;
    let btn = document.createElement('button');

    this.container.classList.add(this._CSS.container);

    if (this._data.code) {
      btn.classList.add(this._CSS.replaceButton);
      btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon);
      btn.innerHTML = `${Embed.toolbox.icon}`;
    } else {
      btn.classList.add(this._CSS.newButton);
      btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon);
      btn.innerHTML = `${Embed.toolbox.icon}`;
    }

    btn.onclick = () => {
      this.showEditor();
    };

    if (typeof tag !== 'undefined') {
      this.container.appendChild(tag);
    }

    this.container.appendChild(this._modal);

    this.container.appendChild(btn);
    return this.container;
  }

  save() {
    if (typeof this._element !== 'undefined') {
      this._data.code = this._element.innerHTML || '';
    } else {
      this._data.code = '';
    }

    return this._data;
  }
}