import React, { useState } from 'react';

// Modules

// App

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function BackgroundImagePosition(props) {
  const [backgroundPosition, setBackgroundPosition] = useState(props.slideBackgroundPosition ? props.slideBackgroundPosition : 'center');

  const onBackgroundImagePosition = position => {
    setBackgroundPosition(position);
    props.onBackgroundImagePosition(position);
  }

  return (
    <div className='settings-wrap background-position'>
      <h6>{props.title}</h6>
      <Row>
        <Col>
          <input
            type={'text'}
            id={'background_position'}
            placeholder={'Background position'}
            value={backgroundPosition}
            onInput={(e) => {
              onBackgroundImagePosition(e.target.value);
            }}
            className='form-control'
          />
        </Col>
      </Row>
    </div>
  );
};
