import React, { useState } from 'react';

// Modules

// App

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Select, MenuItem, FormControl, } from '@mui/material/';

export default function BackgroundImageRepeat(props) {
  const [backgroundRepeat, setBackgroundRepeat] = useState(props.slideBackgroundRepeat ? props.slideBackgroundRepeat : 'no-repeat');
  const options = [
    'repeat',
    'repeat-x',
    'repeat-y',
    'no-repeat',
    'space',
    'round',
    'initial',
    'inherit',
  ];

  const onBackgroundImageRepeat = repeat => {
    console.log("repeat", repeat)
    setBackgroundRepeat(repeat);
    props.onBackgroundImageRepeat(repeat);
  }

  return (
    <div className='background-repeat'>
      {/* <h6>{props.title}</h6> */}
      <Row>
        <Col className='d-flex flex-row w-100'>
          <FormControl
            sx={{
              width: '100%',
              fontSize: 16,
              marginTop: '-2px'
            }}>
            {/* <InputLabel id="background-repeat-select">Repeat</InputLabel> */}
            <Select
              // labelId="background-repeat-select"
              // variant='standard'
              size='small'
              value={backgroundRepeat}
              onChange={(e => { onBackgroundImageRepeat(e.target.value); })}
              // label='Repeat'
              sx={{
                fontSize: 16,
                "& *": {
                  fontSize: 16,
                  fontWeight: 300,
                }
              }}
            >
              {options.map((item, index) => (
                <MenuItem
                  sx={{
                    fontSize: 16,
                    "& *": {
                      fontSize: 16,
                      fontWeight: 300,
                    }
                  }}
                  key={`d-${index}`}
                  value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
      </Row>
    </div>
  );
};
