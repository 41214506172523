import React from 'react';

// Modules
import { connect } from 'react-redux';

// App
import withRouter from '../../hooks/withRouter';
import { serverUrl } from '../../config';
import { patchNode } from '../../core/postNode';
import { getOnlineNode } from '../../core/getNode';
import PageHeader from '../../partials/pageHeader';
import { alertMessages } from '../../partials/alertMessages';
import AlertModal from '../../partials/alertModal';
import Error from "../../partials/error";
import AccountSkeletonScreen from './skeleton-screens/accountSkeletonScreen';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CircularProgress } from '@mui/material';
import { Button, Accordion, AccordionSummary, AccordionDetails, Divider } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import CategorySelector from '../../partials/categorySelector';
import { Link } from 'react-router-dom';

class EditProfile extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      isSubmitting: false,
      user: '',

      first_name: '',
      last_name: '',
      phone_number: '',
      mail: '',
      password: '',
      newPassword: '',
      show_info: '',
      field_push_notifications: '',
      field_email_notifications: '',
      field_job_title: '',
      field_url: '',
      field_address: '',
      field_email_template: '',
      field_sita: '',
      field_cost_centre: '',
      field_region: '',
      profile_picture: null,
      isMailChanged: false,
      isPassChanged: false,

      category_email_template: [{ value: 0, label: '' }],
      category_sita: [{ value: 0, label: '' }],
      category_region: [{ value: 0, label: '' }],

      modalVisible: false,
    };


  }

  /**
   * @function componentDidMount
   * @description Default react method. Fired when component is rendered
   */
  componentDidMount() {
    this.getCategories();
  }

  getAccount = () => {
    if (typeof this.props.user.current_user === 'undefined') {
      this.props.navigate('/');
    } else {
      getOnlineNode('custom_user/' + this.props.user.current_user.uid)
        .then((response) => {
          this.setState(
            {
              user: response.data.rows[0],
            },
            function () {
              this.getContent();
            },
          );
        })
        .catch((_error) => {
          if (_error.response) {
            this.setState({
              isAccountLoading: false,
              isAccountError: true,
              accountErrorStatus: 200,
              accountErrorMessage: alertMessages.noData,
            });
            this.setError(
              true,
              _error.response.status,
              _error.response.statusText,
            );
          } else if (_error.request) {
            this.setState({
              isAccountLoading: false,
              isAccountError: true,
              accountErrorStatus: 0,
              accountErrorMessage: alertMessages.requestError.message,
            });
            //console.log('@rest request: ', _error.request);
            this.setError(true, 0, alertMessages.requestError.message);
          } else {
            this.setState({
              isAccountLoading: false,
              isAccountError: true,
              accountErrorStatus: 0,
              accountErrorMessage: alertMessages.unkownError.message,
            });
            //console.log('@rest unknown: ', _error);
            this.setError(true, 0, alertMessages.unkownError.message);
          }
        });
    }
  };

  getContent = () => {
    const { user } = this.state;

    if (user) {
      let show_info = true;
      let field_push_notifications = true;
      let field_email_notifications = true;

      if (user.field_show_info === 'false') {
        show_info = false;
      }

      if (user.field_push_notifications === 'false') {
        field_push_notifications = false;
      }

      if (user.field_email_notifications === 'false') {
        field_email_notifications = false;
      }

      let sita_code = this.state.category_sita?.find((el) => {
        if (el.value === user.field_sita) {
          return el
        }
      })


      let email_template = this.state.category_email_template?.find((el) => {
        if (el.label === user.field_email_template) {
          return el.value
        }
      })

      this.setState({
        user: user,
        isLoading: false,
        first_name: user.field_first_name,
        last_name: user.field_last_name,
        phone_number: user.phone_number,
        mail: user.mail,
        show_info: show_info,
        // password: 'herewego',
        field_push_notifications: field_push_notifications,
        field_email_notifications: field_email_notifications,
        field_job_title: user.field_job_title,
        field_address: user.field_address,
        field_url: user.field_url,
        field_email_template: email_template ? email_template.value : this.state.category_email_template[0], 
        field_cost_centre: user.field_cost_centre,
        field_sita: sita_code ? sita_code : this.state.category_sita[0],
        field_region: user.field_region,
      });
    }
  };


  getCategories = () => {
    getOnlineNode('api/categories?vid=email_templates,sita,region')
      .then((response) => {
        let email_template = [
          {
            value: 0,
            label: "Select Email signature style",
          },
        ];


        let sita = [
          {
            value: 0,
            label: 'None selected'
          },
        ];

        let region = [
          {
            value: 0,
            label: "Select Subscriber area",
          },
        ];

        response.data.forEach((item) => {
          if (item.has_view_permission === true) {
            if (item.vid === 'email_templates')
              email_template.push({
                value: item.tid,
                label: item.title,
              });

            if (item.vid === 'region')
              region.push({
                value: item.tid,
                label: item.title,
              });

            if (item.vid === 'sita')
              sita.push({
                value: item.tid,
                label: item.title,
              });
          }
        });

        this.setState(
          {
            category_email_template: email_template,
            category_sita: sita,
            category_region: region,
          }, this.getAccount()
        );
      })
      .catch((_error) => { });
  }

  setError = (_isError, _status, _message) => {
    this.setState({
      isLoading: false,
      isRefreshing: false,
      isError: _isError,
      errorStatus: _status,
      errorMessage: _message,
    });
  };

  updateUser = () => {
    const uid = this.props.user.current_user.uid;

    if (this.state.isPassChanged && this.state.newPassword.length < 5) {
      this.setState({
        modalVisible: true,
        alertType: 'error',
        alertTitle: alertMessages.editProfilePasswordTooShort.title,
        alertBody: alertMessages.editProfilePasswordTooShort.message,
        alertConfirmButton: true,
      });
      return false;
    }

    this.setState({
      isSubmitting: true,
    });

    let password = [{ existing: this.state.password }];

    if (this.state.isPassChanged && this.state.newPassword) {
      password = [
        { existing: this.state.password, value: this.state.newPassword },
      ];
    }

    const data = {
      _links: {
        type: {
          href: serverUrl + '/rest/type/user/user',
        },
      },
      uid: {
        value: uid,
      },
      field_first_name: {
        value: this.state.first_name,
      },
      field_last_name: {
        value: this.state.last_name,
      },
      phone_number: {
        value: this.state.phone_number,
      },
      mail: {
        value: this.state.mail,
      },
      pass: password,
      field_job_title: {
        value: this.state.field_job_title,
      },
      field_url: {
        value: this.state.field_url,
      },
      field_address: {
        value: this.state.field_address,
      },
      field_email_template: [{ target_id: this.state.field_email_template }],
      // field_cost_centre: {
      //   value: this.state.field_cost_centre
      // },
      // field_sita: [{ target_id: this.state.field_sita }],
      // field_region: [{ target_id: this.state.field_region }],
    };

    patchNode('user/' + uid, data, this.props.user.csrf_token)
      .then((response) => {
        this.backToAccount.click();
      })
      .catch((error) => {
        // temporary
        this.backToAccount.click();
        // console.log('@user error: ', error.response);

        // // temp simple_oauth error
        // if (error?.response?.status === 500) {
        //   this.backToAccount.click();
        // } else {
        //   if (error?.response?.data?.message) {
        //     this.setState({
        //       isSubmitting: false,
        //       modalVisible: true,
        //       alertType: 'error',
        //       alertTitle: alertMessages.unkownError.title,
        //       alertBody: error.response.data.message,
        //       alertConfirmButton: true,
        //     });
        //   } else {
        //     this.setState({
        //       isSubmitting: false,
        //       modalVisible: true,
        //       alertType: 'error',
        //       alertTitle: alertMessages.editProfileUpdateFailed.title,
        //       alertBody: alertMessages.editProfileUpdateFailed.message,
        //       alertConfirmButton: true,
        //     });
        //   }
        // }


      });
  };

  /**
   * Set modal visibility
   * @param {*} visible 
   */
  setModalVisible = (visible) => {
    this.setState({
      modalVisible: visible,
    });
  };

  passwordComponent = (note, label, placeholder) => {
    const { isMailChanged, isPassChanged } = this.state;

    if (!isMailChanged && !isPassChanged) {
      return null;
    } else {
      return (
        <div className="form-group">
          <Row>
            <Col>
              <p className="text-danger">{note}</p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <label>{label}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <input
                type="password"
                onChange={(event) => {
                  this.setState({
                    password: event.target.value,
                  });
                }}
                placeholder={placeholder}
                className="form-control"
              />
            </Col>
          </Row>
        </div>
      );
    }
  };

  errorAlerts = () => {
    return (
      <AlertModal
        showAlert={this.state.modalVisible}
        showAlertCallback={this.setModalVisible}
        alertType={this.state.alertType}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        cancelButton={this.state.alertCancelButton}
        confirmButton={this.state.alertConfirmButton}
        cancelButtonLabel={this.state.cancelButtonLabel}
        confirmButtonLabel={this.state.confirmButtonLabel}
      />
    )
  }

  saveAlerts = () => {
    return (
      <AlertModal
        showAlert={this.state.postModalVisible}
        showAlertCallback={() => {
          this.setState({
            postModalVisible: !this.state.postModalVisible,
          });
        }}
        alertType={'success'}
        alertMessageTitle={this.state.alertTitle}
        alertMessageBody={this.state.alertBody}
        cancelButton={false}
        confirmButton={true}
        confirmButtonLabel={'OK'}
        onConfirm={() => {
          this.props.navigate('/account');
        }}
      />
    );
  };

  /**
   * @function render
   * @description Default render method
   */
  render() {
    if (this.state.isLoading) {
      return <AccountSkeletonScreen />;
    } else {
      if (this.state.isError) {
        return (
          <Error
            status={this.state.errorStatus}
            message={this.state.errorMessage}
          />
        );
      } else {
        const { isMailChanged, isPassChanged } = this.state;

        let note, label, placeholder;

        if (isMailChanged && isPassChanged) {
          note =
            'Note: To change your email or current password you must confirm your existing password below';
          label = 'Current Password';
          placeholder = 'Enter your current password...';
        } else {
          if (isMailChanged) {
            note =
              'Note: To change your email address you must confirm your password below';
            label = 'Password';
            placeholder = 'Enter your password...';
          }

          if (isPassChanged) {
            note =
              'Note: To change your password you must confirm your existing password below';
            label = 'Current password';
            placeholder = 'Enter your current password...';
          }
        }

        return (
          <main ref={this.containerRef} className="edit-profile  screen">

            <Row>
              <Col xs={12} lg={6} className="mx-lg-auto">

                <PageHeader pageName="Edit Profile" filters={false} />

                {this.errorAlerts()}
                {this.saveAlerts()}

                {/* First name */}
                <div className="form-group">
                  <Row className="mt-2">
                    <Col>
                      <label>First name</label>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <input
                        type="text"
                        onChange={(event) => {
                          this.setState({
                            first_name: event.target.value,
                          });
                        }}
                        // placeholder={
                        //   this.state.user.field_first_name.length > 0
                        //     ? this.state.user.field_first_name
                        //     : "Add your first name..."
                        // }
                        placeholder={"Add your first name..."}
                        value={this.state.first_name}
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </div>

                {/* Last name */}
                <div className="form-group">
                  <Row className="mt-2">
                    <Col>
                      <label>Last name</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <input
                        type="text"
                        onChange={(event) => {
                          this.setState({
                            last_name: event.target.value,
                          });
                        }}
                        // placeholder={
                        //   this.state.user.field_last_name.length > 0
                        //     ? this.state.user.field_last_name
                        //     : "Add your last name..."
                        // }
                        placeholder={"Add your last name..."}
                        value={this.state.last_name}
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </div>

                {/* Job title */}
                <div className="form-group">
                  <Row className="mt-2">
                    <Col>
                      <label>Job title</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <input
                        type="text"
                        onChange={(event) => {
                          this.setState({
                            field_job_title: event.target.value,
                          });
                        }}
                        // placeholder={
                        //   this.state.user.field_last_name.length > 0
                        //     ? this.state.user.field_last_name
                        //     : "Add your last name..."
                        // }
                        placeholder={"Add job title..."}
                        value={this.state.field_job_title}
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </div>

                {/* Phone number */}
                <div className="form-group">
                  <Row className="mt-2">
                    <Col>
                      <label>Phone number</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <input
                        // type="text"
                        // onChange={(phone_number) => {
                        //   this.setState({
                        //     phone_number: phone_number,
                        //   });
                        // }}
                        // placeholder={
                        //   this.state.user.phone_number.length > 0
                        //     ? this.state.user.phone_number
                        //     : "Add a phone number..."
                        // }
                        type="tel"
                        onChange={(event) => {
                          this.setState({
                            phone_number: event.target.value,
                          });
                        }}
                        placeholder={"Add your phone number..."}
                        value={this.state.phone_number}
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </div>

                {/* Email */}

                {/* Email and password */}
                {
                  // this.props.user.current_user.name !== "demo" &&
                  // (
                  //   <>
                  //     {/* Email */}
                  //     <div className="form-group">
                  //       <Row className="mt-2">
                  //         <Col>
                  //           <label>Email</label>
                  //         </Col>
                  //       </Row>
                  //       <Row>
                  //         <Col>
                  //           <input
                  //             type="email"
                  //             onChange={(event) => {
                  //               let hasMailChanged = false;
                  //               let mail = event.target.value;

                  //               if (mail !== this.state.user.mail) {
                  //                 hasMailChanged = true;
                  //               }

                  //               if (mail.length < 1) {
                  //                 hasMailChanged = false;
                  //               }

                  //               this.setState({
                  //                 mail: mail,
                  //                 isMailChanged: hasMailChanged,
                  //               });
                  //             }}
                  //             // placeholder={
                  //             //   this.state.user.mail.length > 0
                  //             //     ? this.state.user.mail
                  //             //     : "Add your email..."
                  //             // }
                  //             placeholder={"Add your email..."}
                  //             value={this.state.mail}
                  //             className="form-control"
                  //           />
                  //         </Col>
                  //       </Row>
                  //     </div>

                  //     {/* New password */}
                  //     <div className="form-group">
                  //       <Row className="mt-2">
                  //         <Col>
                  //           <label>New Password</label>
                  //         </Col>
                  //       </Row>
                  //       <Row>
                  //         <Col>
                  //           <input
                  //             type="password"
                  //             onChange={(event) => {
                  //               let hasPassChanged = true;
                  //               let newPassword = event.target.value;

                  //               if (newPassword.length < 1) {
                  //                 hasPassChanged = false;
                  //               }

                  //               this.setState({
                  //                 newPassword: newPassword,
                  //                 isPassChanged: hasPassChanged,
                  //               });
                  //             }}
                  //             placeholder={"New Password..."}
                  //             className="form-control"
                  //           />
                  //         </Col>
                  //       </Row>
                  //     </div>

                  //     {/* Current password */}
                  //     {this.passwordComponent(note, label, placeholder)}
                  //   </>
                  // )
                }

                {/* Web address (URL) */}
                <div className="form-group">
                  <Row className="mt-2">
                    <Col>
                      <label>Web address (URL)</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <input
                        type="url"
                        onChange={(event) => {
                          this.setState({
                            field_url: event.target.value,
                          });
                        }}
                        placeholder={"Add your web address..."}
                        value={this.state.field_url}
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </div>

                {/* Physical address */}
                <div className="form-group">
                  <Row className="mt-2">
                    <Col>
                      <label>Physical address</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <input
                        type="url"
                        onChange={(event) => {
                          this.setState({
                            field_address: event.target.value,
                          });
                        }}
                        placeholder={"Add your physical address..."}
                        value={this.state.field_address}
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </div>


                <div className="form-group">
                  <Row className="mt-2">
                    <Col>
                      <label>
                        Email signature style
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CategorySelector
                        label="Email signature style"
                        variant="outlined"
                        size="small"
                        selectedCategory={{
                          value: this.state.field_email_template
                        }}
                        handleCategorySelection={(id) => {
                          this.setState({
                            field_email_template: id
                          })
                        }}
                        categories={this.state.category_email_template}
                      />

                      {/* <input
                        type="text"
                        disabled={true}
                        placeholder={"Add your physical address..."}
                        value={this.state.field_email_template}
                        className="form-control"
                      /> */}
                    </Col>
                  </Row>
                </div>

                {/* <div className="form-group">
                  <Row className="mt-2">
                    <Col>
                      <label>Email</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <input
                        type="email"
                        value={this.state.mail}
                        className="form-control"
                        readOnly={true}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="form-group">
                  <Row className="mt-2">
                    <Col>
                      <label>
                      Select email signature style
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CategorySelector
                        label="Email signature style"
                        variant="outlined"
                        size="small"
                        categories={[{ value: 0, label: 'Select email signature style' }]}
                      />
                    </Col>
                  </Row>
                </div> */}

                <Accordion className='ca-accordion edit-profile'>
                  <AccordionSummary
                    className='ca-accordion-summary'
                    expandIcon={<KeyboardArrowRight />}
                  >
                    <Col xs={12} className='d-flex align-items-center'>
                      Subscription information
                      <Divider className='mt-1 ms-2 flex-grow-1' />
                    </Col>
                  </AccordionSummary>
                  <AccordionDetails
                    className='ca-accordion-details'
                    sx={{
                      marginBottom: "10px",
                      paddingRight: "10%",
                      paddingLeft: "10%",
                    }}
                  >
                    <div className="form-group">
                      {/* <Row className="mt-2">
                        <Col>
                          <label>
                            Subscriber area
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <input
                            type="text"
                            value={this.state.field_region}
                            disabled={true}
                            placeholder={"Select Subscriber area"}
                            className="form-control"
                          />
                        </Col>
                      </Row> */}
                      <Row className="mt-2">
                        <Col xs={4}>
                          <label>Subscriber area :</label>
                        </Col>
                        <Col xs={8}>
                          <span className='value'>{this.state.field_region ? this.state.field_region : 'None selected'}</span>
                        </Col>
                      </Row>
                    </div>

                    {/* SITA */}
                    <div className="form-group">
                      {/* <Row className="mt-2">
                        <Col>
                          <label>SITA</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <input
                            type="text"
                            value={this.state.field_sita ? this.state.field_sita?.label : ''}
                            disabled={true}
                            placeholder={"Select SITA code"}
                            className="form-control"
                          />
                        </Col>
                      </Row> */}
                      <Row className="mt-2">
                        <Col xs={4}>
                          <label>SITA code :</label>
                        </Col>
                        <Col xs={8}>
                          <span className='value'>{this.state.field_sita ? this.state.field_sita?.label : 'None selected'}</span>
                        </Col>
                      </Row>
                    </div>

                    {/* Cost Centre */}
                    <div className="form-group">
                      {/* <Row className="mt-2">
                        <Col>
                          <label>Cost centre</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <input
                            type="text"
                            value={this.state.field_cost_centre}
                            disabled={true}
                            placeholder={"Add cost centre..."}
                            className="form-control"
                          />
                        </Col>
                      </Row> */}
                      <Row className="mt-2">
                        <Col xs={4}>
                          <label>Cost centre :</label>
                        </Col>
                        <Col xs={8}>
                          <span className='value'>{this.state.field_cost_centre ? this.state.field_cost_centre : 'None selected'}</span>
                        </Col>
                      </Row>
                    </div>

                  </AccordionDetails>
                </Accordion>

                <div className="form-group">
                  <Row className="align-items-center justify-content-between">
                    <Col>
                      <Link
                        to={"/account"}
                      >
                        <Button
                          variant='outlined'
                          sx={{
                            textTransform: 'none'
                          }}
                        >
                          Cancel
                        </Button>
                      </Link>
                    </Col>
                    <Col xs={'auto'}>
                      {this.state.isSubmitting ? (
                        <CircularProgress />
                      ) : (
                        <Button className="submit-btn" onClick={this.updateUser}
                          sx={{
                            textTransform: "none",
                            backgroundColor: "secondary.main",
                            color: "solid.light",
                            "&:hover": {
                              backgroundColor: "secondary.dark",
                            }
                          }}
                        >
                          <span>Save profile</span>
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>

              </Col>
            </Row>

            <div style={{ display: "none" }}>
              <a
                id="back-to-account"
                href="/account"
                ref={(link) => (this.backToAccount = link)}
              >
                Account
              </a>
            </div>

          </main>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default withRouter(connect(mapStateToProps)(EditProfile));
