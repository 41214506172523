import React, { Component } from 'react';

// App

// UI components
import { User } from 'react-feather';
import { Avatar } from '@mui/material';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

class UserImage extends Component {


  stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: this.stringToColor(this.props.userBrand),
      },
      children: name ? `${name.split(' ')[0][0] ? name.split(' ')[0][0] : ""}${name.split(' ')[1][0] ? name.split(' ')[1][0] : ""}` : <PersonOutlinedIcon />,
    };
  }

  stringToColor = (brand) => {
    switch (brand) {
      case 'rhg':
        return '#53565A'
      case 'blu':
        return '#18448a'
      case 'park-inn':
        return '#f5b335'
      case 'red':
        return '#E62528'
      case 'core':
        return '#559EAA'
      case 'collection':
        return '#101820'
      case 'park-plaza':
        return '#252728'
      case 'individuals':
        return '#f7b03d'
      default:
        return '#53565A'
    }
  }

  render() {
    const hasImage = this.props.image ? true : false;
    let imageUrl = this.props.image
    let style = this.props.className ? `${this.props.className} user-image` : 'user-image';

    return (
      <>
        {/* <div className={style}>
          {hasImage ? (
            <img
              src={imageUrl}
              alt="user"
            />
          ) : (
            <User />
          )}
        </div>
        {this.props.content_counter > 0 && (
          <span className="dropdown-main__indicator">
            {this.props.content_counter}
          </span>
        )} */}

        <Avatar className={style} {...this.stringAvatar(this.props.name)} src={this.props.image} />
          {/* {!this.props.name && !this.props.image && 
            <PersonOutlinedIcon
              color='primary.contrastText'
            />
          }
        </Avatar> */}

      </>
    );
  }

}

export default UserImage;