import React, { useState, useRef, useCallback } from 'react';

// Modules
import moment from 'moment';
import { debounce } from 'lodash';

// App
import { patchNode } from '../../core/postNode';
import { createPoster } from '../../core/createVideoPoster';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Alert, Slider, Stack, Checkbox } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import BackButton from './backButton';

export default function SelectedVideo(props) {
  const [isError, setError] = useState(null);
  const [sliderValue, setSliderValue] = useState(props.selectedMedia.field_poster_timestamp ? props.selectedMedia.field_poster_timestamp : 0);
  const [duration, setDuration] = useState(0);
  const [isPatching, setPatching] = useState(false);
  const video = useRef(null);

  // core
  const [coreLibrary, setCoreLibrary] = useState(props.selectedMedia.core_library === '1' ? true : false);


  const formatDuration = (value) => {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  const handleLoadedMetadata = () => {
    if (video) {
      setDuration(video.current.duration);
      // createPoster(video.current, props.selectedMedia.field_media_video, sliderValue);
    }
  }

  const handleTimestamp = (value) => {
    setSliderValue(value);
    debounceUpdate(value);
  }
  
  const handleCoreLibrary = (checked) => {
    setPatching(true);
    setCoreLibrary(checked ? true : false);
    syncData(sliderValue, checked);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdate = useCallback(
    debounce((value) => {
      setPatching(true);
      createPoster(video.current, props.selectedMedia.field_media_video, value);
      syncData(value, coreLibrary);
    }, 1500, true),
    []
  );

  /** Updates video poster timestamp */
  const syncData = (value, core_library) => {
    const data = {
      mid: props.selectedMedia.mid,
      field_poster_timestamp: value,
      core_library: core_library,
    };

    patchNode(
      `media_library/${props.selectedMedia.mid}`, data, props.user.csrf_token)
      .then(_response => {
        setPatching(false);
        // props.onTimestampUpdate();
      })
      .catch(error => {
        setPatching(false);
      });
  }

  return (
    <>
      {isError ? (
        <>
          <Row className='mt-3 mb-3 justify-content-center'>
            <Col>
              <div className={`crop-actions ${props.isNotForInsert ? 'singular' : ''}`}>
                <BackButton onCancel={props.onCancel} />
              </div>
            </Col>
          </Row>


          <Row>
            <Col>
              <Alert
                variant='filled'
                severity='error'
                onClose={() => {
                  setError(null);
                }}
              >
                <p>{isError}</p>
              </Alert>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className='mt-3 mb-3 justify-content-center'>
            <Col>
              <div className={`crop-actions ${props.isNotForInsert ? 'singular' : ''}`}>
                <BackButton onCancel={props.onCancel} />
                {!props.isNotForInsert && (
                  <Stack direction='row' spacing={2} textAlign='center'>
                    <Button variant='contained' color='secondary' endIcon={<DoneIcon />} onClick={() => {
                      props.onMediaInsert({
                        ...props.selectedMedia,
                        field_poster_timestamp: sliderValue,
                      });
                    }} >Select</Button>

                  </Stack>
                )}
              </div>
            </Col>
          </Row>

          <Row className='mt-3 mb-3 justify-content-center'>
            <Col>
              <div className='text-center'>
                <video
                  ref={video}
                  // poster={`${filesystem_root}/${subdomain}/videos/${props.selectedMedia.mid}.jpg`}
                  className='video-item'
                  controls={true}
                  crossOrigin='use-credentials'
                  src={props.selectedMedia.field_media_video}
                  onLoadedMetadata={handleLoadedMetadata}
                  onError={(e) => {
                    setError(`Failed to load video`);
                  }}>
                  Your browser does not support the video element.
                </video>
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className='form-group'>
                <Row className='mb-2 align-items-center'>
                  <Col xs={4} sm={3}>
                    <label>MID</label>
                  </Col>
                  <Col>
                    <span>{props.selectedMedia.mid}</span>
                  </Col>
                </Row>
                <Row className='mb-2 align-items-center'>
                  <Col xs={4} sm={3}>
                    <label>File name</label>
                  </Col>
                  <Col>
                    <span>{props.selectedMedia.name}</span>
                  </Col>
                </Row>
                <Row className='mb-2 align-items-center'>
                  <Col xs={4} sm={3}>
                    <label>Created</label>
                  </Col>
                  <Col>
                    <span>{moment.unix(props.selectedMedia.created).format("h:mm:ssa - DD/MM/YYYY")}</span>
                  </Col>
                </Row>
                <Row className='mb-2 align-items-center'>
                  <Col xs={4} sm={3}>
                    <label>Thumbnail timestamp</label>
                  </Col>
                  <Col>
                    <span>{formatDuration(sliderValue)}</span>
                  </Col>
                </Row>
                <Row className='mb-2 align-items-center'>
                  <Col>
                    <Slider
                      sx={{ width: '95%', left: '2%' }}
                      max={duration}
                      min={0}
                      value={sliderValue}
                      onChange={(event, value) => {
                        handleTimestamp(value);
                      }}
                      steps={1}
                      disabled={isPatching ? true : false}
                    />
                  </Col>
                </Row>
                {props.permissions.access_media_promote_to_library && (
                  <Row className='mb-2 align-items-center'>
                    <Col xs={4} sm={3}>
                      <label>Corporate library</label>
                    </Col>
                    <Col>
                      <Checkbox
                        sx={{
                          marginLeft: '-13px'
                        }}
                        id='core-lib-checkbox'
                        disabled={isPatching}
                        checked={coreLibrary}
                        onChange={(event) => {
                          handleCoreLibrary(event.target.checked ? true : false);
                        }}
                      />
                    </Col>
                </Row>
                )}
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};