import {combineReducers} from 'redux';

// Other
import { searchReducer } from './searchReducer';
import { navigationReducer } from './navReducer';
import { locationReducer } from './locationReducer';
import { headerReducer } from './headerReducer';
import { permissionsReducer } from './permissions';
import { filtersReducer } from './filtersReducer';
import { themesReducer } from './themesReducer';
import { noticeReducer } from './noticeReducer';

const defaultState = {
  user: {},
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SAVE_SESSION':
      localStorage.setItem('user', JSON.stringify(action.payload));

      return {
        ...state,
        user: action.payload,
      };

    case 'REMOVE_SESSION':
      localStorage.removeItem('user');

      return {
        ...state,
        user: {},
      };

    default:
      return state;
  }
};

export default combineReducers({
  authReducer: authReducer,
  searchReducer: searchReducer,
  navigationReducer: navigationReducer,
  locationReducer: locationReducer,
  headerReducer: headerReducer,
  permissionsReducer: permissionsReducer,
  filtersReducer: filtersReducer,
  themesReducer: themesReducer,
  noticeReducer: noticeReducer,
});
