import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';


// Modules
import { debounce } from 'lodash';

// Editor
import EditorJS from '@editorjs/editorjs';
import Image from '../../../../../editor/blocks/image/index';
import Audio from '../../../../../editor/blocks/audio/index';
import Video from '../../../../../editor/blocks/video/index';
import Gallery from '../../../../../editor/blocks/gallery/index'
import NestedList from '../../../../../editor/blocks/nested-list';
import Quote from '../../../../../editor/blocks/quote'
import Table from '../../../../../editor/blocks/table';
import Title from '../../../../../editor/blocks/title/title';
import AnimationTune from '../../../../../editor/blocks/tools/animation';
import AlignmentBlockTune from '../../../../../editor/blocks/alignment/index';
import Subtitle from '../../../../../editor/blocks/subtitle/subtitle';
import BlockHeading from '../../../../../editor/blocks/blockHeading/blockHeading';
import Body from '../../../../../editor/blocks/body';
import Contact from '../../../../../editor/blocks/contact';
import Embed from '../../../../../editor/blocks/embed';
import Map from '../../../../../editor/blocks/map';
import Rule from '../../../../../editor/blocks/horizontal-rule';
import Point from '../../../../../editor/blocks/point';
import SubscriptTool from '../../../../../editor/blocks/tools/subscript-tool'
import SuperscriptTool from '../../../../../editor/blocks/tools/superscript-tool'
import SizeTool from '../../../../../editor/blocks/tools/size-tool'
import H1 from '../../../../../editor/blocks/tools/heading-tool/h1-tool';
import H2 from '../../../../../editor/blocks/tools/heading-tool/h2-tool';
import H3 from '../../../../../editor/blocks/tools/heading-tool/h3-tool';
import H4 from '../../../../../editor/blocks/tools/heading-tool/h4-tool';
import H5 from '../../../../../editor/blocks/tools/heading-tool/h5-tool';
import H6 from '../../../../../editor/blocks/tools/heading-tool/h6-tool';
import FontColorTool from '../../../../../editor/blocks/font-color-tune';
import Hyperlink from '../../../../../editor/blocks/tools/hyperlink-tool'
import Icon from '../../../../../editor/blocks/icon';
import Client from '../../../../../editor/blocks/client';
import InlineImage from '../../../../../editor/blocks/inline-image';
import Ancillary from '../../../../../editor/blocks/ancillary';
import DimensionTune from '../../../../../editor/blocks/tools/dimension';

// App
import { filesystem_root, subdomain, proxy } from '../../../../../config';
import { patchNode } from '../../../../../core/postNode';
import { default_reveal } from '../../../../../core/defaultReveal';
import Transition from '../../../../../partials/presentations/transition';
import ColorPickerNew from '../../../../../partials/presentations/colorPickerNew';
import SlideAudioPicker from '../../../../../partials/presentations/slideAudioPicker';
import BackgroundImagePicker from '../../../../../partials/presentations/backgroundImagePicker';
import LogoPicker from '../../../../../partials/presentations/logoPicker';
import LogoPosition from '../../../../../partials/presentations/logoPosition';
import LayoutPicker from '../../../../../partials/presentations/layoutPicker';
import MediaLibrary from '../../../../../partials/mediaLibrary';
import IconSelector from '../../../../../partials/iconSelector';
import AudioPlayer from '../../../../../partials/audioPlayer';
import HelpText from '../../help/helpText';
import BespokeMedia from '../../../../../partials/presentations/bespokeMedia';
import { jsonParser, getThemeJson, getLayoutIcons, getLayoutFiles, processObject, isSafari } from '../../../../../utils/helpers';
import { backgroundImageURIGenerator } from '../../../../../core/backgroundImageURIGenerator';
import AddAudio from '../../../../../partials/presentations/addAudio';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Loader } from 'react-feather';
import {
  Box,
  Dialog,
  DialogContent,
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Select,
  MenuItem,
  Alert,
  InputLabel,
  FormGroup,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  Checkbox,
  Slider,
  Stack,
  Tooltip
} from '@mui/material';
import { WarningAmber, KeyboardArrowRight } from '@mui/icons-material';
import { ReactComponent as NounLink } from '../../../../../assets/noun-link.svg';

const HELP_TEXT_IMAGE_ROOT = `${filesystem_root}/${subdomain}/themes/presenter_global_theme/dist/images`;

export default function SlideEdit(props) {
  const user = props.user;
  const permissions = props.permissions;

  const [slideTitle, setSlideTitle] = useState(props.slide.title ? props.slide.title : '');
  const [preventDuplicate, setPreventDuplicate] = useState(props.slide.prevent_duplicate ? parseInt(props.slide.prevent_duplicate) : '');
  const editorInstance = useRef();

  const theme_json = getThemeJson(props.slide.theme.label);

  let json = jsonParser(props.slide.slide_json);
  const [editorData, setEditorData] = useState(json ? json : {});
  const [hasImage, setHasImage] = useState(false);
  const themeDirectory = `${filesystem_root}/${subdomain}/themes/${props.slide?.theme?.label}`;

  const [showSettingsModal, setSettingsModalShow] = useState(false);

  const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  const [showIconSelector, setShowIconSelector] = useState(false);

  const [currentBlock, setCurrentBlock] = useState(null);
  const [currentBlockIndex, setCurrentBlockIndex] = useState(0);
  const [contentCount, setContentCount] = useState(0);

  const [mediaType, setMediaType] = useState('all');
  const [slideAudio, setSlideAudio] = useState(false);

  const isLibrarySlide = props.slide.initial_presentation === props.presentation.id ? false : true;

  const [isCancelled, setCancelled] = useState(false);
  const [isSaving, setSaving] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isSaved, setSaved] = useState(false);
  const [isError, setError] = useState(false);
  const [iframeError, setIframeError] = useState(false);

  const initialRender = useRef(true);

  if (!json?.reveal) {
    json.reveal = default_reveal;
  }

  if (!json.reveal.slideTransition) json.reveal.slideTransition = default_reveal.slideTransition;
  if (!json.reveal.slideLogo) json.reveal.slideLogo = default_reveal.slideLogo;
  if (!json.reveal.slideLogoPosition) json.reveal.slideLogoPosition = default_reveal.slideLogoPosition;
  if (!json.reveal.slideLockup) json.reveal.slideLockup = default_reveal.slideLockup;
  if (!json.reveal.slideLockupPosition) json.reveal.slideLockupPosition = default_reveal.slideLockupPosition;
  if (!json.reveal.slideLayout) json.reveal.slideLayout = default_reveal.slideLayout;
  if (!json.reveal.slideContentPosition) json.reveal.slideContentPosition = default_reveal.slideContentPosition;
  if (!json.reveal.slideAudioData) json.reveal.slideAudioData = default_reveal.slideAudioData;
  if (!json.reveal.slideThumbnail) json.reveal.slideThumbnail = default_reveal.slideThumbnail;

  if (!json.reveal.slideBackgroundColor) json.reveal.slideBackgroundColor = default_reveal.slideBackgroundColor;
  if (!json.reveal.slideBackgroundTransition) json.reveal.slideBackgroundTransition = default_reveal.slideBackgroundTransition;
  if (!json.reveal.slideBackgroundImage) json.reveal.slideBackgroundImage = default_reveal.slideBackgroundImage;
  if (!json.reveal.slideBackgroundOpacity) json.reveal.slideBackgroundOpacity = default_reveal.slideBackgroundOpacity;
  if (!json.reveal.slideBackgroundRepeat) json.reveal.slideBackgroundRepeat = default_reveal.slideBackgroundRepeat;
  if (!json.reveal.slideBackgroundPosition) json.reveal.slideBackgroundPosition = default_reveal.slideBackgroundPosition;
  if (!json.reveal.slideBackgroundSize) json.reveal.slideBackgroundSize = default_reveal.slideBackgroundSize;

  if (!json.reveal.slideInnerBackgroundColor) json.reveal.slideInnerBackgroundColor = default_reveal.slideInnerBackgroundColor;
  if (!json.reveal.slideInnerBackgroundImage) json.reveal.slideInnerBackgroundImage = default_reveal.slideInnerBackgroundImage;
  if (!json.reveal.slideInnerBackgroundOpacity) json.reveal.slideInnerBackgroundOpacity = default_reveal.slideInnerBackgroundOpacity;
  if (!json.reveal.slideInnerBackgroundRepeat) json.reveal.slideInnerBackgroundRepeat = default_reveal.slideInnerBackgroundRepeat;
  if (!json.reveal.slideInnerBackgroundPosition) json.reveal.slideInnerBackgroundPosition = default_reveal.slideInnerBackgroundPosition;
  if (!json.reveal.slideInnerBackgroundSize) json.reveal.slideInnerBackgroundSize = default_reveal.slideInnerBackgroundSize;
  if (!json.reveal.slideAutoAnimate) json.reveal.slideAutoAnimate = default_reveal.slideAutoAnimate;
  if (!json.reveal.slideOverlayOpacity) json.reveal.slideOverlayOpacity = default_reveal.slideOverlayOpacity;
  if (!json.reveal.slideOverlayWidth) json.reveal.slideOverlayWidth = default_reveal.slideOverlayWidth;
  if (!json.reveal.slideOverlayColor) json.reveal.slideOverlayColor = default_reveal.slideOverlayColor;
  if (!json.reveal.slideOverlayRGB) json.reveal.slideOverlayRGB = default_reveal.slideOverlayRGB;
  if (!json.reveal.slideChapterMarker) json.reveal.slideChapterMarker = default_reveal.slideChapterMarker;
  if (!json.reveal.slideDataTableColumn) json.reveal.slideDataTableColumn = default_reveal.slideDataTableColumn;
  if (!json.reveal.archContentAlignment) json.reveal.archContentAlignment = default_reveal.archContentAlignment;

  const [slideTransition, setSlideTransition] = useState(json.reveal.slideTransition);
  const [slideLogo, setSlideLogo] = useState(json.reveal.slideLogo);
  const [slideLogoPosition, setSlideLogoPosition] = useState(json.reveal.slideLogoPosition);
  const [slideLockup, setSlideLockup] = useState(json.reveal.slideLockup);
  const [slideLockupPosition, setSlideLockupPosition] = useState(json.reveal.slideLockupPosition);
  const [slideLayout, setSlideLayout] = useState(json.reveal.slideLayout);
  const [slideContentPosition, setSlideContentPosition] = useState(json.reveal.slideContentPosition);
  const [slideAudioData, setSlideAudioData] = useState(json.reveal.slideAudioData);
  const [slideThumbnail, setSlideThumbnail] = useState(json.reveal.slideThumbnail);
  const [slideFiles, setSlideFiles] = useState();

  const [slideBackgroundColor, setSlideBackgroundColor] = useState(json.reveal.slideBackgroundColor);
  const [slideBackgroundTransition, setSlideBackgroundTransition] = useState(json.reveal.slideBackgroundTransition);
  const [slideBackgroundImage, setSlideBackgroundImage] = useState(json.reveal.slideBackgroundImage);
  const [slideBackgroundOpacity, setSlideBackgroundOpacity] = useState(json.reveal.slideBackgroundOpacity);
  const [slideBackgroundRepeat, setSlideBackgroundRepeat] = useState(json.reveal.slideBackgroundRepeat);
  const [slideBackgroundPosition, setSlideBackgroundPosition] = useState(json.reveal.slideBackgroundPosition);
  const [slideBackgroundSize, setSlideBackgroundSize] = useState(json.reveal.slideBackgroundSize);

  const [slideInnerBackgroundColor, setSlideInnerBackgroundColor] = useState(json.reveal.slideInnerBackgroundColor);
  const [slideInnerBackgroundImage, setSlideInnerBackgroundImage] = useState(json.reveal.slideInnerBackgroundImage);
  const [slideInnerBackgroundOpacity, setSlideInnerBackgroundOpacity] = useState(json.reveal.slideInnerBackgroundOpacity);
  const [slideInnerBackgroundRepeat, setSlideInnerBackgroundRepeat] = useState(json.reveal.slideInnerBackgroundRepeat);
  const [slideInnerBackgroundPosition, setSlideInnerBackgroundPosition] = useState(json.reveal.slideInnerBackgroundPosition);
  const [slideInnerBackgroundSize, setSlideInnerBackgroundSize] = useState(json.reveal.slideInnerBackgroundSize);
  const [slideAutoAnimate, setSlideAutoAnimate] = useState(json.reveal.slideAutoAnimate);
  const [slideOverlayOpacity, setSlideOverlayOpacity] = useState(json.reveal.slideOverlayOpacity);
  const [slideOverlayWidth, setSlideOverlayWidth] = useState(json.reveal.slideOverlayWidth);
  const [slideOverlayColor, setSlideOverlayColor] = useState(json.reveal.slideOverlayColor);
  const [slideOverlayRGB, setSlideOverlayRGB] = useState(json.reveal.slideOverlayRGB);
  const [slideChapterMarker, setSlideChapterMarker] = useState(json.reveal.slideChapterMarker ? json.reveal.slideChapterMarker : '');
  const [slideDataTableColumn, setSlideDataTableColumn] = useState(json.reveal.slideDataTableColumn);
  const [archContentAlignment, setArchContentAlignment] = useState(json.reveal.archContentAlignment);
  const [slideNotes, setSlideNotes] = useState(json.reveal.slideNotes);

  // const [helpText, setHelpText] = useState('');
  const helpText = '';

  // Used on Cancel changes
  const originalSlideTransition = json.reveal.slideTransition;
  const originalSlideLogo = json.reveal.slideLogo;
  const originalSlideLogoPosition = json.reveal.slideLogoPosition;
  const originalSlideLockup = json.reveal.slideLockup;
  const originalSlideLockupPosition = json.reveal.slideLockupPosition;
  const originalSlideLayout = json.reveal.slideLayout;
  const originalSlideContentPosition = json.reveal.slideContentPosition;
  const originalSlideAudioData = json.reveal.slideAudioData;
  const originalSlideThumbnail = json.reveal.slideThumbnail;

  const originalSlideBackgroundColor = json.reveal.slideBackgroundColor;
  const originalSlideBackgroundTransition = json.reveal.slideBackgroundTransition;
  const originalSlideBackgroundImage = json.reveal.slideBackgroundImage;
  const originalSlideBackgroundOpacity = json.reveal.slideBackgroundOpacity;
  const originalSlideBackgroundRepeat = json.reveal.slideBackgroundRepeat;
  const originalSlideBackgroundPosition = json.reveal.slideBackgroundPosition;
  const originalSlideBackgroundSize = json.reveal.slideBackgroundSize;

  const originalSlideInnerBackgroundColor = json.reveal.slideInnerBackgroundColor;
  const originalSlideInnerBackgroundImage = json.reveal.slideInnerBackgroundImage;
  const originalSlideInnerBackgroundOpacity = json.reveal.slideInnerBackgroundOpacity;
  const originalSlideInnerBackgroundRepeat = json.reveal.slideInnerBackgroundRepeat;
  const originalSlideInnerBackgroundPosition = json.reveal.slideInnerBackgroundPosition;
  const originalSlideInnerBackgroundSize = json.reveal.slideInnerBackgroundSize;
  const originalSlideAutoAnimate = json.reveal.slideAutoAnimate;
  const originalSlideOverlayOpacity = json.reveal.slideOverlayOpacity;
  const originalSlideOverlayWidth = json.reveal.slideOverlayWidth;
  const originalSlideOverlayColor = json.reveal.slideOverlayColor;
  const originalSlideOverlayRGB = json.reveal.slideOverlayRGB;
  const originalSlideChapterMarker = json.reveal.slideChapterMarker;
  const originalSlideDataTableColumn = json.reveal.slideDataTableColumn;
  const originalArchCOntentAlignment = json.reveal.archContentAlignment;
  const originalSlideNotes = json.reveal.slideNotes;

  // init editor
  useEffect(() => {
    if (!editorInstance.current && slideLayout.id !== 'advanced') {
      initEditor();
    }
    return () => {
      if (editorInstance && editorInstance.current) {
        editorInstance.current.destroy();
        editorInstance.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Revert to original state of slide settings if it isn't saved.
  useEffect(() => {
    if (
      originalSlideTransition === slideTransition ||
      originalSlideLogo === slideLogo ||
      originalSlideLogoPosition === slideLogoPosition ||
      originalSlideLockup === slideLockup ||
      originalSlideLockupPosition === slideLockupPosition ||
      originalSlideLayout === slideLayout ||
      originalSlideContentPosition === slideContentPosition ||
      originalSlideAudioData === slideAudioData ||
      originalSlideThumbnail === slideThumbnail ||
      originalSlideBackgroundColor === slideBackgroundColor ||
      originalSlideBackgroundTransition === slideBackgroundTransition ||
      originalSlideBackgroundImage === slideBackgroundImage ||
      originalSlideBackgroundOpacity === slideBackgroundOpacity ||
      originalSlideBackgroundRepeat === slideBackgroundRepeat ||
      originalSlideBackgroundPosition === slideBackgroundPosition ||
      originalSlideBackgroundSize === slideBackgroundSize ||
      originalSlideInnerBackgroundColor === slideInnerBackgroundColor ||
      originalSlideInnerBackgroundImage === slideInnerBackgroundImage ||
      originalSlideInnerBackgroundOpacity === slideInnerBackgroundOpacity ||
      originalSlideInnerBackgroundRepeat === slideInnerBackgroundRepeat ||
      originalSlideInnerBackgroundPosition === slideInnerBackgroundPosition ||
      originalSlideInnerBackgroundSize === slideInnerBackgroundSize ||
      originalSlideAutoAnimate === slideAutoAnimate ||
      originalSlideOverlayOpacity === slideOverlayOpacity ||
      originalSlideOverlayWidth === slideOverlayWidth ||
      originalSlideOverlayColor === slideOverlayColor ||
      originalSlideOverlayRGB === slideOverlayRGB ||
      originalSlideChapterMarker === slideChapterMarker ||
      originalSlideDataTableColumn === slideDataTableColumn ||
      originalArchCOntentAlignment === archContentAlignment ||
      originalSlideNotes === slideNotes
    ) {
      if (isCancelled) {
        setCancelled(false);
        props.onRevealUpdate();
      }
    }
  },
    [
      slideBackgroundColor,
      slideTransition,
      slideBackgroundTransition,
      props,
      originalSlideBackgroundColor,
      originalSlideTransition,
      originalSlideBackgroundTransition,
      isCancelled,
      originalSlideBackgroundImage,
      slideBackgroundImage,
      slideBackgroundOpacity,
      originalSlideBackgroundOpacity,
      originalSlideBackgroundRepeat,
      slideBackgroundRepeat,
      originalSlideBackgroundPosition,
      slideBackgroundPosition,
      originalSlideBackgroundSize,
      slideBackgroundSize,
      originalSlideLogo,
      slideLogo,
      originalSlideLogoPosition,
      slideLogoPosition,
      originalSlideLockup,
      slideLockup,
      originalSlideLockupPosition,
      slideLockupPosition,
      slideLayout,
      originalSlideLayout,
      originalSlideInnerBackgroundColor,
      slideInnerBackgroundColor,
      originalSlideInnerBackgroundImage,
      slideInnerBackgroundImage,
      originalSlideInnerBackgroundOpacity,
      slideInnerBackgroundOpacity,
      originalSlideInnerBackgroundRepeat,
      slideInnerBackgroundRepeat,
      originalSlideInnerBackgroundPosition,
      slideInnerBackgroundPosition,
      originalSlideInnerBackgroundSize,
      slideInnerBackgroundSize, originalSlideAudioData,
      slideAudioData,
      originalSlideContentPosition,
      slideContentPosition,
      slideAutoAnimate,
      originalSlideAutoAnimate,
      originalSlideOverlayOpacity,
      slideOverlayOpacity,
      originalSlideOverlayWidth,
      slideOverlayWidth,
      originalSlideOverlayColor,
      slideOverlayColor,
      slideOverlayRGB,
      originalSlideOverlayRGB,
      originalSlideChapterMarker,
      slideChapterMarker,
      originalSlideThumbnail,
      slideThumbnail,
      originalSlideDataTableColumn,
      slideDataTableColumn,
      originalArchCOntentAlignment,
      archContentAlignment,
      originalSlideNotes,
      slideNotes
    ]
  );

  // Show logo settings
  useEffect(() => {
    if (props.showLogoSettings) {
      setSettingsModalShow(true);
    }
  }, [props.showLogoSettings]);

  // Show background settings
  useEffect(() => {
    if (props.showBackgroundSettings) {
      setSettingsModalShow(true);
    }
  }, [props.showBackgroundSettings]);

  // Show transition settings
  useEffect(() => {
    if (props.showTransitionSettings) {
      setSettingsModalShow(true);
    }
  }, [props.showTransitionSettings]);

  // Show slide settings
  useEffect(() => {
    if (props.showSlideSettings) {
      setSettingsModalShow(true);
    }
  }, [props.showSlideSettings]);

  // Show settings modal on help dialog
  useEffect(() => {
    if (props.showHelpDialog) {
      setSettingsModalShow(true);
    }
  }, [props.showHelpDialog])

  // Show settings modal on slide nodes
  useEffect(() => {
    if (props.showSlideNotes) {
      setSettingsModalShow(true);
    }
  }, [props.showSlideNotes])

  // Show audio settings
  useEffect(() => {
    setMediaType('audio');
    setShowMediaLibrary(slideAudio);
  }, [slideAudio]);

  // Update slide audio
  useEffect(() => {
    if (props.activeTrigger) {
      props.slideHasAudio(slideAudioData ? true : false)
    }
  }, [props.activeTrigger, slideAudioData]);

  // Save current block index when launching media library
  useEffect(() => {
    if (editorInstance.current) {
      setCurrentBlockIndex(editorInstance.current.blocks.getCurrentBlockIndex());
    }
  }, [showMediaLibrary])

  let verticalOverlayContent = document.querySelector(`#slide-${props.slide.id}-editor .codex-editor`);

  // vertical overlay style changes
  useEffect(() => {
    if (slideLayout.id === 'image-with-vertical-overlay' && verticalOverlayContent) {
      verticalOverlayContent.style.width = `${slideOverlayWidth}%`
    }
  }, [
    slideOverlayWidth,
    verticalOverlayContent,
    slideLayout.id,
  ]);

  // Initialize editor.js
  const initEditor = () => {
    if (editorInstance.current) {
      editorInstance.current.destroy();
    }

    /**
     * Editorjs configuration for specific layouts
     * 
     * each layout will have different blocks enabled, different tunes and inline tunes
     */

    let tools = {};
    let inlineTools = {};
    let default_block = 'body';
    let minHeight = 0;

    const slide_layout = slideLayout.id;

    switch (slide_layout) {
      case 'biography':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          contact: {
            class: Contact,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          // ancillary: {
          //   class: Ancillary,
          //   tunes: ['Animation', 'FontColorTool'],
          //   config: {}
          // },
        };
        default_block = 'title';
        break;

      case 'clients':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          client: {
            class: Client,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          }
        };
        default_block = 'title';
        break;

      case 'contact-us':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          contact: {
            class: Contact,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          }
        };
        default_block = 'title';
        break;

      case 'data-table':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          table: {
            class: Table,
            tunes: ['Animation'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
            config: {
              rows: 2,
              cols: 2,
            },
          }
        };
        default_block = 'title';
        break;

      case 'embed':
        tools = {
          embed: {
            class: Embed,
          },
        };
        default_block = 'embed';
        break;

      case 'four-column':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          image: {
            class: Image,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
        };
        default_block = 'title';
        break;

      case 'four-column-icons':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          blockHeading: {
            class: BlockHeading,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          icon: {
            class: Icon,
            tunes: ['Animation'],
            config: {
              showIconSelector: (block) => {
                setCurrentBlock(block);
                setShowIconSelector(true);
              },
            }
          },
        };
        default_block = 'title';
        break;

      case 'fullscreen-image':
        tools = {
          subtitle: {
            class: Subtitle,
            tunes: ['Animation'],
          },
          image: {
            class: Image,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
        };
        default_block = 'image';
        break;

      case 'fullscreen-image-gallery':
        tools = {
          gallery: {
            class: Gallery,
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
        };
        default_block = 'gallery';
        break;

      case 'fullscreen-video':
        tools = {
          video: {
            class: Video,
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
        };
        default_block = 'video';
        minHeight = 0;

        break;

      case 'healthcare-meeting-types':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          table: {
            class: Table,
            tunes: ['Animation'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
            config: {
              rows: 2,
              cols: 2,
              healthcare: true,
            },
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
        };
        default_block = 'title';

        break;

      case 'horizontal-three-point':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          point: {
            class: Point,
            tunes: ['Animation', 'FontColorTool'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
        }
        default_block = 'title';

        break;

      case 'image-gallery':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          gallery: {
            class: Gallery,
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
        };
        default_block = 'title';

        break;

      case 'header-and-image':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          image: {
            class: Image,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          video: {
            class: Video,
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
        };
        default_block = 'title';

        break;

      case 'header-and-video':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
        };
        default_block = 'title';

        break;

      case 'image-with-vertical-overlay':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
        };
        default_block = 'title';

        break;

      case 'image-with-horizontal-overlay':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
        };
        default_block = 'title';

        break;

      case 'location-map':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          map: {
            class: Map,
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              },
              googleApiKey: theme_json?.googleApiKey,
              themeDirectory: themeDirectory,
              pin: theme_json?.icons?.pin
            }
          },
        };
        default_block = 'title';

        break;

      case 'one-column':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          image: {
            class: Image,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          video: {
            class: Video,
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          // ancillary: {
          //   class: Ancillary,
          //   tunes: ['Animation', 'FontColorTool'],
          //   config: {}
          // },
        };
        default_block = 'title';

        break;

      case 'one-column-arch-image':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          inlineImage: {
            class: InlineImage,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setSlideFiles({
                  minWidth: 100,
                  minHeight: 100,
                  maxWidth: 540,
                  maxHeight: 540,
                  aspectRatio: null
                })
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
        }
        default_block = 'title';

        break;

      case 'one-column-bullets':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
        };
        default_block = 'title';

        break;

      case 'one-column-centred-bullets':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
        };
        default_block = 'title';

        break;

      case 'one-column-four-images':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          image: {
            class: Image,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                let files = getLayoutFiles(slideLayout.id, props.slide.theme.label);

                // there are different min/max dimensions for each image on this layout
                // we need to know the index of the image block to be updated so we can define the correct dimensions for the media library
                const blocksCount = editorInstance.current.blocks.getBlocksCount();
                let imageBlocks = [];

                for (var i = 0; i < blocksCount; i++) {
                  const block = editorInstance.current.blocks.getBlockByIndex(i);
                  if (block.name === 'image') {
                    imageBlocks.push(block);
                  }
                }

                for (var j = 0; j < imageBlocks.length; j++) {
                  if (imageBlocks[j].id === block.details.id) {
                    if (j === 0) {
                      files = files[0];
                    } else {
                      files = files[1];
                    }
                  }
                }

                setSlideFiles(files);
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          inlineImage: {
            class: InlineImage,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setSlideFiles({
                  minWidth: 100,
                  minHeight: 100,
                  maxWidth: 540,
                  maxHeight: 540,
                  aspectRatio: null
                })
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
        };
        default_block = 'title';

        break;

      case 'one-column-full-image-bullets':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          image: {
            class: Image,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          // ancillary: {
          //   class: Ancillary,
          //   tunes: ['Animation', 'FontColorTool'],
          //   config: {}
          // },
        };
        default_block = 'title';

        break;

      case 'one-column-full-height-image':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          image: {
            class: Image,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          inlineImage: {
            class: InlineImage,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setSlideFiles({
                  minWidth: 100,
                  minHeight: 100,
                  maxWidth: 540,
                  maxHeight: 540,
                  aspectRatio: null
                })
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          // ancillary: {
          //   class: Ancillary,
          //   tunes: ['Animation', 'FontColorTool'],
          //   config: {}
          // },
        };

        default_block = 'title';

        break;

      case 'one-column-half-circle-image':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          inlineImage: {
            class: InlineImage,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setSlideFiles({
                  minWidth: 100,
                  minHeight: 100,
                  maxWidth: 540,
                  maxHeight: 540,
                  aspectRatio: null
                })
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
        }
        default_block = 'title';

        break;

      case 'one-column-image':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          image: {
            class: Image,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          inlineImage: {
            class: InlineImage,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setSlideFiles({
                  minWidth: 100,
                  minHeight: 100,
                  maxWidth: 540,
                  maxHeight: 540,
                  aspectRatio: null
                })
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          // ancillary: {
          //   class: Ancillary,
          //   tunes: ['Animation', 'FontColorTool'],
          //   config: {}
          // },
        };
        default_block = 'title';

        break;

      case 'one-column-image-bullets':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          image: {
            class: Image,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          // ancillary: {
          //   class: Ancillary,
          //   tunes: ['Animation', 'FontColorTool'],
          //   config: {}
          // },
        };
        default_block = 'title';

        break;

      case 'one-row-image':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          image: {
            class: Image,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
        };
        default_block = 'title';

        break;

      case 'presentation-title':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
        };
        default_block = 'title'

        break;

      case 'presentation-title-image':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          inlineImage: {
            class: InlineImage,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setSlideFiles({
                  minWidth: 100,
                  minHeight: 100,
                  maxWidth: 300,
                  maxHeight: 300,
                  aspectRatio: null
                })
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
        };
        default_block = 'title'

        break;

      case 'quote':
        tools = {
          quote: {
            class: Quote,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          }
        };
        default_block = 'quote';

        break;

      case 'section-title':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
        };
        default_block = 'title'

        break;

      case 'section-title-arch-image':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
        };
        default_block = 'title'

        break;

      case 'small-print':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
        };
        default_block = 'title';

        break;

      case 'statement':
        tools = {
          quote: {
            class: Quote,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          }
        };
        default_block = 'quote';

        break;

      case 'statistic':
        tools = {
          quote: {
            class: Quote,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          }
        };
        default_block = 'quote';

        break;

      case 'table-of-contents':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          table: {
            class: Table,
            tunes: ['Animation'],
            inlineToolbar: ['bold', 'italic', 'Subscript', 'Superscript'],
            config: {
              rows: 2,
              cols: 2,
            },
          }
        };
        default_block = 'title';

        break;

      case 'thank-you':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
        };
        default_block = 'title';

        break;

      case 'three-column':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          image: {
            class: Image,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
        };
        default_block = 'title';

        break;

      case 'three-column-image':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          image: {
            class: Image,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
        };
        default_block = 'title';

        break;

      case 'three-usp':
        tools = {
          point: {
            class: Point,
            tunes: ['Animation', 'FontColorTool'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              },
              imageReadOnly: true,
            }
          },
        }
        default_block = 'point';

        break;

      case 'two-column':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          image: {
            class: Image,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          // ancillary: {
          //   class: Ancillary,
          //   tunes: ['Animation', 'FontColorTool'],
          //   config: {}
          // },
        };
        default_block = 'title';

        break;

      case 'two-column-bullets':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
        };
        default_block = 'title';

        break;

      case 'two-column-with-subtitle':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'FontColorTool'],
          },
          body: {
            class: Body,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          image: {
            class: Image,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          inlineImage: {
            class: InlineImage,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setSlideFiles({
                  minWidth: 100,
                  minHeight: 100,
                  maxWidth: 540,
                  maxHeight: 540,
                  aspectRatio: null
                })
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          // ancillary: {
          //   class: Ancillary,
          //   tunes: ['Animation', 'FontColorTool'],
          //   config: {}
          // },
        };
        default_block = 'title';

        break;

      case 'vertical-three-point':
        tools = {
          title: {
            class: Title,
            tunes: ['Animation', 'FontColorTool'],
          },
          point: {
            class: Point,
            tunes: ['Animation', 'FontColorTool'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
        }
        default_block = 'title';

        break;

      default:
        tools = {
          body: {
            class: Body,
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript', 'H2', 'H3', 'H4', 'H5', 'H6'],
            tunes: ['Animation', 'AlignmentBlockTune', 'FontColorTool'],
          },
          title: {
            class: Title,
            tunes: ['Animation', 'AlignmentBlockTune', 'FontColorTool'],
          },
          subtitle: {
            class: Subtitle,
            tunes: ['Animation', 'AlignmentBlockTune', 'FontColorTool'],
          },
          image: {
            class: Image,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          icon: {
            class: Icon,
            tunes: ['Animation'],
            config: {
              showIconSelector: (block) => {
                setCurrentBlock(block);
                setShowIconSelector(true);
              },
            }
          },
          inlineImage: {
            class: InlineImage,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setSlideFiles({
                  minWidth: 100,
                  minHeight: 100,
                  maxWidth: 540,
                  maxHeight: 540,
                  aspectRatio: null
                })
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          client: {
            class: Client,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          contact: {
            class: Contact,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          point: {
            class: Point,
            tunes: ['Animation'],
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          embed: {
            class: Embed,
          },
          map: {
            class: Map,
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          audio: {
            class: Audio,
            config: {
              showMediaLibrary: (api) => {
                setMediaType('audio');
                setShowMediaLibrary(!showMediaLibrary);
              },
            }
          },
          video: {
            class: Video,
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          gallery: {
            class: Gallery,
            config: {
              showMediaLibrary: (block) => {
                setCurrentBlock(block);
                setShowMediaLibrary(true);
              }
            }
          },
          list: {
            class: NestedList,
            tunes: ['Animation', 'AlignmentBlockTune', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript'],
          },
          table: {
            class: Table,
            tunes: ['Animation', 'AlignmentBlockTune', 'FontColorTool'],
            inlineToolbar: ['bold', 'italic', 'Hyperlink', 'Subscript', 'Superscript', 'H3', 'H4', 'H5', 'H6'],
            config: {
              rows: 2,
              cols: 2,
            },
          },
          quote: {
            class: Quote,
            tunes: ['Animation', 'AlignmentBlockTune', 'FontColorTool'],
            config: {
              quotePlaceholder: 'Enter a quote',
              captionPlaceholder: 'Quote\'s author',
            },
          },
          rule: {
            class: Rule,
            tunes: ['Animation'],
          },
          // ancillary: {
          //   class: Ancillary,
          //   tunes: ['Animation', 'FontColorTool'],
          //   config: {}
          // },
        };
        default_block = 'title';
    }

    inlineTools = {
      Subscript: {
        class: SubscriptTool
      },
      Superscript: {
        class: SuperscriptTool
      },
      Size: {
        class: SizeTool
      },
      AlignmentBlockTune: {
        class: AlignmentBlockTune,
        config: {
          default: 'left',
        },
      },
      H1: {
        class: H1
      },
      H2: {
        class: H2
      },
      H3: {
        class: H3
      },
      H4: {
        class: H4
      },
      H5: {
        class: H5
      },
      H6: {
        class: H6
      },
      FontColorTool: {
        class: FontColorTool,
        config: {
          options: getTuneColors()?.length > 0 ? getTuneColors() : ['#fff', '#e9e9e9', '#777', '#000'],
          default: slideLayout?.default_font_color ? slideLayout?.default_font_color : '',
          layout: slide_layout,
          blocks: {
            // body: '',
          }
        }
      },
      Animation: {
        class: AnimationTune,
        config: {
          animations: [
            {
              class: '',
              label: 'none',
            },
            {
              class: 'fade-out',
              label: 'fade-out',
            },
            {
              class: 'fade-up',
              label: 'fade-up',
            },
            {
              class: 'fade-down',
              label: 'fade-down',
            },
            {
              class: 'fade-left',
              label: 'fade-left',
            },
            {
              class: 'fade-right',
              label: 'fade-right',
            },
            {
              class: 'grow',
              label: 'grow',
            },
          ]
        }
      },
      Hyperlink: {
        class: Hyperlink,
        config: {
          options: getTuneColors()?.length > 0 ? getTuneColors() : ['#fff', '#e9e9e9', '#777', '#000'],
        }
      },
      Dimension: {
        class: DimensionTune,
        config: {}
      }
    }

    Object.assign(tools, inlineTools);

    if (editorData.blocks && editorData.blocks.length > 0) {
      editorData.blocks.map(obj => processObject({ ...obj }));
    }

    const editor = new EditorJS({
      /**
       * Id of Element that should contain the Editor
       */
      readOnly: isLibrarySlide === true ? true : false,
      logLevel: 'ERROR',
      holder: `slide-${props.slide.id}-editor`,
      inlineToolbar: true,
      minHeight: minHeight,
      tools: tools,
      defaultBlock: default_block,

      /**
       * Previously saved data that should be rendered
       */
      data: editorData,
      onReady: () => {
        editorInstance.current = editor;
        // set loading false

        const blockLength = editor.blocks.getBlocksCount();
        let isBlockImage = false;
        let imageIndex = 0;
        let contentIndex = 0;


        for (var i = 0; i < blockLength; i++) {
          const block = editor.blocks.getBlockByIndex(i);
          if (block && block.holder && block.name) {

            const element = block?.holder;
            const blockType = block?.name;

            element?.classList.add(blockType);

            if (blockType === 'image' || blockType === 'inlineImage') {
              imageIndex++;
              element?.setAttribute('data-block', imageIndex);
              isBlockImage = true;
            }

            if (blockType === 'body' || blockType === 'list') {
              contentIndex++;
              element?.setAttribute('data-block', contentIndex);
            }
          }
        }

        setContentCount(contentIndex);
        setHasImage(isBlockImage);
      },
      onChange: async (api, event) => {
        const editor = editorInstance.current;
        const blockLength = editor.blocks.getBlocksCount();
        let isBlockImage = false;
        let imageIndex = 0;
        let contentIndex = 0;

        for (var i = 0; i < blockLength; i++) {
          const block = editor.blocks.getBlockByIndex(i);
          if (block && block.holder && block.name) {

            const element = block?.holder;
            const blockType = block?.name;

            element?.classList.add(blockType);

            if (blockType === 'image' || blockType === 'inlineImage') {
              imageIndex++;
              element?.setAttribute('data-block', imageIndex);
              isBlockImage = true;
            }

            if (blockType === 'body' || blockType === 'list') {
              contentIndex++;
              element?.setAttribute('data-block', contentIndex);
            }
          }
        }

        setContentCount(contentIndex);
        setHasImage(isBlockImage);

        let content = await editorInstance.current.save();
        setEditorData(content);
        props.onRevealUpdate(); // so animations are recorded straight away
      },
    });
  };

  // On editor change, update slide
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      debounceUpdate();
    }
  }, [editorData]);



  // const debounceUpdate = useCallback(
  //   debounce(() => updateSlide(), 2000, true),
  //   []
  // );

  /**
   * Auto save
   * 
   * Updates slide
   */
  const updateSlide = async () => {
    let newData = {};

    if (editorInstance && editorInstance.current) {
      newData = await editorInstance.current.save();
    }

    if (newData === '') {
      newData = {
        blocks: [],
        reveal: {},
      }
    }

    newData.reveal = {
      slideTransition: slideTransition,
      slideLogo: slideLogo,
      slideLogoPosition: slideLogoPosition,
      slideLockup: slideLockup,
      slideLockupPosition: slideLockupPosition,
      slideLayout: slideLayout,
      slideContentPosition: slideContentPosition,
      slideAudioData: slideAudioData,
      slideThumbnail: slideThumbnail,
      slideBackgroundColor: slideBackgroundColor,
      slideBackgroundTransition: slideBackgroundTransition,
      slideBackgroundImage: slideBackgroundImage,
      slideBackgroundOpacity: slideBackgroundOpacity,
      slideBackgroundRepeat: slideBackgroundRepeat,
      slideBackgroundPosition: slideBackgroundPosition,
      slideBackgroundSize: slideBackgroundSize,
      slideInnerBackgroundColor: slideInnerBackgroundColor,
      slideInnerBackgroundImage: slideInnerBackgroundImage,
      slideInnerBackgroundOpacity: slideInnerBackgroundOpacity,
      slideInnerBackgroundRepeat: slideInnerBackgroundRepeat,
      slideInnerBackgroundPosition: slideInnerBackgroundPosition,
      slideInnerBackgroundSize: slideInnerBackgroundSize,
      slideAutoAnimate: slideAutoAnimate,
      slideOverlayOpacity: slideOverlayOpacity,
      slideOverlayWidth: slideOverlayWidth,
      slideOverlayColor: slideOverlayColor,
      slideOverlayRGB: slideOverlayRGB,
      slideChapterMarker: slideChapterMarker,
      slideDataTableColumn: slideDataTableColumn,
      archContentAlignment: archContentAlignment,
      slideNotes: slideNotes
    };

    let slideData = {
      id: props.slide.id,
      json: newData,
      node_data: {
        title: slideTitle,
      },
      presentation_id: props.presentation.id,
      prevent_duplicate: preventDuplicate ? 1 : 0,
    };

    setSaving(true);
    setSaved(false);
    setError(false);

    patchNode('api/slide/' + props.slide.id, slideData, props.csrf_token)
      .then(_response => {
        setSaving(false);
        setSaved(true);
        setError(false);
        // props.onSlideUpdate();
      })
      .catch(error => {
        setSaving(false);
        setSaved(false);
        setError(true);
      });
  };

  const debounceUpdate = debounce(updateSlide, 2000);

  const modalTitle = () => {
    if (props.showLayoutSettings) {
      return 'Template Settings';
    }
    if (props.showLogoSettings) {
      return 'Logo settings';
    }

    if (props.showBackgroundSettings) {
      return 'Background settings';
    }

    if (props.showTransitionSettings) {
      return 'Transition settings';
    }

    if (props.showSlideAudioSettings) {
      return 'Audio settings';
    }

    if (props.showSlideSettings) {
      return 'Slide settings';
    }
    if (props.showHelpDialog) {
      return helpText.header;
    }
    if (props.showSlideNotes) {
      return 'Slide notes'
    }
  };

  const slideBackground = () => {
    return (
      <>
        <div className={`settings-wrap-bg-main ${slideInnerBackgroundImage === '' ? 'none' : ''}`}>
          <BackgroundImagePicker
            user={user}
            presentation={props.presentation}
            slide={props.slide}
            slideLayout={slideLayout}
            title={'Slide background'}
            slideBackgroundImage={slideInnerBackgroundImage}
            background_images={theme_json?.background_images}
            themeDirectory={themeDirectory}
            showTooltip={true}
            onImageSelected={(background_image => {
              setSlideInnerBackgroundImage(background_image);
              setTimeout(() => {
                props.onRevealUpdate();
              }, 10);
            })}
            imagePicked={slideInnerBackgroundImage !== ''}
            // opacity settings
            opacityTitle={'Image opacity'}
            slideBackgroundOpacity={slideInnerBackgroundOpacity}
            onBackgroundImageOpacity={(opacity) => {
              setSlideInnerBackgroundOpacity(opacity);
              setTimeout(() => {
                props.onRevealUpdate();
              }, 10);
            }}
            // size settings
            sizeTitle={'Size'}
            slideBackgroundSize={slideInnerBackgroundSize}
            onBackgroundImageSize={(size) => {
              setSlideInnerBackgroundSize(size);
              setTimeout(() => {
                props.onRevealUpdate();
              }, 10);
            }}
            // repeat settings
            repeatTitle={'Repeat'}
            slideBackgroundRepeat={slideInnerBackgroundRepeat}
            onBackgroundImageRepeat={(repeat) => {
              setSlideInnerBackgroundRepeat(repeat);
              setTimeout(() => {
                props.onRevealUpdate();
              }, 10);
            }}
            // position settings
            positionTitle={'Position'}
            slideBackgroundPosition={slideInnerBackgroundPosition}
            onBackgroundImagePosition={(position) => {
              setSlideInnerBackgroundPosition(position);
              setTimeout(() => {
                props.onRevealUpdate();
              }, 10);
            }}
          />
        </div>
        <ColorPickerNew
          title={slideLayout.id === 'section-title-arch-image' ? 'Overlay colour' : 'Background colour'}
          slideBackgroundColor={slideInnerBackgroundColor}
          colors={getColors(0)}
          colorsSecondary={getColors(1)}
          onColorSelected={(background_color => {
            setSlideInnerBackgroundColor(background_color);
            setTimeout(() => {
              props.onRevealUpdate();
            }, 10);
          })}
          overlayOpacity={slideOverlayOpacity}
          changeOverlayOpacity={(value) => {
            setSlideOverlayOpacity(value)
          }}
        />
        {
          permissions?.access_advanced_background_options === true &&
          (
            <Accordion
              elevation={0}
              sx={{
                '&:before': {
                  display: 'none',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<KeyboardArrowRight />}
                sx={{
                  padding: 0,
                  flexDirection: 'row-reverse',
                  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                    transform: 'rotate(90deg)',
                  },
                  '& .MuiAccordionSummary-content': {
                    marginLeft: 1,
                  },
                }}
              >
                <Row className='w-100'>
                  <Col xs={'auto'}>
                    Advanced
                  </Col>
                  <Col xs={'auto'} className='d-flex align-items-center flex-grow-1 p-0'>
                    <Divider
                      sx={{ width: 1 }}
                    />
                  </Col>
                </Row>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                {windowBackground()}
              </AccordionDetails>
            </Accordion>)
        }
      </>
    );
  };

  const windowBackground = () => {
    return (
      <>
        <div className={`settings-wrap-bg-main mt-3 ${slideBackgroundImage === '' ? 'none' : ''}`}>
          <BackgroundImagePicker
            user={user}
            presentation={props.presentation}
            slide={props.slide}

            title={'Window background'}
            slideBackgroundImage={slideBackgroundImage}
            background_images={theme_json?.background_images}
            themeDirectory={themeDirectory}
            showTooltip={true}
            onImageSelected={(background_image => {
              setSlideBackgroundImage(background_image);
              setTimeout(() => {
                props.onRevealUpdate();
              }, 10);
            })}
            imagePicked={slideBackgroundImage !== ''}
            // opacity settings
            opacityTitle={'Image opacity'}
            slideBackgroundOpacity={slideBackgroundOpacity}
            onBackgroundImageOpacity={(opacity) => {
              setSlideBackgroundOpacity(opacity);
              setTimeout(() => {
                props.onRevealUpdate();
              }, 10);
            }}
            // size settings
            sizeTitle={'Size'}
            slideBackgroundSize={slideBackgroundSize}
            onBackgroundImageSize={(size) => {
              setSlideBackgroundSize(size);
              setTimeout(() => {
                props.onRevealUpdate();
              }, 10);
            }}
            // repeat settings
            repeatTitle={'Repeat'}
            slideBackgroundRepeat={slideBackgroundRepeat}
            onBackgroundImageRepeat={(repeat) => {
              setSlideBackgroundRepeat(repeat);
              setTimeout(() => {
                props.onRevealUpdate();
              }, 10);
            }}
            // position settings
            positionTitle={'Position'}
            slideBackgroundPosition={slideBackgroundPosition}
            onBackgroundImagePosition={(position) => {
              setSlideBackgroundPosition(position);
              setTimeout(() => {
                props.onRevealUpdate();
              }, 10);
            }}
          />
          {/* {slideBackgroundImage !== '' && (
            <Row>
              <Col>
                <BackgroundImageOpacity
                  title={'Opacity'}
                  slideBackgroundOpacity={slideBackgroundOpacity}
                  onBackgroundImageOpacity={(opacity) => {
                    setSlideBackgroundOpacity(opacity);
                    setTimeout(() => {
                      props.onRevealUpdate();
                    }, 10);
                  }}
                />
              </Col>
              <Col>
                <BackgroundImageSize
                  title={'Size'}
                  slideBackgroundSize={slideBackgroundSize}
                  onBackgroundImageSize={(size) => {
                    setSlideBackgroundSize(size);
                    setTimeout(() => {
                      props.onRevealUpdate();
                    }, 10);
                  }}
                />
              </Col>
              <Col>
                <BackgroundImageRepeat
                  title={'Repeat'}
                  slideBackgroundRepeat={slideBackgroundRepeat}
                  onBackgroundImageRepeat={(repeat) => {
                    setSlideBackgroundRepeat(repeat);
                    setTimeout(() => {
                      props.onRevealUpdate();
                    }, 10);
                  }}
                />
              </Col>
              <Col>
                <BackgroundImagePosition
                  title={'Position'}
                  slideBackgroundPosition={slideBackgroundPosition}
                  onBackgroundImagePosition={(position) => {
                    setSlideBackgroundPosition(position);
                    setTimeout(() => {
                      props.onRevealUpdate();
                    }, 10);
                  }}
                />
              </Col>
            </Row>
          )} */}
        </div>
        {/* <ColorPicker */}
        <ColorPickerNew
          title={'Background colour'}
          slideBackgroundColor={slideBackgroundColor}
          colors={getColors(0)}
          colorsSecondary={getColors(1)}
          onColorSelected={(background_color => {
            setSlideBackgroundColor(background_color);
            setTimeout(() => {
              props.onRevealUpdate();
            }, 10);
          })}
        />
      </>
    );
  };

  const backgroundSettings = () => {
    if (props.showBackgroundSettings) {
      return (
        <>
          {slideBackground()}
        </>
      );
    }
  };

  const audioSettings = () => {
    return (
      <>
        <SlideAudioPicker
          title={'Select slide audio'}
          user={user}
          presentation={props.presentation}
          slide={props.slide}
          slideAudio={slideAudioData?.src}
          onSlideAudioSelected={(media) => {
            setSlideAudioData({
              src: media,
              autoPlay: false
            });
            setTimeout(() => {
              props.onRevealUpdate();
            }, 10);
          }}
        />
      </>
    );
  };

  const getFormattedDate = (value) => {
    let date = new Date(value * 1000);
    let day = date.getDate().toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
    let month = date.getMonth() + 1;
    month = month.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
    let year = date.getFullYear();
    let hours = date.getHours().toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });;
    let minutes = date.getMinutes().toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });;
    let formattedDate = `${day}.${month}.${year} (${hours}:${minutes})`
    return formattedDate
  }

  const slideSettingsBase = () => {
    return (
      <>
        <Container className='mt-3 slide-settings-base px-0'>
          <Row>
            <Col className='pe-5'>
              <div className='form-group'>
                <label>Slide title</label>
                <TextField id="slide-title-tf" variant="outlined" fullWidth sx={{ p: 0 }} size='small' value={slideTitle} onChange={(event => {
                  setSlideTitle(event.target.value);
                })} />
              </div>
              <div className='form-group'>
                <label>Chapter marker</label>
                <TextField id="chapter-marker-tf" variant="outlined" fullWidth size='small' value={slideChapterMarker} onChange={(event => {
                  setSlideChapterMarker(event.target.value);
                })} />
              </div>
              <FormGroup className='form-group'>
                <FormControlLabel control={<Checkbox checked={slideAutoAnimate} onChange={(e) => { setSlideAutoAnimate(e.target.checked) }} />} label="Auto animate slide" />
              </FormGroup>
              {permissions?.access_advanced_slide_settings === true &&
                <FormGroup className='form-group'>
                  <FormControlLabel control={<Checkbox checked={preventDuplicate === 1 ? true : false} onChange={(e) => {
                    if (e.target.checked) {
                      setPreventDuplicate(1);
                    } else {
                      setPreventDuplicate(0);
                    }
                  }} />} label="Disable duplicate" />
                </FormGroup>
              }
            </Col>
            <Col className='ps-5'>
              <Row className='mb-3'>
                <Col className="label-container"><label>Author</label></Col>
                <Col><span>{props.slide.author}</span></Col>
              </Row>
              <Row className='mb-3'>
                <Col className="label-container"><label>Date created</label></Col>
                <Col><span>{getFormattedDate(props.slide.created)}</span></Col>
              </Row>
              <Row className='mb-3'>
                <Col className="label-container"><label>Last modified</label></Col>
                <Col><span>{getFormattedDate(props.slide.changed)}</span></Col>
              </Row>
              <Row className='mb-3'>
                <Col className="label-container"><label>Template</label></Col>
                <Col><span>{slideLayout?.name}</span></Col>
              </Row>
              <Row className='mb-3'>
                <Col className="label-container"><label>Slide ID</label></Col>
                <Col><span>{props.slide.id}</span></Col>
              </Row>
              {getContentPositions()?.length > 0 ?
                <Row className='mb-3'>
                  <Col className="label-container"><label>Content position</label></Col>
                  <Col>
                    <Select
                      fullWidth
                      className='slide-settings-select'
                      variant='standard'
                      size='small'
                      sx={{
                        '& .MuiMenuItem-root': {
                          fontSize: 14,
                          color: "#415262",
                          fontWeight: "300"
                        }
                      }}
                      value={slideContentPosition}
                      onChange={(e) => {
                        setSlideContentPosition(e.target.value)
                      }}
                    >
                      {getContentPositions().map((i) => {
                        let label = i;

                        if (slideLayout.id === 'one-row-image') {
                          if (i === 'left') {
                            label = 'Text Top / Image Bottom';
                          }

                          if (i === 'right') {
                            label = 'Image Top / Text Bottom';
                          }
                        } else
                          if (slideLayout.id === 'image-with-text-column-overlay') {
                            if (i === 'left') {
                              label = 'Left overlay';
                            }

                            if (i === 'right') {
                              label = 'Right overlay';
                            }
                          } else
                            if (slideLayout.id === 'image-with-text-row-overlay') {
                              if (i === 'left') {
                                label = 'Top';
                              }

                              if (i === 'right') {
                                label = 'Bottom';
                              }
                            }
                            else {
                              if (i === 'left') {
                                label = 'Text left / Image right';
                              }

                              if (i === 'right') {
                                label = 'Image left / Text right';
                              }
                            }

                        return <MenuItem key={`pos-${i}`} value={i}>{label}</MenuItem>
                      })}
                    </Select>
                  </Col>
                </Row>
                : ''
              }

              {getOverlayColor()?.length > 0 ?
                <Row className='mb-3'>
                  <Col className="label-container"><label>Overlay colour</label></Col>
                  <Col>
                    <Select
                      fullWidth
                      variant='standard'
                      className='slide-settings-select'
                      sx={{
                        '& .MuiMenuItem-root': {
                          fontSize: 14,
                          color: "#415262",
                          fontWeight: "300"
                        }
                      }}
                      size='small'
                      value={slideOverlayColor}
                      onChange={(e) => {
                        setSlideOverlayColor(e.target.value);
                      }}
                    >
                      {getOverlayColor().map((i) => {
                        return <MenuItem key={`pos-${i}`} value={i}>{i}</MenuItem>
                      })}
                    </Select>
                  </Col>
                </Row>
                : ''
              }
              {(slideLayout.id === 'image-with-vertical-overlay' || slideLayout.id === 'image-with-horizontal-overlay') &&
                <>
                  <Row className='mb-3'>
                    <Col className="label-container">
                      <label>
                        Overlay opacity
                      </label>
                    </Col>
                    <Col>
                      <Slider
                        value={slideOverlayOpacity}
                        step={0.01}
                        min={0}
                        max={1}
                        valueLabelDisplay="auto"
                        onChange={(e) => {
                          setSlideOverlayOpacity(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  {slideLayout.id === 'image-with-vertical-overlay' &&
                    <Row className='mb-3'>
                      <Col className="label-container">
                        <label>
                          Overlay width
                        </label>
                      </Col>
                      <Col>
                        <Slider
                          value={slideOverlayWidth}
                          marks
                          step={5}
                          min={30}
                          max={70}
                          valueLabelDisplay="auto"
                          onChange={(e) => {
                            setSlideOverlayWidth(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                  }
                </>
              }
              {(slideLayout.id === 'one-column-half-circle-image' || slideLayout.id === 'one-column-arch-image') &&
                <Row className='mb-3'>
                  <Col className="label-container">
                    <label>
                      Align content top
                    </label>
                  </Col>
                  <Col>
                    <Checkbox
                      checked={archContentAlignment}
                      size='small'
                      onChange={(e) => {
                        setArchContentAlignment(e.target.checked)
                      }}
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 22 },
                        '&': { padding: '0' }
                      }}
                    />
                  </Col>
                </Row>
              }
              {(slideLayout.id === 'data-table') &&
                <>
                  <Row className='mb-3'>
                    <Col className="label-container">
                      <label>
                        First column width
                      </label>
                    </Col>
                    <Col>
                      <Slider
                        value={slideDataTableColumn}
                        marks
                        step={5}
                        min={0}
                        max={30}
                        valueLabelDisplay="auto"
                        onChange={(e) => {
                          setSlideDataTableColumn(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </>
              }
            </Col>
          </Row>
          {
            permissions?.access_advanced_slide_settings === true &&
            (
              <>
                <Accordion
                  elevation={0}
                  sx={{
                    '&:before': {
                      display: 'none',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<KeyboardArrowRight />}
                    sx={{
                      padding: 0,
                      flexDirection: 'row-reverse',
                      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                        transform: 'rotate(90deg)',
                      },
                      '& .MuiAccordionSummary-content': {
                        marginLeft: 1,
                      },
                    }}
                  >
                    <Row className='w-100'>
                      <Col xs={'auto'}>
                        Advanced
                      </Col>
                      <Col xs={'auto'} className='d-flex align-items-center flex-grow-1 p-0'>
                        <Divider
                          sx={{ width: 1 }}
                        />
                      </Col>
                    </Row>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: 0
                    }}
                  >
                    {slideSettingsAdvanced()}
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          {slideLayoutSettings()}
        </Container>
      </>
    )
  };

  const slideSettingsAdvanced = () => {
    return (
      <Row className='slide-settings-adv'>
        <Col>
          <Row><h6>Add audio file</h6></Row>
          <AddAudio
            user={user}
            audioData={slideAudioData}
            slideSetting={true}
            onAudioChange={(audio) => {
              setSlideAudioData(audio)
            }}
          />
        </Col>
        <Col xs={12} sm={6}>
          <BespokeMedia
            user={user}
            files={{
              minWidth: 400,
              minHeight: 225,
              maxWidth: 400,
              maxHeight: 225,
              aspectRatio: 1.777777777777778
            }}
            media={slideThumbnail}
            onMediaInsert={(media) => {
              setSlideThumbnail(media);
            }}
            title={`Add slide thumbnail`}
            hint={`To attach a bespoke thumbnail to the slide, please select or upload an image file via the media library`}
          />
        </Col>
      </Row>
    );
  };

  const slideLayoutSettings = () => {
    let layouts_sorted = theme_json?.layouts.sort((a, b) => {
      let na = a.name.toLowerCase(),
        nb = b.name.toLowerCase();

      if (na < nb) {
        return -1;
      }
      if (na > nb) {
        return 1;
      }
      return 0;
    });
    if (permissions?.access_advanced_layout) {
      return (
        <Row className='mt-3'>
          <Col>
            <Row className='mb-2'><h6>Slide Layout</h6></Row>
            <FormControl fullWidth size='small'>
              <InputLabel sx={{ fontSize: 14 }} size='small' id='layout-select-label'>Layout</InputLabel>
              <Select
                labelId='layout-select-label'
                id='layout-select'
                value={slideLayout.id}
                label='Layout'
                variant={'outlined'}
                onChange={(event) => {
                  theme_json?.layouts?.forEach(layout => {
                    if (layout.id === event.target.value) {
                      setSlideLayout(layout);
                    }
                  });
                }}
                input={<OutlinedInput sx={{ fontSize: 14 }} label='Language' />}>
                {
                  layouts_sorted?.map((layout, index) =>
                    <MenuItem key={`layout-${index}`} value={layout.id} name={layout.name} sx={{ fontSize: 14 }}>{layout.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Col>
        </Row>
      )
    }
  };

  const slideSettings = () => {
    if (props.showSlideSettings) {
      return (
        <>
          {slideSettingsBase()}
        </>
      )
    }
  };

  const slideNotesInput = () => {
    return (
      <div className='slide-notes-modal-content'>
        <TextField
          multiline
          defaultValue={slideNotes}
          variant='standard'
          autoFocus={true}
          value={slideNotes ? slideNotes : ''}
          className='slide-notes-input'
          onChange={(event => {
            setSlideNotes(event.target.value);
          })}
          placeholder='You can leave notes for the slide here. This will show up on "Speaker View". In the viewer, press "S" on your keyboard to activate.'
        />
      </div>
    )
  }

  const MemoImg = React.memo(function Image({ src, className, alt, overlay }) {
    return <img src={src} alt={alt} className={className} style={{ filter: `saturate(${overlay === 1 ? 1 : 0}) opacity(${overlay ? overlay : 1})` }} />;
  });

  const MemoImage = React.memo(function Image({ href, width, height }) {
    return <image href={href} width={width} height={height} mask={`${slideInnerBackgroundColor ? 'url(#mask)' : ''}`} ></image>;
  });

  const SectionTitleArchImage = () => {
    return (
      <div className='image-container'>
        <MemoImg src={backgroundImageURIGenerator(slideInnerBackgroundImage, false, props.slide, props.presentation.id)} className={'image'} alt='' overlay={slideOverlayOpacity} />

        <div className='colour-filter' style={{ backgroundColor: slideInnerBackgroundColor }}></div>

        <svg width='100%' height='100%' viewBox='0 0 1366 768' version='1.1' xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'>
          <defs>
            <mask id='mask'>
              <path fill='white' d="M1278.91,768H786c.29-.94,61.53-255.72,18.09-460.58C766.76,131.56,622.58.55,622,0h492c2.19,3.75,141.44,131.92,176.94,298.05,45,210.42-12.94,468.85-12.94,468.85C1278.05,767.23,1279,767.67,1278.91,768Z" />
            </mask>
          </defs>

          <MemoImage href={backgroundImageURIGenerator(slideInnerBackgroundImage, false, props.slide, props.presentation.id)} width='1366' height='768' />

        </svg>

      </div>
    )
  };

  const OneColumArchImage = () => {
    return (
      <div className='overlay-container'>
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
          <path fill="#fff" d="M1104.72,1079.73c-365.62,0-732.66-.09-1098.29,0-5.3,0-6.47-1.16-6.47-6.46Q.2,540,0,6.73C0,1.44,1.13.27,6.43.27c249,.15,618.79,0,867.78,0,.85.78,203.5,184.91,255.93,432.1C1191.2,720.3,1105.12,1078.41,1104.72,1079.73Z" transform="translate(0.04 -0.27)" />
          <path fill="#fff" d="M1565.74.27q174.3,0,348.51,0c4.81,0,5.76,1,5.75,5.76q-.21,534,0,1067.94c0,4.82-.93,5.8-5.75,5.76-38.88-.25-77.75,0-116.66,0,.11-.46-1.21-1.08-1.33-1.54,0,0,81.38-363.24,18.18-659C1764.55,185.69,1568.82,5.54,1565.74.27Z" transform="translate(0.04 -0.27)" />
        </svg>
      </div>
    );
  };

  const OneColumnHalfCircleImage = () => {
    return (
      <div className='overlay-container'>{
        (props?.slide?.theme?.label === "companyapp" || props?.slide?.theme?.label === "Companyapp" || props?.slide?.theme?.label === "bread-creative") ?
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
            <title>full-circle</title>
            <path fill='#fff' stroke='none' d="M0,0V1080H1920V0ZM1440,946c-224,0-405.5-181.55-405.5-405.5S1216.05,135,1440,135s405.5,181.55,405.5,405.5S1664,946,1440,946Z" />
          </svg>
          :
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 768" >
            <rect fill="transparent" width="1366" height="768" />
            <path fill="#fff" d="M773.09,767.91q-384.24,0-768.5.09C.84,768,0,767.16,0,763.41Q.15,384,0,4.59C0,.84.84,0,4.59,0q384.26.15,768.5.09c-10,22.58-20.43,45-29.8,67.79-6.46,15.74-11.44,32.1-17.08,48.18l-5.67,17.84q-5.85,21.87-11.69,43.75-4.7,21.28-9.38,42.56L697.19,233l-.93,5-6.45,45.87-1.88,18.66c-.07.44-.14.88-.22,1.32l-.45,4.08q-.48,6-1,12.05-1,26-2.11,51.95c.57,23.63.61,47.28,1.83,70.88,2.15,41.78,8.23,83.09,17.16,124,3.69,16.85,8.34,33.48,12.55,50.21l5.49,18.85,1,4c.54,1.32,1.09,2.64,1.63,4l.12.06q1.51,5.08,3,10.17l1.23,5,1,2.87.91,2.88q1.08,2.12,2.17,4.23,1.15,4,2.32,8c.17.65.35,1.3.53,2,.53,1.37,1.05,2.75,1.58,4.12l.42,1.75,2.72,7.23,5.28,12.77c2.51,6.33,4.94,12.68,7.61,18.93.31.71,2,.85,3,1.26,0,1.3.07,2.6.11,3.91-.12.1-.35.25-.33.29a6.1,6.1,0,0,0,.41.7l3.17,7.06,2.1,5,1.87,4,2.06,4.89c1,2,2,4,3,6Z" transform="translate(0)" />
          </svg>
      }
      </div>
    );
  };

  const getColors = (variant) => {
    if (theme_json && theme_json?.layouts) {
      let slideLayoutObj = theme_json?.layouts.find(o => { return o.id === slideLayout.id });
      let colorsPrimary = slideLayoutObj?.colors;
      let colorsSecondary = slideLayoutObj?.colorsSecondary;
      return variant ? colorsSecondary : colorsPrimary;
    } else {
      return variant ? slideLayout.colorsSecondary : slideLayout.colorsPrimary;
    }
  };

  const getTuneColors = () => {
    if (theme_json) {
      return theme_json.tuneColors;
    } else {
      return slideLayout?.tuneColors ? slideLayout?.colorsPrimary : [];
    }
  };

  const getContentPositions = () => {
    if (theme_json && theme_json?.layouts) {
      let slideLayoutObj = theme_json?.layouts.find(o => { return o.id === slideLayout.id });
      return slideLayoutObj?.contentPosition;
    } else {
      return slideLayout?.contentPosition ? slideLayout?.contentPosition : [];
    }
  };

  const getOverlayColor = () => {
    if (theme_json && theme_json?.layouts) {
      let slideLayoutObj = theme_json?.layouts.find(o => { return o.id === slideLayout.id });
      return slideLayoutObj?.overlayColor;
    } else {
      return slideLayout?.overlayColor ? slideLayout?.overlayColor : [];
    }
  };

  const testIframe = (src) => {
    fetch(src)
      .then(res => {
        if (res.status === 404 || res.status === 403 || res.status === 500) {
          setIframeError(true);
        }
      });
  };

  let bgStyling = {
    backgroundSize: slideInnerBackgroundSize,
    backgroundRepeat: slideInnerBackgroundRepeat,
    backgroundPosition: slideInnerBackgroundPosition,
    opacity: slideLayout.id !== 'one-column-arch-image' && slideLayout.id !== 'one-column-half-circle-image' && slideInnerBackgroundOpacity,
    backgroundImage: slideLayout.id === 'one-column-arch-image' || slideLayout.id === 'one-column-half-circle-image' ? '' : slideInnerBackgroundImage !== '' ? `url(${backgroundImageURIGenerator(slideInnerBackgroundImage, false, props.slide, props.presentation.id)})` : ''
  };

  // let dataAutoAnimate = slideAutoAnimate ? {
  //   'data-auto-animate': ''
  // } : {}

  const helpTextParser = (string) => {
    if (string) {
      const parsed = string.replace(new RegExp('ASSET_PREFIX', 'g'), HELP_TEXT_IMAGE_ROOT);
      return parsed;
    } else {
      return string;
    }
  };

  const lockupEnabled = () => {
    return (props.slide?.theme?.label === 'rhg' && slideLayout.id === 'thank-you')
  }

  const isImgOverlay = () => {
    return (slideLayout.id === 'image-with-horizontal-overlay' || slideLayout.id === 'image-with-vertical-overlay')
  }

  return (
    <>
      <section
        className={`slide-wrap h-100 ${permissions.access_blank_layout ? 'high-level' : ''} ${isSaving ? '' : ''}`}
        data-permissions={permissions?.create_new_block === true ? true : false}
        // reveal params
        data-transition={slideTransition}
        data-background-transition={slideBackgroundTransition}
        data-background-color={slideBackgroundColor}
        data-background-image={backgroundImageURIGenerator(slideBackgroundImage, false, props.slide, props.presentation.id)}
        data-background-opacity={slideBackgroundOpacity}
        data-background-repeat={slideBackgroundRepeat}
        data-background-position={slideBackgroundPosition}
        data-background-size={slideBackgroundSize}
        data-theme={props.slide.theme.label}
        data-slide-layout={slideLayout.id}
        initial-presentation-id={isLibrarySlide ? props.slide.initial_presentation : ''}
      >
        <div id={`slide-${props.slide.id}`} className={`slide ${isSafari ? 'safari' : ''}`}>
          {isLibrarySlide && (
            <Tooltip id="library-slide-tooltip" title={`P=${props.slide.initial_presentation}, S=${props.slide.id}`} placement='bottom-start'>
              <div className='library-slide-indicator'>
                <span className='icon-container'>
                  <span className='icon'>
                    {/* <Attachment color='solid' /> */}
                    <NounLink style={{ transform: 'rotate(45deg)', pointerEvents: 'none' }} />
                  </span>
                  <span className='tri' />
                </span>
              </div>
            </Tooltip>
          )}

          <div className='slide-inner-background' style={{ backgroundColor: slideInnerBackgroundColor }}>
            <div className='slide-inner-background-content'
              style={bgStyling}>
              {slideLayout.id === 'section-title-arch-image' && (
                <SectionTitleArchImage />
              )}

              {slideLayout.id === 'one-column-arch-image' && (
                <OneColumArchImage />
              )}

              {slideLayout.id === 'one-column-half-circle-image' && (
                <OneColumnHalfCircleImage />
              )}
              {(slideLayout.id === 'one-column-arch-image' || slideLayout.id === 'one-column-half-circle-image') &&
                <Box
                  sx={{ display: 'flex', justifyContent: 'flex-end', height: '100%', width: '100%' }}
                >
                  <img
                    alt=''
                    style={{
                      minWidth: 683,
                      maxWidth: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      opacity: `${slideInnerBackgroundOpacity}`,
                      marginRight: `${slideLayout.id === 'one-column-arch-image' ? '3%' : ''}`
                    }}
                    src={backgroundImageURIGenerator(slideInnerBackgroundImage, false, props.slide, props.presentation.id)}
                  />
                </Box>
              }
            </div>
          </div>

          {slideLayout.id === 'advanced' ? (
            <>
              {iframeError ? (
                <div className='content-wrapper w-100 h-100 d-flex justify-content-center align-items-center' style={{ backgroundColor: '#fff' }}>
                  <h6 style={{ color: '#888' }}>Error loading slide. The slide may be missing or corrupt.</h6>
                </div>
              ) : (
                <Iframe id={props.slide.id} slide={props.slide} live={props.live} presentation_id={props.presentation.id} onTest={testIframe} />
              )}
            </>
          ) : (
            <div
              id={`slide-${props.slide.id}-editor`}
              className={`inner-container editor-container 
              content-${contentCount} ${hasImage ? 'has-image' : ''} 
              ${isImgOverlay ? slideOverlayColor : ''} 
              ${slideContentPosition} 
              ${slideLayout.id === 'data-table' ? `stub-column-${slideDataTableColumn ? slideDataTableColumn : 0}` : ''} 
              ${slideLayout.id === 'one-column-half-circle-image' || slideLayout.id === 'one-column-arch-image' ? archContentAlignment ? 'top' : '' : ''}
              ${isImgOverlay ? `alpha-${Math.round(slideOverlayOpacity * 10)}` : ''} 
              `}
            />
          )}

          {slideLogo !== '' && (
            <div className={`logo-container ${slideLogoPosition}`}>
              <img src={`${themeDirectory}/dist/images/logos/${slideLogo}`} alt={`slide-logo`} />
            </div>
          )}

          {(lockupEnabled() && slideLockup) && (
            <div className={`lockup-container ${slideLockupPosition}`}>
              <img src={`${themeDirectory}/dist/images/lockups/${slideLockup}`} alt={`slide-lockup`} />
            </div>
          )}
        </div>

        {isSaving || isError ? (
          <div className='state-container'>
            <Button
              className='state'
              disabled={true}>
              <span>
                {isSaving && !isError && (
                  <span className='saver'>
                    <Loader width={20} height={20} color='#1d202b' />
                    <span className='label'>Saving</span>
                  </span>
                )}
                {!isSaving && isError && (
                  <span className='error'>
                    <WarningAmber fontSize={'small'} color={'error'} />
                    <span className='label'>Error saving slide</span>
                  </span>
                )}
              </span>
            </Button>
          </div>
        ) : (
          <></>
        )}

        {showSettingsModal && (
          <Dialog
            className={
              `ss-modal settings-modal 
              ${props.showBackgroundSettings ? 'background-modal' : ''} 
              ${props.showSlideAudioSettings ? 'audio-modal' : ''} 
              ${props.showSlideSettings ? 'slide-settings-modal' : ''} 
              ${props.showHelpDialog ? 'slide-help-modal' : ''} 
              ${props.showSlideNotes ? 'slide-notes-modal' : ''}`}
            open={showSettingsModal}
            maxWidth={
              props.showTransitionSettings ||
                props.showLayoutSettings ||
                props.showLogoSettings ||
                props.showSlideNotes ||
                props.showHelpDialog ? 'md' :
                props.showSlideSettings ? 'lg' :
                  props.showBackgroundSettings ? 'xl' : 'sm'}
            fullWidth={
              props.showTransitionSettings ||
                props.showSlideSettings ||
                props.showLogoSettings ||
                props.showBackgroundSettings ||
                props.showSlideNotes ||
                props.showHelpDialog ? true : false}
            // fullScreen={props.showBackgroundSettings ? true : false}
            // transitionDuration={0}
            onClose={() => {
              setSlideLogo(originalSlideLogo);
              setSlideLogoPosition(originalSlideLogoPosition);
              setSlideLockup(originalSlideLockup);
              setSlideLockupPosition(originalSlideLockupPosition);
              setSlideLayout(originalSlideLayout);
              setSlideContentPosition(originalSlideContentPosition);
              setSlideTransition(originalSlideTransition);
              setSlideAudioData(originalSlideAudioData);
              setSlideBackgroundColor(originalSlideBackgroundColor);
              setSlideBackgroundTransition(originalSlideBackgroundTransition);
              setSlideBackgroundImage(originalSlideBackgroundImage);
              setSlideBackgroundOpacity(originalSlideBackgroundOpacity);
              setSlideBackgroundRepeat(originalSlideBackgroundRepeat);
              setSlideBackgroundPosition(originalSlideBackgroundPosition);
              setSlideBackgroundSize(originalSlideBackgroundSize);
              setSlideInnerBackgroundColor(originalSlideInnerBackgroundColor);
              setSlideInnerBackgroundImage(originalSlideInnerBackgroundImage);
              setSlideInnerBackgroundOpacity(originalSlideInnerBackgroundOpacity);
              setSlideInnerBackgroundRepeat(originalSlideInnerBackgroundRepeat);
              setSlideInnerBackgroundPosition(originalSlideInnerBackgroundPosition);
              setSlideInnerBackgroundSize(originalSlideInnerBackgroundSize);
              setSlideAutoAnimate(originalSlideAutoAnimate);
              setSlideOverlayOpacity(originalSlideOverlayOpacity);
              setSlideOverlayWidth(originalSlideOverlayWidth);
              setSlideOverlayColor(originalSlideOverlayColor);
              setSlideOverlayRGB(originalSlideOverlayRGB);
              setSlideChapterMarker(originalSlideChapterMarker);
              setSlideDataTableColumn(originalSlideDataTableColumn);
              setArchContentAlignment(originalArchCOntentAlignment)
              setCancelled(true);
              setSettingsModalShow(false);
              props.onSettingsModalHide();
            }}>

            <Box className='ss-modal-title'>
              <Container fluid>
                <Row className='align-items-center justify-content-between'>
                  <Col xs={'auto'}>
                    <h3>
                      {props.showHelpDialog ? <div dangerouslySetInnerHTML={{ __html: helpTextParser(HelpText(slideLayout.id, hasImage, slideContentPosition, "header")) }} /> : modalTitle()}

                    </h3>
                  </Col>
                  <Col xs={'auto'}>
                    <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
                      <Button
                        variant='outlined'
                        className='cancel'
                        sx={{
                          backgroundColor: 'primary.contrastText',
                          textTransform: 'none',
                          marginRight: '10px'
                        }}
                        onClick={() => {
                          setSettingsModalShow(false);
                          props.onSettingsModalHide();
                        }}>
                        Cancel
                      </Button>
                      {
                        !props.showHelpDialog &&

                        <Button
                          className='action'
                          sx={{
                            textTransform: 'none',
                          }}
                          variant='contained'
                          color='secondary'
                          onClick={() => {
                            setSettingsModalShow(false);
                            props.onSettingsModalHide();
                            updateSlide();
                          }}>
                          Save
                        </Button>
                      }
                    </Stack>
                  </Col>
                </Row>
              </Container>
            </Box>
            <DialogContent
              sx={{
                pt: '20px !important',
                overflowX: 'hidden'
              }}
              dividers={true}
            >
              <div className={`bg-settings-container ss-modal-content ${props.showHelpDialog ? 'slide-help-modal' : ''} ${props.slideNotes ? 'slide-notes-modal' : ''}`}>
                {props.showBackgroundSettings && (
                  <>
                    {backgroundSettings()}
                  </>
                )}
                {props.showLogoSettings && (
                  <>
                    <div className={`settings-wrap-lg-main`}>
                      <LogoPicker
                        title={'Logo'}
                        slideLogo={slideLogo}
                        logos={theme_json?.logos}
                        themeDirectory={themeDirectory}
                        onLogoSelected={(slide_logo => {
                          setSlideLogo(slide_logo);
                        })}
                      />
                    </div>
                    <Box>
                      <Row className='m-0 w-100 pt-3 px-0'>
                        <Col className='px-0'>
                          <Row>
                            <Col xs={'auto'} className='px-0' >
                              <LogoPosition
                                slideLogoPosition={slideLogoPosition}
                                variant='standard'
                                size='small'
                                showTooltip={true}
                                onLogoPosition={(position) => {
                                  setSlideLogoPosition(position);
                                }}
                              />
                            </Col>
                            <Col xs={'auto'} className='ps-3'>
                              <Alert severity='warning' sx={{ fontSize: 12 }} >Please note, this option may be overridden via the brand theme.</Alert>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Box>
                    {lockupEnabled() &&
                      <>
                        <div className={`settings-wrap-lg-main mt-5`}>
                          <LogoPicker
                            title={'Lockup'}
                            isLockup={true}
                            slideLogo={slideLockup}
                            logos={theme_json?.lockups}
                            themeDirectory={themeDirectory}
                            onLogoSelected={(slide_lockup => {
                              setSlideLockup(slide_lockup);
                            })}
                          />
                        </div>
                        <Box>
                          <Row className='m-0 w-100 pt-3 px-0'>
                            <Col className='px-0'>
                              <Row>
                                <Col xs={'auto'} className='px-0' >
                                  <LogoPosition
                                    slideLogoPosition={slideLockupPosition}
                                    variant='standard'
                                    size='small'
                                    showTooltip={true}
                                    isLockup={true}
                                    onLogoPosition={(position) => {
                                      setSlideLockupPosition(position);
                                    }}
                                  />
                                </Col>
                                <Col xs={'auto'} className='ps-3'>
                                  <Alert severity='warning' sx={{ fontSize: 12 }} >Please note, this option may be overridden via the brand theme.</Alert>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Box>
                      </>
                    }
                  </>
                )}
                {props.showTransitionSettings && (
                  <>
                    <Transition
                      title={'Slide transition'}
                      selectedTransition={slideTransition}
                      onTransitionSelected={(slide_transition => {
                        setSlideTransition(slide_transition);
                        setTimeout(() => {
                          props.onRevealUpdate();
                        }, 10);
                      })}
                    />
                    <Transition
                      title={'Background transition'}
                      selectedTransition={slideBackgroundTransition}
                      onTransitionSelected={(background_transition => {
                        setSlideBackgroundTransition(background_transition);
                        setTimeout(() => {
                          props.onRevealUpdate();
                        }, 10);
                      })}
                    />
                  </>
                )}
                {props.showLayoutSettings && (
                  <div className={`settings-wrap-lg-main`}>
                    <LayoutPicker
                      layouts={theme_json?.layouts}
                      slideLayout={slideLayout}
                      slideContentPosition={slideContentPosition}
                      onLayoutSelected={(layout => {
                        setSlideLayout(layout);
                      })}
                      onContentPositionSelected={(position => {
                        setSlideContentPosition(position);
                      })}
                      categoryTitle={'Category'}
                      layoutTitle={'Layout'}
                    />
                  </div>
                )}
                {props.showSlideAudioSettings &&
                  <>
                    {audioSettings()}
                  </>
                }
                {props.showSlideSettings &&
                  <>
                    {slideSettings()}
                  </>
                }
                {props.showHelpDialog &&
                  <div
                    dangerouslySetInnerHTML={{ __html: helpTextParser(HelpText(slideLayout.id, hasImage, slideContentPosition, "body")) }}
                  />
                }
                {props.showSlideNotes &&
                  <>
                    {slideNotesInput()}
                  </>
                }
              </div>
            </DialogContent>
          </Dialog>
        )}

        {showMediaLibrary && (
          <MediaLibrary
            user={user}
            csrf_token={props.csrf_token}
            presentation={props.presentation}
            slide={props.slide}
            slideLayout={slideLayout}
            mediaType={currentBlock?.bundle ? currentBlock.bundle : mediaType}
            json={json}
            files={slideFiles}
            setMediaType={(type => {
              setMediaType(type);
            })}
            setShowMediaLibrary={(condition) => {
              setShowMediaLibrary(condition);
              setSlideAudio(condition);
              setSlideFiles({});
            }}
            showMediaLibrary={showMediaLibrary}
            onMediaInsert={(media) => {
              if (slideAudio) {
                setShowMediaLibrary(false);
                setSlideAudio(false);
                setMediaType('all');
                setSlideAudioData({
                  src: media.field_media_audio,
                  autoPlay: false,
                  name: media.name,
                  mid: media.mid
                });
              } else {
                if (currentBlock?.details) {
                  let data = currentBlock?.data;

                  if (currentBlock?.bundle === 'image') {
                    if (currentBlock?.details.name === 'gallery') {
                      const imageData = {
                        mid: media.mid,
                        url: media.field_media_image,
                        caption: media.alt,
                        x: media.x,
                        y: media.y,
                        width: media.width,
                        height: media.height,
                        maxWidth: media.maxWidth,
                        maxHeight: media.maxHeight,
                      };

                      if (Array.isArray(data.images)) {
                        data.images.push(imageData);
                      } else {
                        data.images = [];
                        data.images.push(imageData);
                      }
                    } else {
                      data.mid = media.mid;
                      data.url = media.field_media_image;
                      data.x = media.x;
                      data.y = media.y;
                      data.width = media.width;
                      data.height = media.height;
                      data.maxWidth = media.maxWidth;
                      data.maxHeight = media.maxHeight;
                    }
                  }

                  if (currentBlock?.bundle === 'video') {
                    data.mid = media.mid;
                    data.url = media.field_media_video;
                    data.timestamp = media.field_poster_timestamp
                  }

                  editorInstance.current.blocks.update(currentBlock?.details?.id, data);

                  // const blockElements = document.querySelectorAll(`#slide-${props.slide.id}-editor .ce-block`);
                  
                  // blockElements.forEach((element, index) => {
                  //   if (index === currentBlockIndex) {
                  //     const child = element.querySelector('.ce-block__content .fake');
                  //     child.click();
                  //   }
                  // });
                }
              }
            }}
            selectedMid={currentBlock?.data?.mid}
            currentBlock={currentBlock}
          />
        )}

        {slideLayout.id === 'four-column-icons' && (
          <IconSelector
            icons={getLayoutIcons(slideLayout.id, props.slide.theme.label)}
            iconColors={slideLayout.iconColors}
            iconColorsSecondary={getColors(1)}
            setShowIconSelector={(condition) => {
              setShowIconSelector(condition);
            }}
            currentBlock={currentBlock}
            showIconSelector={showIconSelector}
            onIconSelected={(icon, color) => {
              if (currentBlock?.details) {
                let data = currentBlock?.data;
                data.icon = icon.icon;
                data.name = icon.name;
                data.color = color;
                editorInstance.current.blocks.update(currentBlock?.details?.id, data);
              }
            }}
          />
        )}

        {slideAudioData &&
          <>
            <AudioPlayer
              isSlideAudio={true}
              audio={typeof slideAudioData == 'string' ? slideAudioData : slideAudioData.src}
              editMode={true}
              audioTrigger={props.activeTrigger}
              playOverride={props.playSlideAudio}
              audioPlaying={(value) => { props.onSlideAudioPlaying(value) }}
              showControls={props.showSlideAudioControls}
              hideSlideAudioControls={() => props.hideSlideAudioControls()}
              autoPlay={slideAudioData.autoPlay}
            />
          </>
        }
      </section>
    </>
  );
};

const Iframe = (props) => {
  const isLibrary = parseInt(props.slide.initial_presentation) !== parseInt(props.presentation_id) ? true : false;
  let src = `${proxy}${props.slide.slide_html_path}`;

  if (isLibrary) {
    src = `${proxy}/${subdomain}/presentations/${props.slide.initial_presentation}/${props.slide.initial_presentation}/${props.id}/${props.id}.html`;

    if (props.slide.field_migration_id) {
      src = `${proxy}/${subdomain}/presentations/${props.slide.initial_presentation}/${props.slide.initial_presentation}/${props.id}.html`;
    }
  } else {
    if (props.live) {
      src = `${proxy}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${props.id}/${props.id}.html`;
      if (props.slide.field_migration_id) {
        src = `${proxy}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${props.id}.html`;
      }
    }
  }

  props.onTest(src);

  return useMemo(() => {
    return <iframe id={`frame-${props.id}`} className='advanced-slide' title={`Advanced Slide ${props.id}`} data-src={src} />;
  }, [props.id, src]);
};
