import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { getOnlineNode } from '../../core/getNode'

import PageHeader from '../../partials/pageHeader'
import NoticeItem from './noticeItem'
import Pagination from '../../partials/pagination'
import NoData from '../../partials/noData';

import { Row, Col } from 'react-bootstrap'


export default function Notices(props) {
  // const notices = useSelector((state) => state).noticeReducer.rows
  const [isLoading, setLoading] = useState(true);
  const [notices, setNotices] = useState([])
  const user = useSelector((state) => state).authReducer.user;
  const noticeRows = useSelector((state) => state).noticeReducer.rows;
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 20;
  // const [notices, setNotices] = useState()

  const listLabels = [
    { title: "ID", width: { xs: 1 }, className: "" },
    { title: "Date", width: { xs: 1 }, className: "d-xl-block d-none px-0" },
    { title: "Subject", width: { xs: 5, md: "auto px-4" }, grow: 1 },
    { title: "Open", width: { xl: 1 }, align: "right", className: "d-xl-block d-none" },
    { title: "Read", width: { xs: 1 }, align: "right" },
  ]

  useEffect(() => {
    getNotices()
  }, [currentPage]);

  const getNotices = () => {
    if (user.current_user) {
      getOnlineNode(`api/notifications?all=true&items_per_page=${pageSize}&page=${currentPage}&_format=json`)
        .then((response) => {
          setNotices(response.data.rows);
          setLoading(false);
        })
        .catch((error) => {
          console.log('notifications archive error', error)
        })
    }
  }
  return (
    <>
      <div className='notifications screen'>
        <PageHeader
          pageName="Notices"
          listHeader={true}
          listLabels={listLabels}
        />
        <div className='notifications-list'>
          {notices.length > 0 ? (
            <>
              {notices.map((e) => {
                return <NoticeItem item={e} user={user} rows={noticeRows} />
              })}
            </>
          ) : (
            <>
              {!isLoading && (
                <NoData activeIndex={0} />
              )}
            </>
          )}
        </div>

        {notices.length > 0 && (
          <Pagination
            containerClasses={`d-flex justify-content-center align-items-center mt-3`}
            previousDisabled={currentPage + 1 === 1 ? true : false}
            nextDisabled={notices.length < pageSize ? true : false}
            currentPage={currentPage + 1}
            onPrevious={() => {
              if (!(currentPage + 1 <= 1)) {
                setCurrentPage(currentPage - 1)
              }
            }}
            onNext={() => {
              setCurrentPage(currentPage + 1)
            }}
          />
        )}
      </div>
    </>
  )
}