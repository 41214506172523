import React, { useEffect, useState } from "react";

// Modules
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// App
import { root_auth_handshake } from "../../config";
import {
  auth_login,
  auth_logout,
  get_logout_token,
  get_csrf_token,
} from "../../core/auth";
import { getOnlineNode } from "../../core/getNode";
import PageHeader from "../../partials/pageHeader";
import { alertMessages } from "../../partials/alertMessages";

// UI components
import { LockOutlined, PersonOutlineOutlined } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
  FormHelperText,
  Alert,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";

export default function Login(props) {
  const [isAllowed, setAllowed] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [csrfToken, setCsrfToken] = useState("");
  const [logoutToken, setLogoutToken] = useState("");

  const isInModal = props.type === "modal" ? true : false;

  /** redux */
  const user = useSelector((state) => state).authReducer.user;

  /** hooks */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // check session
  useEffect(() => {
    if (user.current_user && user.current_user.uid) {
      getOnlineNode(`user/${user.current_user.uid}`)
        .then(() => {
          // logged in => redirect home
          setLoading(false);
          navigate("/");
        })
        .catch(() => {
          // not logged in => redirect publicHome
          setLoading(false);
          navigate("/");
        });
    }
  }, []);

  const doLogin = (event) => {
    event.preventDefault();

    if (!username || username === "") {
      setError(true);
      setErrorMessage(alertMessages.invalidUsername.title);
      return;
    }

    if (!password || password === "") {
      setError(true);
      setErrorMessage(alertMessages.invalidPassword.title);
      return;
    }

    setLoading(true);

    get_csrf_token()
      .then((token_response) => {
        setCsrfToken(token_response.data);

        auth_login(username, password, csrfToken)
          .then((auth_response) => {
            setLoading(false);
            setError(false);
            setErrorMessage("");
            dispatch({ type: "SAVE_SESSION", payload: auth_response.data });
          })
          .catch((auth_error) => {
            console.log("err: ", auth_error.response);

            if (auth_error.response?.status === 403) {
              console.log("403");
              // logout
              logout();
            } else {
              let message = "Unknown error!";

              if (auth_error?.response?.data?.message) {
                message = auth_error.response.data.message;
              }

              setLoading(false);
              setError(true);
              setErrorMessage(message);
            }
          });
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        setErrorMessage("token");
      });
  };

  const logout = () => {
    get_logout_token()
      .then((logout_token_response) => {
        setLogoutToken(logout_token_response.data.logout_token);

        auth_logout(logout_token_response.data.logout_token)
          .then(() => {
            doLogin();
          })
          .catch((error) => {
            setLoading(false);
            setError(true);
            setErrorMessage("Unknown error!");
          });
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        setErrorMessage("Unknown error!");
      });
  };

  if (!isAllowed) {
    return (
      <main className={`login screen d-flex flex-column align-items-center`}>
        <Row className={`g-0 w-50`}>
          <PageHeader pageName="Root Auth" filters={false} />
        </Row>

        <Row className={`g-0 w-50`}>
          <Col xs={12}>
            <form>
              <div className="form-group mt-1">
                <Row>
                  <Col>
                    <FormControl sx={{ width: 1 }}>
                      <InputLabel
                        htmlFor="rootAuth"
                        sx={{ backgroundColor: "#fff" }}
                      >
                        <Typography
                          sx={{
                            color: "primary.main",
                            fontSize: "16px",
                          }}
                        >
                          <LockOutlined
                            sx={{
                              paddingRight: "5px",
                              height: "25px",
                            }}
                          />
                          Auth
                        </Typography>
                      </InputLabel>
                      <OutlinedInput
                        type="password"
                        id={"rootAuth"}
                        onInput={(e) => {
                          if (e.target.value === root_auth_handshake) {
                            setAllowed(true);
                          }
                        }}
                      />
                    </FormControl>
                  </Col>
                </Row>
              </div>
            </form>
          </Col>
        </Row>
      </main>
    );
  }

  return (
    <main
      className={`login  ${
        isInModal ? "mt-5" : "screen d-flex flex-column align-items-center"
      }`}
    >
      <Row className={`g-0 ${isInModal ? "" : "w-50 mb-3"}`}>
        {!isInModal && <PageHeader pageName="Login" filters={false} />}
      </Row>

      <Row className={`g-0 ${isInModal ? "" : "w-50"}`}>
        <Col xs={12}>{}</Col>

        {isError && (
          <>
            {errorMessage === "token" && (
              <Col xs={12} className="mb-4">
                <Alert variant="filled" severity="error">
                  Failed to fetch token. Please try again or contact an
                  administrator!
                </Alert>
              </Col>
            )}

            {errorMessage !== "token" &&
              errorMessage !== "username" &&
              errorMessage !== "password" && (
                <Col xs={12} className="mb-4">
                  <Alert variant="filled" severity="error">
                    {errorMessage}
                  </Alert>
                </Col>
              )}
          </>
        )}

        <Col xs={12}>
          <form onSubmit={doLogin}>
            <div className="form-group">
              <Row>
                <Col>
                  <FormControl
                    error={
                      isError &&
                      errorMessage === alertMessages.invalidUsername.title
                        ? true
                        : false
                    }
                    sx={{ width: 1 }}
                  >
                    <InputLabel
                      htmlFor="username"
                      sx={{ backgroundColor: "#fff" }}
                    >
                      <Typography
                        sx={{
                          color: "primary.main",
                          fontSize: "16px",
                        }}
                      >
                        <PersonOutlineOutlined
                          sx={{
                            paddingRight: "5px",
                            height: "25px",
                          }}
                        />
                        Username
                      </Typography>
                    </InputLabel>
                    <OutlinedInput
                      id={"username"}
                      value={username}
                      onInput={(e) => {
                        setUsername(e.target.value);
                        setError(false);
                        setErrorMessage("");
                      }}
                    />
                    {isError &&
                      errorMessage === alertMessages.invalidUsername.title && (
                        <FormHelperText
                          sx={{ marginLeft: 0 }}
                          id="username-text"
                        >
                          {errorMessage}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Col>
              </Row>
            </div>

            <div className="form-group mt-3">
              <Row>
                <Col>
                  <FormControl
                    error={
                      isError &&
                      errorMessage === alertMessages.invalidPassword.title
                        ? true
                        : false
                    }
                    sx={{ width: 1 }}
                  >
                    <InputLabel
                      htmlFor="password"
                      sx={{ backgroundColor: "#fff" }}
                    >
                      <Typography
                        sx={{
                          color: "primary.main",
                          fontSize: "16px",
                        }}
                      >
                        <LockOutlined
                          sx={{
                            paddingRight: "5px",
                            height: "25px",
                          }}
                        />
                        Password
                      </Typography>
                    </InputLabel>
                    <OutlinedInput
                      type="password"
                      id={"password"}
                      value={password}
                      onInput={(e) => {
                        setPassword(e.target.value);
                        setError(false);
                        setErrorMessage("");
                      }}
                    />
                    {isError &&
                      errorMessage === alertMessages.invalidPassword.title && (
                        <FormHelperText
                          sx={{ marginLeft: 0 }}
                          id="password-text"
                        >
                          {errorMessage}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Col>
              </Row>
            </div>

            <div className="form-group mt-3">
              <Row>
                <Col className="d-flex justify-content-center">
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      sx={{
                        minWidth: "250px",
                      }}
                      variant="contained"
                      type="submit"
                    >
                      Login
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </form>
        </Col>
      </Row>
    </main>
  );
}
