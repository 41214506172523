import React, { useState } from 'react';

// Modules
import { useNavigate } from 'react-router';

// App
import CreatePresentationFromTemplate from './createPresentationFromTemplate';

// UI
import { Fab, Tooltip, Box, Menu, MenuItem, ListItemText, ListItemIcon, Snackbar, Alert, Typography } from '@mui/material';

import { Add, NoteAdd, AddCircleOutlineOutlined, NoteAddOutlined } from '@mui/icons-material';

export default function CreatePresentation(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = anchorEl ? true : false;
  const [fromTemplateOpen, setFromTemplateOpen] = useState(false);
  const [onSuccess, setSuccess] = useState(false);

  // hooks
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='create-pres'>
      <Box
        className='d-flex align-items-center'
        onClick={handleClick}
        sx={{
          cursor: 'pointer'
        }}
      >
        <Tooltip title='Create presentation'>
          <Fab id='create-presentation-button' className={open ? `fab active` : 'fab'} onClick={handleClick} size='small' color='primary' aria-label='Create presentation'>
            <Add />
          </Fab>
        </Tooltip>
        <Typography variant='span' className='presentations-edit-labels'>
          Create presentation
        </Typography>
      </Box>

      <Menu
        id='create-presentation'
        aria-labelledby='create-presentation'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ marginTop: '10px' }}
      >
        <MenuItem onClick={() => {
          setAnchorEl(null);
          navigate(`/presentations/add`);
        }}>
          <ListItemIcon>
            <AddCircleOutlineOutlined fontSize='small' />
          </ListItemIcon>
          <ListItemText>New blank presentation</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => {
          setAnchorEl(null);
          setFromTemplateOpen(true);
        }}>
          <ListItemIcon>
            <NoteAddOutlined fontSize='small' />
          </ListItemIcon>
          <ListItemText>From predefined template</ListItemText>
        </MenuItem>
      </Menu>

      <CreatePresentationFromTemplate
        user={props.user}
        isOpen={fromTemplateOpen}
        onClose={() => {
          setFromTemplateOpen(false);
        }}
        onSuccess={() => {
          setSuccess(true);
          props.onUpdate();
        }}
      />

      <Snackbar open={onSuccess} autoHideDuration={6000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity='success' sx={{ width: '100%' }}>
          Presentation created successfully.
        </Alert>
      </Snackbar>
    </div>
  )
}