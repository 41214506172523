import React from "react";

// Modules

// App
import store from "../../redux/store";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
// import InstagramEmbed from 'react-instagram-embed';
import Error from "../../partials/error";
import HomeSkeletonScreen from "./homeSkeletonScreen";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Twitter } from "react-feather";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isRefreshing: false,
      isError: false,
      errorStatus: '',
      errorMessage: '',
      data: null,
      news: null,
      noticeboard: null,
      document: null,
      video: null,
      event: null,
      course: null,
      store: store.getState(),
    };
  }

  renderTwitterTimeline = () => {
    return (
      <Col xs={12} sm={8} className="mb-5 mb-md-0">
        <div className="twitter-container">
          <div className="twitter-container__header">
            <div className="twitter-brand">
              <div className="logo">
                <Twitter />
              </div>
              <span>Twitter</span>
            </div>
            <h6 className="twitter-handle">@RadissonHotels</h6>
          </div>
          <div className="twitter-container__timeline">
            <TwitterTimelineEmbed
              sourceType='profile'
              screenName='RadissonHotels'
            />
          </div>
        </div>
      </Col>
    );
  };

  render() {
    if (this.state.isError) {
      return (
        <Error 
          status={this.state.errorStatus}
          message={this.state.errorMessage}
        />
      );
    } else {
      if (this.state.isLoading) {
        return <HomeSkeletonScreen />;
      } else {
        return (
          <main className='home screen'>
            <Row className='mt-5 justify-content-center'>
              {this.renderTwitterTimeline()}
            </Row>
          </main>
        );
      }
    }
  }
}

export default Home;
